import React, { useRef, useState } from 'react';

import {
    Box,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core';

import DisplayPaginatedResults from 'components/shared/display-paginated-results';
import NoDataPlaceholder from 'components/shared/no-data-placeholder';
import PropTypes from 'prop-types';
import { TableSeparator } from 'RaisisComponents';
import { useTranslation } from 'react-i18next';
import API from 'utils/axios';

import ArticleRow from './article-row';

const ManageArticles = ({
    query,
    purposeSortType,
    sortType,
    categorySortCrit,
    onOpensStockModal,
    setInitializeData,
}) => {
    const { t } = useTranslation();

    const currentPageForArticles = useRef(null);
    const [articles, setArticles] = useState([]);

    const [loading, setLoading] = useState(true);

    const isSearch = query.trim().length > 0;

    const getArticles = async (currentPage, perPage, controller) => {
        try {
            const response = await API.get('/articles', {
                params: {
                    perPage,
                    currentPage,
                    pagesToLoad: 1,
                    type: sortType !== 'ALL' ? sortType : null,
                    categorieId: categorySortCrit !== 'ALL' ? categorySortCrit : null,
                    many: purposeSortType,
                },
                signal: controller?.signal,
            });

            currentPageForArticles.current = currentPage;
            setArticles(response.data.articles);
            setInitializeData?.((prev) => ({ ...prev, nextDisabled: !response.data.length }));
            return response.data.length;
        } catch (err) {
            throw new Error(err);
        }
    };

    const getSearchedArticles = async (currentPage, perPage, controller) => {
        try {
            const response = await API.get('/searchArticles', {
                params: {
                    text: query,
                    perPage,
                    currentPage,
                    pagesToLoad: 1,
                    type: sortType !== 'ALL' ? sortType : null,
                    categorieId: categorySortCrit !== 'ALL' ? categorySortCrit : null,
                    many: purposeSortType,
                },
                signal: controller?.signal,
            });

            currentPageForArticles.current = currentPage;
            setArticles(response.data.articles);

            return response.data.length;
        } catch (err) {
            throw new Error(err);
        }
    };

    return (
        <DisplayPaginatedResults
            query={query}
            loading={loading}
            setLoading={setLoading}
            getFunction={getArticles}
            getSearchFunction={getSearchedArticles}
            refreshList={[sortType, categorySortCrit, purposeSortType]}
        >
            {loading ? (
                <div className="flex h-64 w-full items-center justify-center bg-layout-main">
                    <CircularProgress />
                </div>
            ) : articles.length ? (
                <TableContainer component={Box}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>{t('Article name')}</TableCell>
                                <TableCell>{t('Article category')}</TableCell>
                                <TableCell>{t('Price without VAT')}</TableCell>
                                <TableCell>{t('VAT rate')}</TableCell>
                                <TableCell>{t('VAT value')}</TableCell>
                                <TableCell>{t('Final price with VAT')}</TableCell>
                                <TableCell>{t('Variables')}</TableCell>
                                <TableCell>{t('Project')}</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableSeparator />
                            {articles.map((nomenclature) => (
                                <ArticleRow
                                    key={nomenclature.id}
                                    nomenclature={nomenclature}
                                    isInitializationCase={!!setInitializeData}
                                    onOpensStockModal={onOpensStockModal}
                                    setLoading={setLoading}
                                    getArticlesCallback={() =>
                                        !isSearch
                                            ? getArticles(currentPageForArticles.current, 10)
                                            : getSearchedArticles(currentPageForArticles.current, 10)
                                    }
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <NoDataPlaceholder />
            )}
        </DisplayPaginatedResults>
    );
};

ManageArticles.propTypes = {
    query: PropTypes.string,
    purposeSortType: PropTypes.string,
    sortType: PropTypes.string,
    categorySortCrit: PropTypes.string,
    onOpensStockModal: PropTypes.func,
    setInitializeData: PropTypes.func,
};

ManageArticles.defaultProps = {
    query: '',
    purposeSortType: 'ALL',
    sortType: 'ALL',
    categorySortCrit: 'ALL',
    onOpensStockModal: () => {},
    setInitializeData: null,
};

export default ManageArticles;
