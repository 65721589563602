import React, { useContext, useEffect, useState } from 'react';

import { FileUp, ListFilter } from 'lucide-react';

import { Button, CircularProgress } from '@material-ui/core';

import Loading from 'components/shared/loading';
import NoDataPlaceholder from 'components/shared/no-data-placeholder';
import GlobalContext from 'contexts/GlobalContext';
import { Header } from 'RaisisComponents';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { getRawImageFromPath } from 'utils';
import API from 'utils/axios';
import exportHtmlAsPDF from 'utils/exportHtmlAsPDF';
import { getCompanyLogoAndIcon } from 'utils/getterFunctions';

import ModalReportsExport from './modal-reports-filter';
import ReportsTable from './reports-table';

const Reports = () => {
    const { t } = useTranslation();
    const { tenant } = useContext(GlobalContext);

    const [loading, setLoading] = useState(true);
    const [exporting, setExporting] = useState(false);
    const [fetching, setFetching] = useState(false);

    const [stocks, setStocks] = useState([]);
    const [projects, setProjects] = useState([]);
    const [companyLogo, setCompanyLogo] = useState(null);

    const [open, setOpen] = useState(false);
    const [data, setData] = useState([]);
    const [filteredOptions, setFilteredOptions] = useState({
        type: 'COMPANY',
        options: [],
    });

    useEffect(() => {
        (async () => {
            try {
                const [dataRes, stocksRes, projectsRes, { logoUrl }] = await Promise.all([
                    API.get('/article_reports', { params: filteredOptions }),
                    API.get('/stocks', {
                        params: {
                            perPage: 99999,
                            currentPage: 0,
                            pagesToLoad: 1,
                        },
                    }),
                    API.get('/PmProjects', {
                        params: {
                            perPage: 99999,
                            currentPage: 0,
                            pagesToLoad: 1,
                        },
                    }),
                    getCompanyLogoAndIcon(),
                ]);

                const logoBase64 = await getRawImageFromPath(logoUrl);
                setCompanyLogo(logoBase64);

                setData(dataRes.data.report);
                setStocks(stocksRes.data.stocks.content);
                setProjects(projectsRes.data.PmProjects.content);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleFilter = async (params) => {
        try {
            const dataRes = await API.get('/article_reports', { params });

            setFetching(true);
            setData(dataRes.data.report);
        } catch (error) {
            console.error(error);
        } finally {
            setFetching(false);
        }
    };

    const handleExport = async () => {
        try {
            setExporting(true);

            const html = document.getElementById('articles-report-table').innerHTML;

            const columnsWidths = ['6%', '10%', '10%', '10%', '10%', '10%', '10%', '10%', '6%', '6%', '6%', '6%'];

            await exportHtmlAsPDF(
                'landscape',
                [html],
                [],
                null,
                null,
                true,
                null,
                `Articles_Report_Export_${new Date().toISOString().slice(0, 5)}_${tenant.companyName}.pdf`,
                [
                    {
                        elements: [
                            {
                                key: 'table',
                                props: {
                                    widths: columnsWidths,
                                    dontBreakRows: true,
                                },
                            },
                        ],
                    },
                ],
                [],
            );
        } catch (error) {
            console.error(error);
        } finally {
            setExporting(false);
        }
    };

    return (
        <>
            <Helmet>
                <title>{t('Stocks reports')}</title>
            </Helmet>
            <Header
                pageTitle={t('Stocks reports')}
                action={
                    <>
                        <Button
                            color="primary"
                            startIcon={fetching ? <CircularProgress size={20} /> : <ListFilter />}
                            style={{ borderRadius: '999px' }}
                            onClick={handleOpen}
                            disabled={loading || fetching || exporting}
                        >
                            {t(fetching ? 'Loading...' : 'Filter data')}
                        </Button>
                        <Button
                            color="secondary"
                            startIcon={exporting ? <CircularProgress size={20} /> : <FileUp />}
                            style={{ borderRadius: '999px' }}
                            onClick={handleExport}
                            disabled={loading || fetching || exporting || !data.length}
                        >
                            {t(exporting ? 'Loading...' : 'Export as PDF')}
                        </Button>
                    </>
                }
            />

            <div className="page-container">
                {loading ? (
                    <Loading style={{ height: '100%', margin: 'auto 0' }} />
                ) : data.length ? (
                    <div className="overflow-x-auto" id="articles-report-table">
                        <ReportsTable data={data} companyLogo={companyLogo} />
                    </div>
                ) : (
                    <NoDataPlaceholder />
                )}
            </div>

            <ModalReportsExport
                open={open}
                onClose={handleClose}
                onFilter={handleFilter}
                stocks={stocks}
                projects={projects}
                filteredOptions={filteredOptions}
                setFilteredOptions={setFilteredOptions}
            />
        </>
    );
};

export default Reports;
