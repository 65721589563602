import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import OpportunityForm from 'components/opportunities/opportunity-form';
import UserContext from 'contexts/UserContext';
import PropTypes from 'prop-types';
import { Header } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const CreateOpportunity = ({ setInitializeData }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { checkPerm } = useContext(UserContext);

    const canAll = checkPerm([
        {
            permissionId: '15',
            permissionType: 'ALL',
        },
    ]);

    useEffect(() => {
        if (!canAll) {
            history.push('/');
        }
    }, [canAll]);

    if (setInitializeData) return <OpportunityForm editable={false} setInitializeData={setInitializeData} />;

    return (
        <>
            <Helmet>
                <title>Configurator ERP | {t('Add new Opportunity')}</title>
            </Helmet>

            <Header pageTitle={t('Add new Opportunity')} />
            {canAll && (
                <div className="page-container">
                    <OpportunityForm editable={false} />
                </div>
            )}
        </>
    );
};

CreateOpportunity.propTypes = {
    setInitializeData: PropTypes.func,
};

CreateOpportunity.defaultProps = {
    setInitializeData: null,
};

export default CreateOpportunity;
