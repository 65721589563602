import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import GetAppIcon from '@material-ui/icons/GetApp';
import PieChartIcon from '@material-ui/icons/PieChart';
import ShowChartIcon from '@material-ui/icons/ShowChart';

import {
    Box,
    Button,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core';

import {
    ArcElement,
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Tooltip,
} from 'chart.js';
import UserContext from 'contexts/UserContext';
import { useSnackbar } from 'notistack';
import { Dropdown, Header, SelectList, TableSeparator } from 'RaisisComponents/index.js';
import { Bar, Doughnut, Line } from 'react-chartjs-2';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { formatDate, generateRGBValue, slugify } from 'utils';
import API from 'utils/axios';
import { getEntityFields } from 'utils/getterFunctions';
import XLSX from 'xlsx';

ChartJS.register(CategoryScale, LinearScale, BarElement, LineElement, PointElement, ArcElement, Tooltip, Legend);

const entityList = ['Contact', 'Contract', 'Catalogue'];
const entityMapper = ['Contacts', 'Contracts', 'Immobile'];

const Reports = () => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();

    const [step, setStep] = useState(0);
    const [entity, setEntity] = useState(0);
    const [selectedColumns, setSelectedColumns] = useState([]);

    const [tableHeaders, setTableHeaders] = useState([]);
    const [actualHeaders, setActualHeaders] = useState([]);
    const [fields, setFields] = useState([]);
    const [entityRecords, setEntityRecords] = useState([]);
    const [sortKey, setSortKey] = useState({ key: null, order: -1 });

    /*
        Chart States
    */
    const [chartColumn, setChartColumn] = useState(null);
    const [chartColors, setChartColors] = useState([]);
    const [chartData, setChartData] = useState({});
    const [chartType, setChartType] = useState(0);

    /*
        Loading States
    */
    const [loadingFields, setLoadingFields] = useState(false);
    const [loadingRecords, setLoadingRecords] = useState(false);

    const { checkPerm } = useContext(UserContext);

    const canView = checkPerm([
        {
            permissionId: '10',
            permissionType: 'VIEW',
        },
    ]);

    useEffect(() => {
        if (!canView) {
            history.push('/');
        }
    }, [canView]);

    useEffect(() => {
        (async () => {
            /*
                Fetch all entity column fields
            */

            if (step === 1 || step === 3) {
                const newHeaders = [];
                const newActualHeaders = [];

                setLoadingFields(true);
                const ef = await getEntityFields(entityMapper[entity]);

                for (const obj of ef.objectToRender) {
                    const colFields = [];
                    const colTypes = [];
                    const colNames = [];

                    for (const field of obj.fields) {
                        colNames.push(field.name);
                        colFields.push(field.name);
                        colTypes.push(field.type);
                        newHeaders.push({ name: field.displayName, sort: -1 });
                    }

                    newActualHeaders.push({
                        displayName: obj.displayName,
                        fields: colFields,
                        types: colTypes,
                        names: colNames,
                    });
                }

                setFields(ef);
                setTableHeaders(newHeaders);
                setActualHeaders(newActualHeaders);
                setLoadingFields(false);
            }

            if (step === 2 || step === 3) {
                /*
                    Fetching all records on step 2 and 3
                    If step is 2 then check if any columns were selected first
                */

                if (!selectedColumns.length && step === 2) {
                    setStep(1);
                } else {
                    setLoadingRecords(true);

                    const records = await API.get(entityMapper[entity].toLowerCase(), {
                        params: {
                            currentPage: 0,
                            perPage: 99999,
                            pagesToLoad: 1,
                            type: entity === 0 ? 'ALL' : undefined,
                        },
                    });

                    let er;
                    if (entity === 0) er = [...records.data.data.contacts];
                    if (entity === 1) er = [...records.data.contracts];
                    if (entity === 2) er = [...records.data.immobile];

                    const newRecords = er.map((row) => {
                        let parsedData = JSON.parse(row.data);

                        if ('data' in parsedData) parsedData = JSON.parse(parsedData.data);

                        let aux = {};

                        for (const [headerColumnIndex, headerColumn] of actualHeaders.entries()) {
                            const oldColumn = { ...parsedData[slugify(headerColumn.displayName)] };
                            let newColumn = {};

                            for (const [headerFieldIndex, headerField] of headerColumn.fields.entries()) {
                                const slug = slugify(headerField);
                                let value = {};

                                if (slug in oldColumn) {
                                    value = oldColumn[slug];
                                    const type = headerColumn?.types[headerFieldIndex];

                                    if (type === 'Reference') value = '-';
                                    else if (type === 'Date') value = formatDate(value);
                                    else if (type === 'Selector')
                                        value =
                                            fields?.objectToRender[headerColumnIndex]?.fields[headerFieldIndex]
                                                ?.options[value[0]];
                                    else if (type === 'Toggle' && typeof value !== 'undefined')
                                        value =
                                            fields?.objectToRender[headerColumnIndex]?.fields[headerFieldIndex]
                                                ?.options[value === true ? 0 : 1];
                                    else if (type === 'File') {
                                        value = value.map((v) => v.url).length + ' ' + t('file/s');
                                    } else if (type === 'Image') {
                                        value = value.map((v) => v.url).length + ' ' + t('image/s');
                                    }
                                } else {
                                    value = '';
                                }

                                newColumn = {
                                    ...newColumn,
                                    [slug]: value,
                                };
                            }

                            aux = {
                                ...aux,
                                ...newColumn,
                            };
                        }

                        return aux;
                    });

                    setEntityRecords(newRecords);
                    setLoadingRecords(false);
                }
            }
        })();
    }, [entity, step]);

    /*
        Generate data and colors for the chart
    */
    useEffect(() => {
        if (tableHeaders.length > 0 && entityRecords.length > 0 && chartColumn !== null) {
            const newChartData = {};

            /*
                First, we create an object of value frequencies per each record
                for example: '0753592689': 5
                'name': 'value'
            */
            entityRecords.forEach((record) => {
                const key = Object.keys(record)[chartColumn];
                if (record[key] in newChartData) {
                    newChartData[record[key]]++;
                } else {
                    newChartData[record[key]] = 1;
                }
            });

            const newChartColors = [];
            for (const key in newChartData) {
                const c = generateRGBValue();
                newChartColors.push({
                    fill: `rgba(${c.r}, ${c.g}, ${c.b}, .75)`,
                    stroke: `rgba(${c.r}, ${c.g}, ${c.b}, 1)`,
                });
            }

            setChartData(newChartData);
            setChartColors(newChartColors);
        }
    }, [tableHeaders, entityRecords, chartColumn]);

    const chartProps = {
        options: {
            plugins: { legend: { display: false, position: 'bottom' } },
        },
        data: {
            labels: Object.keys(chartData),
            datasets: [
                {
                    label: '',
                    data: Object.keys(chartData).map((key) => chartData[key]),
                    backgroundColor: chartColors.map((c) => c.fill),
                    borderColor: chartColors.map((c) => c.stroke),
                    borderWidth: 0,
                    hoverOffset: 0,
                },
            ],
        },
    };

    /*
        Sort Function
    */
    const compare = (a, b) => {
        if (sortKey.key === null) return 0;
        const key = Object.keys(a)[sortKey.key];
        if (a[key] > b[key]) return sortKey.order;
        else if (a[key] === b[key]) return 0;
        else return -sortKey.order;
    };

    /*
        Export .xlsx
    */
    const exportTable = () => {
        const newEntityRecords = entityRecords.sort(compare).map((record) => {
            const newRecord = {};
            Object.keys(record).map((key, index) => {
                if (selectedColumns.indexOf(index) >= 0) {
                    newRecord[key] = record[key];
                }
            });
            return newRecord;
        });
        const book = XLSX.utils.book_new();
        const sheet = XLSX.utils.json_to_sheet(newEntityRecords);
        XLSX.utils.book_append_sheet(book, sheet, entityMapper[entity]);

        XLSX.write(book, { bookType: 'xlsx', type: 'buffer' });
        XLSX.writeFile(book, `${entityMapper[entity]}.xlsx`);
    };

    const steps = [t('Pick Entity'), t('Select Fields'), t('View'), t('Graphics')];

    return (
        <>
            <Helmet>
                <title>CRM | {t('Reports')}</title>
            </Helmet>

            <Header pageTitle={t('Reports')} />
            {canView && (
                <div className="min-h-svh p-14 pt-12 sm:p-4">
                    {/* <div className="mb-10">
                        <Stepper
                            steps={[t('Pick Entity'), t('Select Fields'), t('View'), t('Graphics')]}
                            step={step}
                            setStep={setStep}
                        />
                    </div> */}

                    <div className="mb-20 text-left sm:text-center">
                        <div className="text-3xl text-main-text">
                            {t('Step')}
                            <span className="p-2">{step + 1}</span>-<span className="p-2">{steps[step]}</span>
                        </div>
                    </div>

                    <div className="relative mb-20 sm:mb-40">
                        {/*
                        Alegere Entitate
                    */}
                        {step === 0 && (
                            <div className="flex justify-start sm:justify-center">
                                <div className="inline-block">
                                    <Dropdown
                                        options={entityList.map((e) => t(e))}
                                        selectedOption={entity}
                                        setSelectedOption={setEntity}
                                        variant="black"
                                    />
                                </div>
                            </div>
                        )}

                        {/*
                        Configurare Coloane
                    */}
                        {step === 1 && (
                            <div>
                                {loadingFields ? (
                                    <CircularProgress />
                                ) : tableHeaders.length > 0 ? (
                                    <div className="flex justify-start p-0 sm:justify-center sm:p-4">
                                        <div className="flex-col">
                                            <SelectList
                                                options={tableHeaders.map((th) => th.name)}
                                                selectedOptions={selectedColumns}
                                                setSelectedOptions={setSelectedColumns}
                                                multiple
                                            />
                                            <h6
                                                className="pointer-events-none mt-4 text-dark-text"
                                                style={{ userSelect: 'none' }}
                                            >
                                                {t('* select the column you want to appear in the Report')}
                                            </h6>
                                        </div>
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </div>
                        )}

                        {/* 
                        Vizualizare Tabel / Sortare
                    */}
                        {step === 2 && (
                            <div>
                                {loadingRecords ? (
                                    <CircularProgress />
                                ) : (
                                    <>
                                        <TableContainer component={Box}>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        {tableHeaders.map((head, index) => (
                                                            <React.Fragment key={index}>
                                                                {selectedColumns.indexOf(index) >= 0 ? (
                                                                    <TableCell>
                                                                        <div className="flex items-center">
                                                                            {head.name}
                                                                            {/* Sort button */}
                                                                            <div
                                                                                className={`relative ml-4 h-7 w-7 flex-shrink-0  rounded-full ${
                                                                                    sortKey.key === index
                                                                                        ? 'bg-primary-main'
                                                                                        : 'bg-layout-transparent hover:bg-layout-transparent-dark'
                                                                                } cursor-pointer transition-colors`}
                                                                                onClick={() => {
                                                                                    if (sortKey.key !== index) {
                                                                                        setSortKey({
                                                                                            key: index,
                                                                                            order: -1,
                                                                                        });
                                                                                    } else {
                                                                                        if (sortKey.order > 0)
                                                                                            setSortKey({
                                                                                                key: null,
                                                                                                order: -1,
                                                                                            });
                                                                                        else
                                                                                            setSortKey({
                                                                                                key: sortKey.key,
                                                                                                order:
                                                                                                    sortKey.order * -1,
                                                                                            });
                                                                                    }
                                                                                }}
                                                                            >
                                                                                <ArrowForwardIcon
                                                                                    className={`absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 rotate-90 transform text-buttons-text ${
                                                                                        sortKey.key === index &&
                                                                                        sortKey.order > 0 &&
                                                                                        '-rotate-90'
                                                                                    }`}
                                                                                    style={{
                                                                                        fontSize: '1rem',
                                                                                        transition:
                                                                                            'transform .2s ease',
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </TableCell>
                                                                ) : (
                                                                    <></>
                                                                )}
                                                            </React.Fragment>
                                                        ))}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <TableSeparator />
                                                    {entityRecords.sort(compare).map((record, index) => (
                                                        <TableRow key={index}>
                                                            {Object.keys(record).map((key, index) => (
                                                                <>
                                                                    {selectedColumns.indexOf(index) >= 0 ? (
                                                                        <TableCell>
                                                                            {typeof record[key] !== 'object' &&
                                                                                record[key]}
                                                                        </TableCell>
                                                                    ) : (
                                                                        <></>
                                                                    )}
                                                                </>
                                                            ))}
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>

                                        <div className="mt-20 flex justify-start sm:justify-center">
                                            <Button
                                                style={{
                                                    borderRadius: '999px',
                                                }}
                                                color="primary"
                                                startIcon={<GetAppIcon />}
                                                onClick={exportTable}
                                            >
                                                {t('Export .xls')}
                                            </Button>
                                        </div>
                                    </>
                                )}
                            </div>
                        )}

                        {step === 3 && (
                            <div>
                                {loadingFields || loadingRecords ? (
                                    <CircularProgress />
                                ) : tableHeaders.length > 0 ? (
                                    <>
                                        <div className="mb-10 flex justify-start sm:justify-center">
                                            <div className="mb-1.5 inline-block">
                                                <Dropdown
                                                    options={tableHeaders.map((th) => th.name)}
                                                    selectedOption={chartColumn}
                                                    setSelectedOption={setChartColumn}
                                                    placeholder={t('Graphic column')}
                                                    variant="black"
                                                />
                                            </div>
                                        </div>
                                        {Object.keys(chartData).length > 0 && chartColors.length > 0 && (
                                            <div className="flex w-full max-w-2xl flex-row sm:flex-col">
                                                <div className="flex-1 rounded-md bg-layout-transparent p-10">
                                                    {chartType === 0 && <Doughnut {...chartProps} />}
                                                    {chartType === 1 && <Bar {...chartProps} />}
                                                    {chartType === 2 && <Line {...chartProps} />}
                                                </div>
                                                <div className="ml-2 mt-6 flex flex-col justify-start gap-2 text-main-text sm:ml-0 sm:flex-row  sm:justify-center">
                                                    <div
                                                        className={`p-4 text-center sm:text-left ${
                                                            chartType === 0
                                                                ? 'bg-primary-main'
                                                                : 'bg-layout-transparent hover:bg-layout-transparent-dark'
                                                        } cursor-pointer rounded-md transition-colors`}
                                                        onClick={() => setChartType(0)}
                                                    >
                                                        <PieChartIcon className="text-buttons-text" />
                                                    </div>
                                                    <div
                                                        className={`p-4 text-left sm:text-center ${
                                                            chartType === 1
                                                                ? 'bg-primary-main'
                                                                : 'bg-layout-transparent hover:bg-layout-transparent-dark'
                                                        } cursor-pointer rounded-md transition-colors`}
                                                        onClick={() => setChartType(1)}
                                                    >
                                                        <EqualizerIcon className="text-buttons-text" />
                                                    </div>
                                                    <div
                                                        className={`p-4 text-center sm:text-left ${
                                                            chartType === 2
                                                                ? 'bg-primary-main'
                                                                : 'bg-layout-transparent hover:bg-layout-transparent-dark'
                                                        } cursor-pointer rounded-md transition-colors`}
                                                        onClick={() => setChartType(2)}
                                                    >
                                                        <ShowChartIcon className="text-buttons-text" />
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    <></>
                                )}
                            </div>
                        )}
                    </div>

                    <div className="flex items-center justify-start sm:justify-center">
                        {step !== 0 && (
                            <>
                                <Button
                                    startIcon={<ArrowBackIcon />}
                                    color="primary"
                                    onClick={() => (step > 0 ? setStep(step - 1) : null)}
                                >
                                    {t('Previous step')}
                                </Button>
                                <div className="w-5 flex-shrink-0" />
                            </>
                        )}

                        {step !== 3 && (
                            <Button
                                startIcon={<ArrowForwardIcon />}
                                color="primary"
                                onClick={() => {
                                    if (step === 1 && !selectedColumns.length) {
                                        enqueueSnackbar(
                                            t('At least one column should be selected in order to continue!'),
                                            {
                                                variant: 'error',
                                            },
                                        );
                                    } else if (step < 3) setStep(step + 1);
                                }}
                            >
                                {t('Next step')}
                            </Button>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default Reports;
