import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import AddIcon from '@material-ui/icons/Add';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { Box, Button, Table, TableBody, TableCell, TableContainer } from '@material-ui/core';

import TableDisplay from 'components/shared/configurator/table-display';
import UserContext from 'contexts/UserContext';
import PropTypes from 'prop-types';
import { ExpandableRow, Header } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { crm } from 'routes';

const Complex = (props) => {
    const { name, rang, children } = props;
    const [open, setOpen] = useState(false);

    return (
        <ExpandableRow
            colSpan={8}
            padding={false}
            row={
                <TableCell colSpan={8}>
                    <div className="flex items-center">
                        {rang > 0 && <div className={`w-${16 * rang} mr-4 h-1 bg-layout-transparent`} />}
                        <Button
                            startIcon={
                                <ArrowDropDownIcon className={`transform ${open ? 'rotate-180' : 'rotate-0'}`} />
                            }
                            color="primary"
                            onClick={() => setOpen(!open)}
                        >
                            {name}
                        </Button>
                    </div>
                </TableCell>
            }
            collapse={
                <TableContainer component={Box}>
                    <Table>
                        <TableBody>{children}</TableBody>
                    </Table>
                </TableContainer>
            }
        />
    );
};

Complex.propTypes = {
    children: PropTypes.element,
    name: PropTypes.string,
    rang: PropTypes.number,
};

Complex.defaultProps = {
    children: <></>,
    name: 'Nume Complex',
    rang: 0,
};

const ManageEstates = () => {
    const { t } = useTranslation();
    const history = useHistory();

    const { checkPerm } = useContext(UserContext);

    const canView = checkPerm([
        {
            permissionId: '7',
            permissionType: 'VIEW',
        },
    ]);

    const canAll = checkPerm([
        {
            permissionId: '7',
            permissionType: 'ALL',
        },
    ]);

    /*
        Dictates which estate photos are shown in the big gallery that appears on photo click
    */

    /*
        Photo that will be active when gallery opens
    */
    const [galleryIndexOpen, setGalleryIndexOpen] = useState(0);

    return (
        <>
            <Helmet>
                <title>CRM | {t('Manage catalogue')}</title>
            </Helmet>
            <Header
                pageTitle={t('Manage catalogue')}
                action={
                    <>
                        {canAll && (
                            <Button
                                startIcon={<AddIcon />}
                                color="secondary"
                                style={{ borderRadius: '999px' }}
                                onClick={() => history.push(crm.base + crm.estate.base + crm.estate.create)}
                            >
                                {t('Add catalog entity')}
                            </Button>
                        )}
                    </>
                }
            />

            {canView && (
                <div className="page-container relative z-10">
                    <TableDisplay
                        entity="Immobile"
                        initialIndex={galleryIndexOpen}
                        setInitialIndex={setGalleryIndexOpen}
                    />
                </div>
            )}
        </>
    );
};

export default ManageEstates;
