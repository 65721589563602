import React, { useCallback, useContext, useEffect, useState } from 'react';

import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';

import { Button, Checkbox, FormControlLabel, TextField } from '@material-ui/core';

import ActivityContext from 'contexts/ActivityContext';
import GlobalContext from 'contexts/GlobalContext';
import UserContext from 'contexts/UserContext';
import DOMPurify from 'dompurify';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import useScreenSizes from 'hooks/useScreenSizes.js';
import htmlToDraft from 'html-to-draftjs';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { DatePicker, Dropdown, LabelWrapper, MultiDropdown } from 'RaisisComponents/index.js';
import { Editor } from 'react-draft-wysiwyg';
import { useTranslation } from 'react-i18next';
import { ONE_DAY_IN_MILLISECONDS } from 'utils';
import API from 'utils/axios';
import * as yup from 'yup';

import SubOpportunityActivity from './sub-opportunity-activity';

const OpportunityActivity = (props) => {
    const { theme } = useContext(GlobalContext);
    const { departmentId, partnerId, tenantUsers, contactId, groups } = props;
    const { t } = useTranslation();
    const activityContext = useContext(ActivityContext);
    const [width] = useScreenSizes();
    const { user } = useContext(UserContext);
    const { enqueueSnackbar } = useSnackbar();

    /**
     * Opportunity states/functions
     */
    const [opportunities, setOpportunities] = useState([]);

    // Predefined
    const [fromPredefined, setFromPredefined] = useState(false);
    const [selectedOpportunity, setSelectedOpportunity] = useState(null);
    const [predefinedOpportunity, setPredefinedOpportunity] = useState([]);

    const [opportunityInfo, setOpportunityInfo] = useState(null);

    const [titleOpportunity, setTitleOpportunity] = useState('');
    const [active, setActive] = useState(false);
    const [users, setUsers] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const [commentOpportunity, setCommentOpportunity] = useState('');
    const [opportunityParentId, setOpportunityParentId] = useState(null);

    //Opportunity description logic
    const htmlToDraftBlocks = (html) => {
        const blocksFromHtml = htmlToDraft(html);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        const editorState = EditorState.createWithContent(contentState);
        return editorState;
    };

    const [descriptionOpportunity, setDescriptionOpportunity] = useState(
        htmlToDraftBlocks(`<h2 style="color: rgb(0,0,0);>${t('Add description for the Opportunity')}</h2>`),
    );

    // 4 Dropdowns
    const [responsible, setResponsible] = useState([]);
    const [supervisor, setSupervisor] = useState([]);
    const [watcher, setWatcher] = useState([]);

    const [opportunityStartDate, setOpportunityStartDate] = useState(new Date());

    /**
     * Fetch Tenants
     */
    useEffect(() => {
        (async () => {
            try {
                const response = await API.get('/tenants', {
                    params: {
                        perPage: 99999,
                        currentPage: 0,
                    },
                });
                setUsers(response.data.users.map((u) => u?.profile?.name));
                setAllUsers(response.data.users);

                setPredefinedOpportunity((await API.get('/opportunities-list')).data.data);
            } catch (err) {
                console.error(err);
            }
        })();
    }, []);

    /**
     * Function take the info about user from an dropdown and transform data into an array on indexes for the dropdown to be set on edit
     * @param {*} usersId is an array of objects from the opportunity data
     * @returns  An array of index for the dropdowns
     */
    const setDropdowns = useCallback(
        (usersId) => {
            if (allUsers.length > 0) {
                return usersId
                    ?.map((user) => user.userId)
                    .reduce((acc, curr) => {
                        const index = allUsers.findIndex((user) => user.id === curr);
                        return index >= 0 ? [...acc, index] : acc;
                    }, []);
            }

            return [];
        },
        [allUsers],
    );

    const formatSubOpportunity = (subOpportunity) => {
        return {
            ...subOpportunity,
            opportunityResponsible: setDropdowns(subOpportunity.mileStoneResponsible),
            estimatedStartDate: null,
            estimatedEndDate: null,
            children: subOpportunity.children.map((c) => ({
                ...c,
                opportunityResponsible: setDropdowns(c.mileStoneResponsible),
                estimatedStartDate: null,
                priority: 1,
                estimatedEndDate: null,
            })),
        };
    };

    useEffect(() => {
        (async () => {
            if (selectedOpportunity === null) return;
            try {
                const response = await API.get(`/mileStone/${predefinedOpportunity[selectedOpportunity].id}`);
                const opportunityInfo = response.data.data;

                const opportunity = opportunityInfo.mileStoneTask.map((m) => formatSubOpportunity(m));
                setTitleOpportunity(opportunityInfo.name);
                setDescriptionOpportunity(htmlToDraftBlocks(opportunityInfo.description));
                setResponsible(setDropdowns(opportunityInfo.responsible));
                setWatcher(setDropdowns(opportunityInfo.watcher));
                setSupervisor(setDropdowns(opportunityInfo.accepter));
                setActive(opportunityInfo?.isActive);
                setOpportunityInfo(opportunityInfo);
                setOpportunities(calculateDuration(opportunity));
            } catch (err) {
                console.error(err);
            }
        })();
    }, [selectedOpportunity, predefinedOpportunity]);

    const defaultOpportunity = () => {
        return {
            id: Math.random(),
            name: '',
            estimatedDays: 0,
            estimatedStartDate: null,
            estimatedEndDate: null,
            opportunityResponsible: [],
            children: [],
        };
    };

    const addOpportunity = () => {
        const newOpportunities = [...opportunities, defaultOpportunity()];
        setOpportunities(calculateDuration(newOpportunities));
    };

    const deleteOpportunity = (id) => {
        const newOpportunities = opportunities.filter((m) => m.id !== id);
        setOpportunities(newOpportunities.length > 0 ? calculateDuration(newOpportunities) : []);
    };

    const updateOpportunity = (id, key, value) => {
        const opportunityIndex = opportunities.findIndex((m) => m.id === id);
        const newOpportunities = [...opportunities];
        newOpportunities[opportunityIndex][key] = value;
        setOpportunities(calculateDuration(newOpportunities));
    };

    /**
     * @param {*} usersList - Is the state of the every dropdown with users - Array of numbers, returned by dropdown
     * @returns An array of users ids
     */
    const getUsersIds = (usersList) => {
        return usersList.map((user) => tenantUsers[user].id);
    };

    /**
     * Most important function!!!
     */
    const calculateDuration = (O, date = null) => {
        const newOpportunities = [];

        let nextStartDate = new Date(date ?? opportunityStartDate).getTime();

        for (const opportunity of O) {
            const newOpportunity = { ...opportunity };

            newOpportunity.estimatedStartDate = new Date(nextStartDate);

            newOpportunity.children = [];

            let totalEstimatedDays = 0;

            for (const task of opportunity.children) {
                const newTask = { ...task };

                newTask.estimatedStartDate = nextStartDate;
                newTask.estimatedEndDate = newTask.estimatedStartDate + task.estimatedDays * ONE_DAY_IN_MILLISECONDS;
                nextStartDate = newTask.estimatedEndDate + ONE_DAY_IN_MILLISECONDS;
                newOpportunity.children.push(newTask);
                totalEstimatedDays += task.estimatedDays;
            }

            newOpportunity.estimatedEndDate = new Date(nextStartDate);
            newOpportunity.estimatedDays = totalEstimatedDays;

            newOpportunities.push(newOpportunity);
        }

        return newOpportunities;
    };

    let schemaOpportunity = yup.object().shape({
        // descriptionOpportunity: yup
        //     .object()
        //     .typeError(t('The description is required!'))
        //     .required(t('The description is required!')),
        commentOpportunity: yup
            .string()
            .trim()
            .typeError(t('The Comment for the Opportunity is mandatory!'))
            .required(t('The Comment for the Opportunity is mandatory!')),
        responsible: yup
            .array()
            .typeError(t('Choosing at least one Opportunity responsible is mandatory!'))
            .min(1, t('Choosing at least one Opportunity responsible is mandatory!'))
            .required(t('Choosing at least one Opportunity responsible is mandatory!')),
        supervisor: yup
            .array()
            .typeError(t('Choosing at least one Opportunity supervisor is mandatory!'))
            .min(1, t('Choosing at least one Opportunity supervisor is mandatory!'))
            .required(t('Choosing at least one Opportunity supervisor is mandatory!')),
        watcher: yup
            .array()
            .typeError(t('Choosing at least one Opportunity watcher/beneficiary is mandatory!'))
            .min(1, t('Choosing at least one Opportunity watcher/beneficiary is mandatory!'))
            .required(t('Choosing at least one Opportunity watcher/beneficiary is mandatory!')),
        titleOpportunity: yup
            .string()
            .trim()
            .typeError(t('The name of the Opportunity is mandatory!'))
            .required(t('The name of the Opportunity is mandatory!')),
        opportunity: yup
            .array()
            .of(
                yup.object().shape({
                    estimatedDays: yup
                        .number()
                        .typeError(
                            t('The Sub-Opportunity must have allocated the required number of days for completion!'),
                        )
                        .required(
                            t('The Sub-Opportunity must have allocated the required number of days for completion!'),
                        ),
                    name: yup
                        .string()
                        .trim()
                        .typeError(t('Sub-Opportunity name is mandatory!'))
                        .required(t('Sub-Opportunity name is mandatory!')),
                    opportunityResponsible: yup
                        .array()
                        .of(
                            yup
                                .number()
                                .typeError(t('Choosing at least one Sub-Opportunity responsible is mandatory!')),
                        )
                        .min(1, t('Choosing at least one Sub-Opportunity responsible is mandatory!'))
                        .required(t('Choosing at least one Sub-Opportunity responsible is mandatory!')),
                    children: yup
                        .array()
                        .of(
                            yup.object().shape({
                                estimatedDays: yup
                                    .number()
                                    .typeError(
                                        t('The Task must have allocated the required number of days for completion!'),
                                    )
                                    .required(
                                        t('The Task must have allocated the required number of days for completion!'),
                                    ),
                                name: yup
                                    .string()
                                    .trim()
                                    .typeError(t('Task name is mandatory!'))
                                    .required(t('Task name is mandatory!')),
                                priority: yup
                                    .number()
                                    .typeError(t('Priority for the task is required!'))
                                    .required(t('Priority for the task is required!')),
                                opportunityResponsible: yup
                                    .array()
                                    .of(
                                        yup
                                            .number()
                                            .typeError(t('Choosing at least one Task responsible is mandatory!')),
                                    )
                                    .min(1, t('Choosing at least one Task responsible is mandatory!'))
                                    .required(t('Choosing at least one Task responsible is mandatory!')),
                            }),
                        )
                        .min(1, t('Sub-Opportunity must contain at least one task')),
                }),
            )
            .min(1, t('The Opportunity must have at least one Sub - Opportunity!')),
    });

    const createOpportunityActivity = async () => {
        if (activityContext.isCreating) return;

        try {
            await schemaOpportunity.validate({
                descriptionOpportunity,
                titleOpportunity,
                responsible,
                commentOpportunity,
                supervisor,
                watcher,
                opportunity: opportunities.map((m) => m),
            });

            try {
                const formData = new FormData();
                const htmlContent = DOMPurify.sanitize(
                    draftToHtml(convertToRaw(descriptionOpportunity.getCurrentContent())).replaceAll(
                        'color: currentcolor;',
                        '',
                    ),
                );

                const reqBody = {
                    milestoneId: opportunityParentId ?? undefined,
                    contactId: contactId ?? undefined,
                    title: titleOpportunity,
                    description: htmlContent,
                    note: commentOpportunity,
                    actionType: 'TASK',
                    status: 'TODO',
                    ownerId: user.id,
                    responsible: getUsersIds(responsible),
                    watcher: getUsersIds(watcher),
                    supervisor: getUsersIds(supervisor),
                    partnerId: partnerId ? partnerId : undefined,
                    departmentId,
                    pipelineType: 'LEADS_TO_POTENTIAL',
                    tasks: opportunities?.map((m) => ({
                        mileStoneTaskId: typeof m.id === 'string' ? m.id : undefined,
                        name: m.name,
                        estimatedStartDate: m.estimatedStartDate,
                        estimatedEndDate: m.estimatedEndDate,
                        responsible: getUsersIds(m.opportunityResponsible),
                        subTasks: m?.children?.map((c) => ({
                            mileStoneTaskId: typeof c.id === 'string' ? c.id : undefined,
                            name: c.name,
                            priority:
                                c.priority === 0 ? 'LOW' : c.priority === 1 ? 'MEDIUM' : c.priority === 2 ? 'HIGH' : '',
                            estimatedStartDate: c.estimatedStartDate,
                            estimatedEndDate: c.estimatedEndDate,
                            responsible: getUsersIds(c.opportunityResponsible),
                        })),
                    })),
                    groups,
                };

                formData.append('data', JSON.stringify(reqBody));
                activityContext.submitNewActivity(formData);
            } catch (err) {
                console.error(err);
            }
        } catch (err) {
            if (err.errors) {
                enqueueSnackbar(err.errors[0], { variant: 'error' });
            }
        }
    };

    const editOpportunityActivity = async () => {
        if (activityContext.isCreating) return;

        try {
            await schemaOpportunity.validate({
                descriptionOpportunity,
                titleOpportunity,
                pipeline: 0,
                responsible,
                commentOpportunity,
                supervisor,
                watcher,
                opportunity: opportunities.map((m) => m),
            });

            try {
                const formData = new FormData();
                const htmlContent = DOMPurify.sanitize(
                    draftToHtml(convertToRaw(descriptionOpportunity.getCurrentContent())).replaceAll(
                        'color: currentcolor;',
                        '',
                    ),
                );

                const reqBody = {
                    milestoneId: opportunityParentId ?? undefined,
                    contactId: contactId ?? undefined,
                    title: titleOpportunity,
                    description: htmlContent,
                    note: commentOpportunity,
                    actionType: 'TASK',
                    status: 'TODO',
                    ownerId: user.id,
                    responsible: getUsersIds(responsible),
                    watcher: getUsersIds(watcher),
                    supervisor: getUsersIds(supervisor),
                    partnerId: partnerId ? partnerId : undefined,
                    departmentId,
                    pipelineType: 'LEADS_TO_POTENTIAL',
                    tasks: opportunities?.map((m) => ({
                        activitiesTaskId: typeof m.id === 'string' ? m.id : undefined,
                        name: m.name,
                        estimatedStartDate: m.estimatedStartDate,
                        estimatedEndDate: m.estimatedEndDate,
                        responsible: getUsersIds(m.opportunityResponsible),
                        subTasks: m?.children?.map((c) => ({
                            activitiesTaskId: typeof c.id === 'string' ? c.id : undefined,
                            name: c.name,
                            priority:
                                c.priority === 0 ? 'LOW' : c.priority === 1 ? 'MEDIUM' : c.priority === 2 ? 'HIGH' : '',
                            estimatedStartDate: c.estimatedStartDate,
                            estimatedEndDate: c.estimatedEndDate,
                            responsible: getUsersIds(c.opportunityResponsible),
                        })),
                    })),
                    groups,
                };

                formData.append('id', JSON.stringify(activityContext.activityForEdit.id));
                formData.append('data', JSON.stringify(reqBody));
                activityContext.editActivity(formData);
            } catch (err) {
                console.error(err);
            }
        } catch (err) {
            if (err.errors) {
                enqueueSnackbar(err.errors[0], { variant: 'error' });
            }
        }
    };

    useEffect(() => {
        const { activityForEdit } = activityContext;

        if (activityForEdit) {
            const { activitiesTasks, title, description, users, note } = activityForEdit;

            setTitleOpportunity(title);
            setDescriptionOpportunity(htmlToDraftBlocks(description));
            setCommentOpportunity(note);

            setResponsible(
                users
                    .filter((user) => user.userType === 'RESPONSIBLE')
                    .map((user) => tenantUsers.findIndex((u) => u.id === user.userId)),
            );
            setSupervisor(
                users
                    .filter((user) => user.userType === 'ACCEPTER')
                    .map((user) => tenantUsers.findIndex((u) => u.id === user.userId)),
            );
            setWatcher(
                users
                    .filter((user) => user.userType === 'WATCHER')
                    .map((user) => tenantUsers.findIndex((u) => u.id === user.userId)),
            );

            setOpportunityStartDate(activitiesTasks[0].estimatedStartDate);

            const opportunities = activitiesTasks.map((opportunity) => ({
                children: opportunity.subTasks.map((task) => ({
                    estimatedDays: Math.ceil(
                        (new Date(task.estimatedEndDate).getTime() - new Date(task.estimatedStartDate).getTime()) /
                            (1000 * 60 * 60 * 24),
                    ),
                    id: task.id,
                    mileStoneResponsible: task.responsible.map((user) => ({
                        id: user.id,
                        userId: user.userId,
                        mileStoneTaskId: user.taskId,
                    })),
                    name: task.name,
                    parentId: task.parentId,
                })),

                estimatedDays: Math.ceil(
                    (new Date(opportunity.estimatedEndDate).getTime() -
                        new Date(opportunity.estimatedStartDate).getTime()) /
                        (1000 * 60 * 60 * 24),
                ),
                id: opportunity.id,

                mileStoneResponsible: opportunity.responsible.map((user) => ({
                    id: user.id,
                    userId: user.userId,
                    mileStoneTaskId: user.taskId,
                })),
                name: opportunity.name,
                parentId: opportunity.milestoneTaskParentId,
            }));

            const formatOpportunity = opportunities.map((m) => formatSubOpportunity(m));
            setOpportunities(calculateDuration(formatOpportunity));
        }
    }, [activityContext, tenantUsers]);

    return (
        <div>
            <form>
                {/* Choose from predefined CHECKBOX */}
                <div className="mb-5">
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={fromPredefined}
                                onChange={(e) => setFromPredefined(e.currentTarget.checked)}
                                style={{
                                    color: `rgb(${theme['UI-COLORS']['MAIN-PRIMARY'].color})`,
                                }}
                            />
                        }
                        label={<p className="user-select-none">{t('Pick a preconfigured opportunity')}</p>}
                    />
                </div>

                {/* Predefined opportunities DROPDOWN */}
                {fromPredefined && (
                    <div className="relative z-50 mb-5">
                        <Dropdown
                            options={predefinedOpportunity.map((m) => m.name)}
                            selectedOption={selectedOpportunity}
                            setSelectedOption={setSelectedOpportunity}
                            placeholder={t('Add from Opportunities')}
                            variant="black"
                        />

                        <p className="pointer-events-none mt-2 select-none text-dark-text">
                            {t('* If you choose a template, all the fields below will be preconfigured.')}
                        </p>
                    </div>
                )}

                <div className="mb-10">
                    <TextField
                        placeholder={t('Opportunity name')}
                        value={titleOpportunity}
                        onChange={(e) => setTitleOpportunity(e.target.value)}
                    />
                </div>

                <div className="mb-10">
                    <LabelWrapper label={t('Add description for the Opportunity')}>
                        <Editor
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            editorState={descriptionOpportunity}
                            onEditorStateChange={setDescriptionOpportunity}
                            toolbarStyle={{
                                margin: 0,
                                padding: 0,
                                borderRadius: 0,
                                color: 'black',
                                border: 'none',
                                backgroundColor: 'inherit',
                            }}
                            wrapperStyle={{
                                width: '100%',
                            }}
                            editorStyle={{
                                borderRadius: 0,
                                backgroundColor: 'white',
                                padding: '0px 8px',
                                color: 'black',
                                minHeight: '20rem',
                                maxHeight: width <= 750 ? '' : '40rem',
                                lineHeight: 1.2,
                            }}
                            toolbar={{
                                options: [
                                    'inline',
                                    'blockType',
                                    'fontSize',
                                    'list',
                                    'textAlign',
                                    'colorPicker',
                                    'link',
                                    'remove',
                                    'history',
                                ],
                            }}
                        />
                    </LabelWrapper>
                </div>

                {/* SubOpportunities */}
                <div className="relative z-40 mb-10">
                    {opportunities.map((subOpportunity, index) => (
                        <div
                            key={subOpportunity.id}
                            className="relative"
                            style={{
                                position: 'relative',
                                zIndex: opportunities.length - index + 1,
                            }}
                        >
                            <SubOpportunityActivity
                                onDelete={deleteOpportunity}
                                onUpdate={updateOpportunity}
                                opportunity={subOpportunity}
                                allUsers={users}
                                letter={String.fromCharCode(index + 65)}
                            />
                        </div>
                    ))}

                    <Button
                        startIcon={<AddIcon />}
                        fullWidth
                        onClick={() => {
                            if (opportunities === undefined) {
                                setOpportunities([]);
                                addOpportunity();
                            } else {
                                addOpportunity();
                            }
                        }}
                        color="primary"
                    >
                        {t('Add Sub-Opportunity')}
                    </Button>
                </div>

                {/* Opportunity dates */}
                <div className="relative z-30 mb-4 flex gap-4">
                    <LabelWrapper label={t('From')}>
                        <DatePicker
                            date={opportunityStartDate}
                            setDate={(date) => {
                                setOpportunityStartDate(date);
                                setOpportunities((M) => {
                                    return calculateDuration(M, date);
                                });
                            }}
                            variant="black"
                        />
                    </LabelWrapper>
                    {/* <LabelWrapper label={t('Up to')}>
                            <DatePicker
                                date={opportunities?.length > 0 && opportunities[opportunities.length - 1].estimatedEndDate}
                                variant="black"
                            />
                        </LabelWrapper> */}
                </div>

                {/* 3 Dropdowns */}
                <div className="mb-5 flex gap-5">
                    <LabelWrapper label={t('Responsible')}>
                        <MultiDropdown
                            variant="black"
                            options={tenantUsers.map((user) => user.profile?.name)}
                            placeholder={t('Choose user')}
                            selectedOptions={responsible}
                            setSelectedOptions={(i) => {
                                if (responsible?.indexOf(i) > -1) {
                                    setResponsible(responsible.filter((opt) => opt !== i));
                                } else {
                                    setResponsible([...responsible, i]);
                                }
                            }}
                        />
                    </LabelWrapper>

                    <LabelWrapper label={t('Supervisor')}>
                        <MultiDropdown
                            variant="black"
                            options={tenantUsers.map((user) => user.profile?.name)}
                            placeholder={t('Choose user')}
                            selectedOptions={supervisor}
                            setSelectedOptions={(i) => {
                                if (supervisor?.indexOf(i) > -1) {
                                    setSupervisor(supervisor.filter((opt) => opt !== i));
                                } else {
                                    setSupervisor([...supervisor, i]);
                                }
                            }}
                        />
                    </LabelWrapper>

                    <LabelWrapper label={t('Watcher / Beneficiary')}>
                        <MultiDropdown
                            variant="black"
                            options={tenantUsers.map((user) => user.profile?.name)}
                            placeholder={t('Choose user')}
                            selectedOptions={watcher}
                            setSelectedOptions={(i) => {
                                if (watcher?.indexOf(i) > -1) {
                                    setWatcher(watcher.filter((opt) => opt !== i));
                                } else {
                                    setWatcher([...watcher, i]);
                                }
                            }}
                        />
                    </LabelWrapper>
                </div>

                {/* Pipeline Dropdown */}
                <div className="mb-10">
                    <LabelWrapper label={t('Pipeline')}>
                        <Dropdown
                            variant="black"
                            placeholder={t('Choose option')}
                            options={[t('Opportunity')]}
                            selectedOption={0}
                            disabled
                        />
                    </LabelWrapper>
                </div>

                {/* Comment */}
                <div className="mb-10">
                    <TextField
                        placeholder={t('Write a comment...')}
                        label={t('Comment')}
                        value={commentOpportunity}
                        multiline
                        rows={3}
                        onChange={(e) => setCommentOpportunity(e.target.value)}
                    />
                </div>

                {/* Submit button */}
                <div className="flex items-center">
                    <Button
                        color="secondary"
                        startIcon={activityContext.activityForEdit !== null ? <EditIcon /> : <AddIcon />}
                        onClick={() => {
                            if (opportunityInfo) {
                                const myOpportunity = {
                                    name: titleOpportunity,
                                    description: descriptionOpportunity,
                                    mileStoneTask: [],
                                };

                                const confOpportunity = {
                                    name: opportunityInfo.name,
                                    description: opportunityInfo.description,
                                    mileStoneTask: [],
                                };

                                /**
                                 * Construim in functie de opportunitatea noastră
                                 */
                                for (const subOpportunity of opportunities) {
                                    const newSubOpportunity = {};
                                    newSubOpportunity.name = subOpportunity.name;
                                    newSubOpportunity.children = [];

                                    for (const task of subOpportunity.children) {
                                        newSubOpportunity.children.push({ name: task.name });
                                    }

                                    myOpportunity.mileStoneTask.push(newSubOpportunity);
                                }

                                /**
                                 * Construim in functie de opportunitatea din configurator
                                 */

                                for (const subOpportunity of opportunityInfo.mileStoneTask) {
                                    const newSubOpportunity = {};
                                    newSubOpportunity.name = subOpportunity.name;
                                    newSubOpportunity.children = [];

                                    for (const task of subOpportunity.children) {
                                        newSubOpportunity.children.push({ name: task.name });
                                    }

                                    confOpportunity.mileStoneTask.push(newSubOpportunity);
                                }

                                if (JSON.stringify(confOpportunity) === JSON.stringify(myOpportunity)) {
                                    setOpportunityParentId(opportunityInfo.id);
                                }
                            }

                            if (activityContext.activityForEdit !== null) {
                                editOpportunityActivity();
                            } else {
                                createOpportunityActivity();
                            }
                        }}
                    >
                        {`${activityContext.activityForEdit !== null ? t('Edit') : t('Add')} ${t('Opportunity')}`}
                    </Button>

                    <div className="w-2 flex-shrink-0" />

                    <Button color="secondary">{t('Cancel')}</Button>
                </div>
            </form>
        </div>
    );
};

OpportunityActivity.propTypes = {
    departmentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    partnerId: PropTypes.string,
    tenantUsers: PropTypes.arrayOf(PropTypes.object),
    activity: PropTypes.object,
    contactId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    groups: PropTypes.array,
};

OpportunityActivity.defaultProps = {
    departmentId: null,
    partnerId: null,
    tenantUsers: [],
    contactId: null,
    activity: null,
    groups: [],
};

export default OpportunityActivity;
