import React, { useState } from 'react';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import { ClickAwayListener } from '@material-ui/core';

import PropTypes from 'prop-types';

export const ActionArrow = (props) => {
    const { actions } = props;
    const [open, setOpen] = useState(false);
    const filteredActions = actions.filter((action) => !('render' in action) || action.render);

    return (
        <>
            {filteredActions.length > 0 ? (
                <ClickAwayListener onClickAway={() => setOpen(false)}>
                    <div className="relative">
                        <div
                            className="relative flex h-8 w-8 cursor-pointer items-center justify-center rounded-full hover:bg-layout-transparent"
                            onClick={() => setOpen(!open)}
                        >
                            <KeyboardArrowDownIcon
                                className={`transform text-main-text transition-all duration-200 ${
                                    open ? 'rotate-180' : 'rotate-0'
                                }`}
                                style={{
                                    fontSize: '1.5rem',
                                    transition: 'transform .15s ease',
                                }}
                            />
                        </div>

                        <ul
                            className={`no-user-select-recursive absolute right-0 top-full z-50 transform rounded-md bg-primary-main py-2 transition-all duration-150 md:left-auto md:right-0 ${
                                open
                                    ? 'pointer-events-auto translate-y-0 opacity-100'
                                    : 'pointer-events-none translate-y-4 opacity-0'
                            }`}
                        >
                            {filteredActions.map((action) => (
                                <li
                                    key={action.name}
                                    onClick={() => {
                                        action.action();
                                        setOpen(false);
                                    }}
                                    className="min-w-max cursor-pointer px-5 py-3 text-xl text-buttons-text transition-colors hover:bg-primary-light"
                                >
                                    {action.name}
                                </li>
                            ))}
                        </ul>
                    </div>
                </ClickAwayListener>
            ) : null}
        </>
    );
};

ActionArrow.propTypes = {
    actions: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            action: PropTypes.func,
        }),
    ),
};

ActionArrow.defaultProps = {
    actions: [],
};

export default ActionArrow;
