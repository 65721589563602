import React, { useContext } from 'react';

import { CalendarDays, EllipsisVertical, Pencil, SquareMousePointer, Trash, User } from 'lucide-react';

import ActionsPopover from 'components/shared/actions-popover/actions-popover';
import Avatar from 'components/shared/avatar/avatar';
import Tag from 'components/shared/tag/tag';
import UserContext from 'contexts/UserContext';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'utils';

const WikiHeader = ({ wiki, disabled, onDeleteWiki, openEdit }) => {
    const { t } = useTranslation();

    const { checkPerm } = useContext(UserContext);
    const canAll = checkPerm([
        {
            permissionId: '19',
            permissionType: 'ALL',
        },
    ]);

    const actions = canAll
        ? [
              {
                  icon: <Pencil />,
                  text: t('Edit'),
                  action: openEdit,
                  disabled,
                  confirm: false,
              },
              {
                  icon: <Trash />,
                  text: t('Delete'),
                  action: (wiki) => onDeleteWiki(wiki.id),
                  disabled,
                  confirm: true,
              },
          ]
        : [];

    return (
        <div className="flex h-14 items-center justify-between gap-4">
            <div className="flex h-full items-center gap-3">
                <Avatar url={wiki.author.profileImageUrl} />
                <Tag
                    tag={{
                        icon: <User className="h-6 w-6" />,
                        label: wiki.author.profile.name,
                        color: 'var(--main-text)',
                        backgroundColor: 'var(--layout-transparent)',
                        borderColor: 'var(--layout-transparent)',
                    }}
                />
                <Tag
                    tag={{
                        icon: <CalendarDays className="h-6 w-6" />,
                        label: formatDate(wiki.createdAt),
                        color: 'var(--main-text)',
                        backgroundColor: 'var(--layout-transparent)',
                        borderColor: 'var(--layout-transparent)',
                    }}
                />
                <Tag
                    tag={{
                        icon: <SquareMousePointer className="h-6 w-6" />,
                        label: wiki.departament.name,
                        color: 'var(--main-text)',
                        backgroundColor: 'var(--layout-transparent)',
                        borderColor: 'var(--layout-transparent)',
                    }}
                />
            </div>
            <ActionsPopover
                icon={<EllipsisVertical />}
                opacity={{ default: 1, hover: 0.4 }}
                actions={actions.map((element) => ({ ...element, action: element.action.bind(null, wiki) }))}
                disabled={disabled}
            />
        </div>
    );
};

WikiHeader.propTypes = {
    wiki: PropTypes.object,
    disabled: PropTypes.bool,
    onDeleteWiki: PropTypes.func,
    openEdit: PropTypes.func,
};

WikiHeader.defaultProps = {
    wiki: {},
    disabled: false,
    onDeleteWiki: () => null,
    openEdit: () => null,
};

export default WikiHeader;
