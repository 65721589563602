import React, { useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import LangSwitch from 'components/shared/lang-switch';
import UserContext from 'contexts/UserContext';
import useNavProps from 'hooks/useNavProps';
import PropTypes from 'prop-types';
import { Layout, NewSidebar } from 'RaisisComponents';
import { useTranslation } from 'react-i18next';
import { configurator } from 'routes';

const handleFindSelectionIndexes = (items, index, pathname) => {
    let done = false;
    let currentSelectionIndexes = [index];

    for (let i = 0; i < items.length; i++) {
        const item = items[i];

        if (Boolean(item.route) && pathname === item.route) {
            done = true;
            break;
        }

        if (item.subItems) {
            const { currentSelectionIndexes: childrenSelectionIndexes, done: childrenDone } =
                handleFindSelectionIndexes(item.subItems, i, pathname);

            currentSelectionIndexes = [...currentSelectionIndexes, ...childrenSelectionIndexes];
            done = childrenDone;
        }
    }

    return { currentSelectionIndexes, done };
};

const handleGetInitialItemsSelection = (items, pathname) => {
    if (pathname === '/') return [];
    let candidateSelectedItems = [];

    for (let i = 0; i < items.length; i++) {
        const item = items[i];

        if (item.subItems) {
            const { currentSelectionIndexes, done } = handleFindSelectionIndexes(item.subItems, i, pathname);

            if (done) {
                candidateSelectedItems = currentSelectionIndexes;
                break;
            }
        }
    }

    return candidateSelectedItems;
};

const SidebarLayout = (props) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { pathname } = useLocation();

    const { user } = useContext(UserContext);

    const { children, disabledSidebar, handleLogout } = props;
    const {
        navName,
        sidebarProps: { navItems, moduleName, backUrl },
    } = useNavProps();

    const initialSelectedItems = handleGetInitialItemsSelection(navItems, pathname);

    return (
        <Layout
            sidebar={
                <NewSidebar
                    logoAction={() => history.push('/')}
                    imageUrl={user.profileImageUrl}
                    userName={user.profile.name}
                    disabled={disabledSidebar}
                    userDropdownActions={[
                        {
                            name: t('Edit profile'),
                            handle: () =>
                                history.push(
                                    configurator.base +
                                        configurator.users.base +
                                        configurator.users.update +
                                        '/' +
                                        user.id +
                                        '?tab=1',
                                ),
                        },
                        {
                            name: t('My activity'),
                            handle: () =>
                                history.push(
                                    configurator.base +
                                        configurator.users.base +
                                        configurator.users.update +
                                        '/' +
                                        user.id +
                                        '?tab=0',
                                ),
                        },
                        {
                            name: t('Logout'),
                            handle: () => handleLogout(),
                        },
                    ]}
                    initialSelectedItems={initialSelectedItems}
                    navItems={navItems}
                    moduleName={navName ?? moduleName}
                    backUrl={backUrl}
                    footer={<LangSwitch />}
                />
            }
        >
            {children}
        </Layout>
    );
};

SidebarLayout.propTypes = {
    children: PropTypes.element,
    sidebar: PropTypes.element,
    disabledSidebar: PropTypes.bool,
    handleLogout: PropTypes.func,
};

SidebarLayout.defaultProps = {
    children: <></>,
    sidebar: null,
    disabledSidebar: true,
    handleLogout: () => {},
};

export default SidebarLayout;
