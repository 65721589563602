import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';

import Wiki from 'components/modules/wiki/wiki';
import DisplayInfiniteScrollResults from 'components/shared/display-infinite-scroll-results';
import Loading from 'components/shared/loading';
import NoDataPlaceholder from 'components/shared/no-data-placeholder';
import { useSnackbar } from 'notistack';
import { Header, Search, Tabs } from 'RaisisComponents';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import API from 'utils/axios';

const AffiliateProjectWikis = () => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const { projectId } = useParams();

    const [query, setQuery] = useState('');

    const [loading, setLoading] = useState(true);
    const [operating, setOperating] = useState(false);

    const [selectedDepartment, setSelectedDepartment] = useState(0);
    const [fileType, setFileType] = useState('all');

    const [departments, setDepartments] = useState([]);
    const [wikis, setWikis] = useState([]);

    const getWikis = async (controller) => {
        try {
            const response = await API.get('/wikis', {
                params: {
                    query,
                    projectId,
                    departmentId: selectedDepartment === 0 ? null : departments[selectedDepartment - 1].id,
                    fileType: fileType === 'all' ? null : fileType,
                },
                signal: controller?.signal ?? undefined,
            });

            setWikis(response.data.wikiArray);
        } catch (err) {
            throw new Error(err);
        }
    };

    const handleAddComment = async (wikiId, data) => {
        try {
            setOperating(true);

            const { comment, files } = data;

            const formData = new FormData();

            const reqBody = {
                wikiId,
                comment,
            };

            formData.append('data', JSON.stringify(reqBody));
            if (files.length > 0) {
                files.forEach((element) => formData.append('files', element.file));
            }

            await API.post('/wikiComment', formData);
            await getWikis();

            enqueueSnackbar(t('Comment added!'), {
                variant: 'success',
            });
        } catch (err) {
            console.error(err);
            throw err;
        } finally {
            setOperating(false);
        }
    };

    useEffect(() => {
        (async () => {
            try {
                const [resDepartments] = await Promise.all([
                    API.get('pmDepartments', {
                        params: {
                            perPage: 99999,
                            currentPage: 0,
                            pagesToLoad: 1,
                            type: 'PLANNING_OVERVIEW',
                        },
                    }),
                    getWikis(),
                ]);

                setDepartments(resDepartments.data.pmDepartments);
            } catch (err) {
                console.error(err);
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    /**
     * This useEffect is for filtering wikis by department and file type
     */
    useEffect(() => {
        (async () => {
            try {
                setOperating(true);
                await getWikis();
            } catch (error) {
                console.error(error);
                throw error;
            } finally {
                setOperating(false);
            }
        })();
    }, [selectedDepartment, fileType]);

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Wiki - ${t('Documentation')}`}</title>
            </Helmet>
            <Header
                pageTitle={`Wiki - ${t('Documentation')}`}
                action={<Search searchIconBig={true} value={query} setValue={setQuery} withSearchIcon={false} />}
                toolbar={
                    <Tabs
                        disabled={operating}
                        tabs={[t('All'), ...(departments?.map((d) => d.name) ?? [])]}
                        activeTab={selectedDepartment}
                        setActiveTab={setSelectedDepartment}
                    />
                }
                toolbarSecondary={
                    <FormControl component="fieldset">
                        <RadioGroup
                            aria-label="status"
                            name="status-filter"
                            value={fileType}
                            onChange={(e) => setFileType(e.target.value)}
                        >
                            <div className="no-user-select-recursive flex items-center text-main-text">
                                <FormControlLabel
                                    disabled={operating}
                                    value="all"
                                    control={<Radio />}
                                    label={t('All')}
                                />
                                <FormControlLabel
                                    disabled={operating}
                                    value="FILE"
                                    control={<Radio />}
                                    label={t('Files')}
                                />
                                <FormControlLabel
                                    disabled={operating}
                                    value="DOCUMENT"
                                    control={<Radio />}
                                    label={t('Documents')}
                                />
                            </div>
                        </RadioGroup>
                    </FormControl>
                }
            />
            <div className="page-container">
                <div className="relative flex justify-center gap-16">
                    <DisplayInfiniteScrollResults
                        query={query}
                        setLoading={setLoading}
                        getFunction={getWikis}
                        refreshList={[selectedDepartment, fileType]}
                    >
                        <div className="flex w-full max-w-5xl flex-col items-center">
                            {loading ? (
                                <Loading style={{ height: '100%', margin: 'auto 0' }} />
                            ) : wikis.length > 0 ? (
                                wikis.map((wiki) => (
                                    <Wiki
                                        key={wiki.id}
                                        disabled={operating}
                                        wiki={wiki}
                                        onAddComment={handleAddComment}
                                    />
                                ))
                            ) : (
                                <NoDataPlaceholder />
                            )}
                        </div>
                    </DisplayInfiniteScrollResults>
                </div>
            </div>
        </React.Fragment>
    );
};

export default AffiliateProjectWikis;
