import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import BasicTooltip from 'components/shared/basic-tooltip';
import UserContext from 'contexts/UserContext';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { quotes } from 'routes';

const mappedBgColors = {
    PRIMARY: 'bg-primary-main',
    WAITING: 'bg-secondary-main',
    BLOCKED: 'bg-layout-transparent',
    REFUSED: 'bg-error',
    ACCEPTED: 'bg-success',
};

const mappedHoveBgColors = {
    PRIMARY: 'bg-primary-light',
    WAITING: 'bg-secondary-light',
    BLOCKED: 'bg-layout-transparent-dark',
    REFUSED: 'bg-error-light',
    ACCEPTED: 'bg-success-light',
};

const mappedBackendKeys = {
    PRIMARY: 'Primary offer',
    WAITING: 'Waiting offer',
    BLOCKED: 'Blocked offer',
    REFUSED: 'Refused offer',
    ACCEPTED: 'Accepted offer',
};

const OfferArticleHistory = ({ article, style }) => {
    const { t } = useTranslation();
    const history = useHistory();

    const { checkPerm } = useContext(UserContext);

    const canView = checkPerm([
        {
            permissionId: '9',
            permissionType: 'VIEW',
        },
    ]);

    const handleNavigateToOffer = (id) => history.push(quotes.base + quotes.viewBidding.base + '/' + id);

    if (!canView || !article || article.OfferArticles.length === 0) return null;

    return (
        <div className="flex w-full flex-col gap-2 rounded-md bg-layout-transparent-dark p-5" style={style}>
            <h3 className="text-xl font-bold">{`${t('Article history')}`}</h3>

            <div className="flex max-h-80 flex-col gap-2 overflow-y-auto py-1" style={{ minHeight: 'min-content' }}>
                {article.OfferArticles.map((article) => {
                    return (
                        <BasicTooltip key={article.id} tip={t('View offer')}>
                            <div
                                onClick={() => handleNavigateToOffer(article.Offer.id)}
                                className={`flex cursor-pointer items-center justify-between gap-2 rounded-md p-2 sm:flex-col ${
                                    mappedBgColors[article.articleStatus]
                                } hover:${mappedHoveBgColors[article.articleStatus]}`}
                            >
                                <p className="break-words">{`${t('Offer')}: ${article.Offer.name}`}</p>
                                <p className="break-words">{`${t('Queue status')}: ${t(
                                    mappedBackendKeys[article.articleStatus],
                                )}`}</p>
                                <p className="break-words">{`${t('Offer status')}: ${t(
                                    article.Offer.status.charAt(0) +
                                        article.Offer.status.substring(1).toLocaleLowerCase(),
                                )}`}</p>
                            </div>
                        </BasicTooltip>
                    );
                })}
            </div>
        </div>
    );
};

OfferArticleHistory.propTypes = {
    article: PropTypes.object,
    style: PropTypes.object,
};

OfferArticleHistory.defaultProps = {
    article: {},
    style: {},
};

export default OfferArticleHistory;
