import React, { useContext, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ChevronDown } from 'lucide-react';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

import { Button } from '@material-ui/core';

import Comments from 'components/shared/comments/comments';
import ActivityContext from 'contexts/ActivityContext';
import GlobalContext from 'contexts/GlobalContext';
import UserContext from 'contexts/UserContext';
import DOMPurify from 'dompurify';
import useConfirm from 'hooks/useConfirm';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { configurator, crm } from 'routes';
import { daysFrom, errorHandling, fileNameFromKey, formatDate, formatTime, linkStyle } from 'utils';
import API from 'utils/axios';

import ActionArrow from '../action-arrow';
import SubOpportunityDisplay from '../sub-opportunity-display';

const MAPPED_STATUS_ICONS = {
    TODO: null,
    DONE: (
        <CheckIcon
            className="text-buttons-text transition-opacity"
            style={{
                fontSize: '1rem',
            }}
        />
    ),
    FAILED: (
        <CloseIcon
            className="text-buttons-text transition-opacity"
            style={{
                fontSize: '1rem',
            }}
        />
    ),
};

const COMMENTS_ROUTES = {
    activities: 'activities_comments',
    subActivities: 'tasks_comments',
    tasks: 'tasks_comments',
};

const Opportunity = (props) => {
    const {
        opportunity,
        deleteOpportunity,
        updateOpportunity,
        actionArrow,
        userLink,
        setSeeGantt,
        setEditActivity,
        editDocuments,
        onNotify,
    } = props;

    const confirm = useConfirm();

    const history = useHistory();
    const { user, checkPerm } = useContext(UserContext);
    const globalContext = useContext(GlobalContext);
    const { enqueueSnackbar } = useSnackbar();
    const { isContact, fetchActivities } = useContext(ActivityContext);
    const { t } = useTranslation();

    const [open, setOpen] = useState(false);

    const userCanAll = checkPerm([
        {
            permissionId: '1',
            permissionType: 'ALL',
        },
    ]);

    const affiliateCanAll =
        user.isAdviser &&
        checkPerm([
            {
                permissionId: '1',
                permissionType: 'VIEW',
            },
        ]);

    const canAll = userCanAll || affiliateCanAll;

    const userIsResponsible =
        canAll &&
        (user.id === opportunity.authorId ||
            (user.isAdviser &&
                opportunity.users
                    .filter((u) => u.userType === 'RESPONSIBLE')
                    .map((u) => u.userId)
                    .includes(user.id)));

    const responsible = opportunity.users
        .filter((u) => u.userType === 'RESPONSIBLE')
        .map((u) => u.user.profile.name)
        .join(', ');

    const supervisor = opportunity.users
        .filter((u) => u.userType === 'ACCEPTER')
        .map((u) => u.user.profile.name)
        .join(', ');

    const watcher = opportunity.users
        .filter((u) => u.userType === 'WATCHER')
        .map((u) => u.user.profile.name)
        .join(', ');

    const formattedComments = useMemo(
        () =>
            opportunity.comments.map((element) => ({
                ...element,
                text: element.comment,
                createdAt: element.createAt,
                files: element.files.map((file) => ({
                    ...file,
                    id: file.fileId,
                    url: file.urls,
                    fileName: fileNameFromKey(file.file.key),
                })),
            })),
        [opportunity.comments],
    );

    const handleAddComment = async (type, id, data) => {
        try {
            const { comment, files } = data;

            const formData = new FormData();

            const reqBody = {
                activityId: id,
                comment,
            };

            formData.append('data', JSON.stringify(reqBody));
            if (files.length > 0) {
                files.forEach((element) => formData.append('files', element.file));
            }

            await API.post(COMMENTS_ROUTES[type], formData);
            enqueueSnackbar(t('Comment added successfully!'), { variant: 'success' });
        } catch (err) {
            console.error(err);
            throw err;
        } finally {
            fetchActivities();
        }
    };

    const handleDeleteComment = async (type, id) => {
        try {
            await API.delete(COMMENTS_ROUTES[type], {
                data: {
                    id,
                },
            });

            enqueueSnackbar(t('Comment deleted!'), {
                variant: 'success',
            });
        } catch (err) {
            enqueueSnackbar(errorHandling(err).length > 100 ? errorHandling(err) : t(errorHandling(err)), {
                variant: 'error',
            });
            console.error(err);
        } finally {
            fetchActivities();
        }
    };

    return (
        <>
            {opportunity !== null && user?.id && (
                <div className="relative mb-12 last:mb-0">
                    {/* Opportunity checkbox and date */}
                    <div
                        className="absolute -left-8 top-5 sm:-left-4 sm:top-12"
                        style={{ transform: 'translateX(calc(-50% - 1px))' }}
                    >
                        <div
                            className={`flex h-6 w-6 items-center justify-center rounded-full border-2 transition-colors ${
                                opportunity.status === 'FAILED'
                                    ? 'border-error bg-error'
                                    : opportunity.status === 'DONE'
                                      ? 'border-pink-500 bg-pink-500'
                                      : `border-pink-500 bg-layout-main`
                            }`}
                        >
                            {MAPPED_STATUS_ICONS[opportunity.status]}
                        </div>

                        <div className="absolute top-1/2 -translate-x-full -translate-y-1/2 transform p-2.5 pr-5 text-right sm:flex sm:translate-x-10 sm:gap-6">
                            <p
                                style={{ userSelect: 'none' }}
                                className="whitespace-nowrap text-right font-semibold text-pink-500"
                            >{`${
                                daysFrom(opportunity.createAt) !== 0
                                    ? Math.abs(daysFrom(opportunity.createAt))
                                    : t('today')
                            } ${
                                daysFrom(opportunity.createAt) > 0
                                    ? t('days ago')
                                    : daysFrom(opportunity.createAt) === 1
                                      ? t('Yesterday')
                                      : ''
                            }`}</p>
                            <p style={{ userSelect: 'none' }} className="whitespace-nowrap text-right font-semibold">
                                {formatDate(opportunity.createAt, false)}
                            </p>
                            <p style={{ userSelect: 'none' }} className="whitespace-nowrap text-right text-dark-text">
                                {formatTime(opportunity.createAt)}
                            </p>
                        </div>
                    </div>

                    {/* Opportunity department */}
                    <div className="absolute left-6 top-0 -translate-y-1/2 transform">
                        <div className="flex gap-4">
                            <p
                                className={`rounded-full border-2 border-primary-main bg-primary-main px-5 py-3 font-bold`}
                                style={{
                                    backdropFilter: 'blur(6px)',
                                }}
                            >
                                {t('Opportunity')}
                            </p>
                            <p
                                className="rounded-full border-2 border-pink-500 px-5 py-3 font-bold"
                                style={{
                                    backdropFilter: 'blur(6px)',
                                }}
                            >
                                {opportunity.departament.name}
                            </p>
                        </div>
                    </div>

                    {/* Action Arrow */}
                    <div className="absolute right-2 top-2">
                        <ActionArrow
                            actions={(() => {
                                const canEditTasks =
                                    opportunity.actionType !== 'DOCUMENTS' &&
                                    opportunity.actionType !== 'FILES' &&
                                    actionArrow.edit;

                                const canEditDocuments =
                                    (opportunity.actionType === 'DOCUMENTS' || opportunity.actionType === 'FILES') &&
                                    editDocuments;

                                const displayArrow =
                                    actionArrow.mark || actionArrow.edit || actionArrow.gantt || actionArrow.notify;

                                return userIsResponsible && displayArrow
                                    ? [
                                          {
                                              name: t('Mark as done'),
                                              render: actionArrow.mark && opportunity.status !== 'DONE',
                                              action: () => {
                                                  confirm(
                                                      t('Are you sure you want to set as done this opportunity?'),
                                                      () => {
                                                          setTimeout(() => {
                                                              updateOpportunity(opportunity, 'DONE');
                                                          }, 250);
                                                      },
                                                  );
                                              },
                                          },
                                          {
                                              name: t('Mark as failed'),
                                              render: actionArrow.mark && opportunity.status !== 'FAILED',
                                              action: () => {
                                                  confirm(
                                                      t('Are you sure you want to set as failed this opportunity?'),
                                                      () => {
                                                          setTimeout(() => {
                                                              updateOpportunity(opportunity, 'FAILED');
                                                          }, 250);
                                                      },
                                                  );
                                              },
                                          },
                                          {
                                              name: t('Mark as todo'),
                                              render: actionArrow.mark && opportunity.status !== 'TODO',
                                              action: () => {
                                                  confirm(
                                                      t('Are you sure you want to set as todo this opportunity?'),
                                                      () => {
                                                          setTimeout(() => {
                                                              updateOpportunity(opportunity, 'TODO');
                                                          }, 250);
                                                      },
                                                  );
                                              },
                                          },
                                          {
                                              name: t('Edit'),
                                              render: canEditTasks || canEditDocuments,
                                              action: () => {
                                                  setEditActivity();
                                              },
                                          },
                                          {
                                              name: t('Delete'),
                                              render: canEditTasks || canEditDocuments,
                                              action: () => {
                                                  globalContext.setGlobalModalOpen(true);
                                                  globalContext.setGlobalModalChildren(
                                                      <div className="flex-col">
                                                          <p className="mb-8 text-center text-2xl font-bold text-main-text">
                                                              {t('Are you sure you want to')}
                                                              <br />
                                                              {t('delete this opportunity?')}
                                                          </p>
                                                          <div className="flex items-center justify-center">
                                                              <Button
                                                                  color="primary"
                                                                  onClick={() => {
                                                                      deleteOpportunity(opportunity);
                                                                      globalContext.setGlobalModalOpen(false);
                                                                  }}
                                                              >
                                                                  {t('Yes')}
                                                              </Button>
                                                              <div className="w-4" />
                                                              <Button
                                                                  onClick={() =>
                                                                      globalContext.setGlobalModalOpen(false)
                                                                  }
                                                              >
                                                                  {t('No')}
                                                              </Button>
                                                          </div>
                                                      </div>,
                                                  );
                                              },
                                          },
                                          {
                                              name: 'Gantt',
                                              render: actionArrow.gantt,
                                              action: setSeeGantt,
                                          },
                                          {
                                              name: t('Notify'),
                                              render: actionArrow.notify,
                                              action: () => onNotify(opportunity.id),
                                          },
                                      ]
                                    : [];
                            })()}
                        />
                    </div>

                    <div className="mb-1.5 rounded-xl border border-layout-transparent bg-layout-transparent px-6 py-4 pt-10 text-main-text transition-colors sm:pt-24">
                        {/* Actitity name (header) */}
                        <h3 className="mb-5 font-semibold">{opportunity.title}</h3>
                        {/* Opportunity body */}

                        {opportunity.description && opportunity.activitiesTasks.length > 0 && (
                            <iframe
                                className="mb-5 h-64 w-full rounded-xl border border-layout-lighter bg-white  p-2 md:h-96"
                                srcDoc={`<base target="_blank" /> ${DOMPurify.sanitize(opportunity.description)}`}
                            />
                        )}

                        {/* Opportunity footer */}
                        {/* Opportunity Author */}
                        <div className="mb-5 flex items-center">
                            <div className="mr-5">
                                <span className="pointer-events-none text-dark-text">{t('Opportunity author')}: </span>
                                <span
                                    className={`font-semibold ${userLink && linkStyle}`}
                                    onClick={() => {
                                        if (!userLink) return;
                                        isContact
                                            ? history.push(
                                                  crm.base + crm.contactActivity.base + '/' + opportunity.authorId,
                                              )
                                            : history.push(
                                                  configurator.base +
                                                      configurator.users.base +
                                                      configurator.users.update +
                                                      '/' +
                                                      opportunity.authorId +
                                                      '?tab=1',
                                              );
                                    }}
                                >
                                    {opportunity.author?.profile?.name}
                                </span>
                            </div>

                            {/* Opportunity Asignee */}
                            {responsible && (
                                <div className="mr-5">
                                    <span className="pointer-events-none text-dark-text">{t('Responsible')}: </span>

                                    <span className="mr-1 cursor-default font-semibold text-main-text">
                                        {responsible}
                                    </span>
                                </div>
                            )}
                            {supervisor && (
                                <div className="mr-5">
                                    <span className="pointer-events-none text-dark-text">{t('Supervisor')}: </span>

                                    <span className="mr-1 cursor-default font-semibold text-main-text">
                                        {supervisor}
                                    </span>
                                </div>
                            )}
                            {watcher && (
                                <div className="mr-5">
                                    <span className="pointer-events-none text-dark-text">
                                        {t('Watcher / Beneficiary')}:{' '}
                                    </span>

                                    <span className="mr-1 cursor-default font-semibold text-main-text">{watcher}</span>
                                </div>
                            )}
                        </div>

                        {/* SubOpportunity section */}
                        <div
                            className={`relative grid transform transition-all duration-300 ${open ? 'mb-5' : ''}`}
                            style={{
                                gridTemplateRows: open ? '1fr' : `0fr`,
                            }}
                        >
                            <div className="overflow-hidden">
                                {opportunity.activitiesTasks.map((subOpportunity, index) => (
                                    <div
                                        key={subOpportunity.id}
                                        style={{
                                            // zIndex: opportunity.activitiesTasks.length - index,
                                            position: 'relative',
                                        }}
                                    >
                                        <SubOpportunityDisplay
                                            subOpportunity={subOpportunity}
                                            userIsResponsible={userIsResponsible}
                                            canViewComments={true}
                                            canAllComments={canAll}
                                            onAddComment={handleAddComment}
                                            onDeleteComment={handleDeleteComment}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div
                            className={`mx-auto flex h-14 w-14 transform cursor-pointer items-center justify-center rounded-full border border-layout-transparent bg-layout-transparent transition-transform duration-300 hover:bg-layout-transparent-dark ${
                                open ? 'rotate-180' : 'rotate-0'
                            }`}
                            onClick={() => setOpen((prev) => !prev)}
                        >
                            <ChevronDown className="h-9 w-9 opacity-60" />
                        </div>
                    </div>

                    {/* Comment section */}
                    <div className="pb-8">
                        <Comments
                            comments={formattedComments}
                            canAdd={canAll}
                            canDelete={canAll}
                            onSubmit={handleAddComment.bind(null, 'activities', opportunity.id)}
                            onDelete={handleDeleteComment.bind(null, 'activities')}
                        />
                    </div>
                </div>
            )}
        </>
    );
};

Opportunity.propTypes = {
    onNotify: PropTypes.func,
    opportunity: PropTypes.object,
    deleteOpportunity: PropTypes.func,
    updateOpportunity: PropTypes.func,
    actionArrow: PropTypes.object,
    userLink: PropTypes.bool,
    setSeeGantt: PropTypes.func.isRequired,
    setEditActivity: PropTypes.func,
    editDocuments: PropTypes.bool,
};

Opportunity.defaultProps = {
    onNotify: () => null,
    opportunity: null,
    deleteOpportunity: () => null,
    updateOpportunity: () => null,
    actionArrow: {
        mark: false,
        edit: false,
        gantt: false,
        notify: false,
    },
    userLink: false,
    setEditActivity: () => null,
    editDocuments: false,
};

export default Opportunity;
