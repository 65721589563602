import React from 'react';
import { Route, Switch } from 'react-router-dom';

import FinancialTools from 'pages/financial-tool/financial-tool';
import { Header } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { accountancy } from 'routes';

import AccountingAccounts from './accounting-accounts/accounting-accounts';
import CreateContractType from './contract-type-nomenclator/create-contract-type';
import EditContractType from './contract-type-nomenclator/edit-contract-type';
import ManageContractType from './contract-type-nomenclator/manage-contract-type';
import CreateExpense from './expenses/create-expense';
import EditExpense from './expenses/edit-expense';
import ManageExpenses from './expenses/manage-expenses';
import CreateInvoice from './invoices/create-invoice';
import EditInvoice from './invoices/edit-invoice';
import ManageInvoices from './invoices/manage-invoices';
import CreateRevenue from './revenue/create-revenue';
import EditRevenue from './revenue/edit-revenue';
import ManageRevenue from './revenue/manage-revenue';

const Accountancy = () => {
    const { t } = useTranslation();

    return (
        <>
            <Helmet>
                <title>{t('Accountancy')}</title>
            </Helmet>
            <Switch>
                <Route exact path={accountancy.base}>
                    <Header pageTitle="Accountancy 2" />
                </Route>
                <Route exact path={accountancy.base + accountancy.expenseNomenclature.base}>
                    <ManageExpenses />
                </Route>
                <Route
                    path={
                        accountancy.base +
                        accountancy.expenseNomenclature.base +
                        accountancy.expenseNomenclature.update +
                        '/:expenseId'
                    }
                >
                    <EditExpense />
                </Route>
                <Route
                    path={
                        accountancy.base + accountancy.expenseNomenclature.base + accountancy.expenseNomenclature.create
                    }
                >
                    <CreateExpense />
                </Route>
                <Route exact path={accountancy.base + accountancy.revenueNomenclature.base}>
                    <ManageRevenue />
                </Route>
                <Route
                    path={
                        accountancy.base +
                        accountancy.revenueNomenclature.base +
                        accountancy.revenueNomenclature.update +
                        '/:revenueId'
                    }
                >
                    <EditRevenue />
                </Route>
                <Route
                    path={
                        accountancy.base + accountancy.revenueNomenclature.base + accountancy.revenueNomenclature.create
                    }
                >
                    <CreateRevenue />
                </Route>
                <Route path={accountancy.base + accountancy.accountingAccounts.base}>
                    <AccountingAccounts />
                </Route>
                {/* Contract type nomenclature */}
                <Route exact path={accountancy.base + accountancy.contractTypeNomenclature.base}>
                    <ManageContractType />
                </Route>
                <Route
                    path={
                        accountancy.base +
                        accountancy.contractTypeNomenclature.base +
                        accountancy.contractTypeNomenclature.update +
                        '/:contractTypeId'
                    }
                >
                    <EditContractType />
                </Route>
                <Route
                    path={
                        accountancy.base +
                        accountancy.contractTypeNomenclature.base +
                        accountancy.contractTypeNomenclature.create
                    }
                >
                    <CreateContractType />
                </Route>
                {/* Invoices */}
                <Route exact path={accountancy.base + accountancy.invoice.base}>
                    <ManageInvoices />
                </Route>
                <Route path={accountancy.base + accountancy.invoice.base + accountancy.invoice.update + '/:invoiceId'}>
                    <EditInvoice />
                </Route>
                <Route path={accountancy.base + accountancy.invoice.base + accountancy.invoice.create}>
                    <CreateInvoice />
                </Route>
                {/* P&L and Cashflow */}
                <Route path={accountancy.base + accountancy.PL.base} key={accountancy.base + accountancy.PL.base}>
                    <FinancialTools type="pl" />
                </Route>

                <Route
                    path={accountancy.base + accountancy.cashflow.base}
                    key={accountancy.base + accountancy.cashflow.base}
                >
                    <FinancialTools type="cashflow" />
                </Route>
            </Switch>
        </>
    );
};

export default Accountancy;
