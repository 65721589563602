import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';

import {
    Button,
    Checkbox,
    CircularProgress,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
} from '@material-ui/core';

import GlobalContext from 'contexts/GlobalContext';
import TaskManagementContext from 'contexts/TaskManagementContext';
import UserContext from 'contexts/UserContext';
import useScreenSizes from 'hooks/useScreenSizes.js';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { Dropdown, LabelWrapper, Tabs } from 'RaisisComponents/index.js';
import { useTranslation } from 'react-i18next';
import { projectManagement } from 'routes';
import { errorHandling } from 'utils';
import API from 'utils/axios';
import { getPartners } from 'utils/getterFunctions';

import PMTaskManagementComment from './pm-task-management-comment';
import PMTaskManagementDocuments from './pm-task-management-documents';
import PMTaskManagementFiles from './pm-task-management-files';
import PMTaskManagementInvoices from './pm-task-management-invoices';
import PMTaskManagementOpportunities from './pm-task-management-opportunities';
import PMTaskManagementTasks from './pm-task-management-tasks';

const PMTaskManagementModal = (props) => {
    const { theme } = useContext(GlobalContext);
    const history = useHistory();

    const { closeModal, departments, tenants } = props;
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const contentRef = useRef(null);

    const taskManagementContext = useContext(TaskManagementContext);

    const [loading, setLoading] = useState(false);
    const [width, height] = useScreenSizes();

    const [partnersCheck, setPartnersCheck] = useState(false);
    const [selectedPartner, setSelectedPartner] = useState(null);
    const [partners, setPartners] = useState([]);

    const [activityDepartment, setActivityDepartment] = useState(null);
    const handleDepartmentChange = (e) => {
        setActivityDepartment(e.target.value);
    };

    const [activityType, setActivityType] = useState(0);

    /**
     * Creating an array with objects for tags component to display name of the partners
     */
    const allPartners = partners.map((p) => {
        const data = JSON.parse(p.data);
        return data.standard.name;
    });

    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                if (departments.length) setActivityDepartment(departments[0].id);

                const partnersAccounts = await getPartners();
                setPartners(partnersAccounts);
            } catch (err) {
                console.error(err);
                enqueueSnackbar(errorHandling(err).length > 100 ? errorHandling(err) : t(errorHandling(err)), {
                    variant: 'error',
                });
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    useEffect(() => {
        let observer = null;

        if (contentRef.current) {
            observer = new ResizeObserver((entries) => {
                if (contentRef.current) {
                    for (const entry of entries) {
                        if (entry.target.getBoundingClientRect().height >= height * 0.6) {
                            contentRef.current.style.overflowY = 'auto';
                            contentRef.current.style.overflowX = 'hidden';
                        } else {
                            contentRef.current.style.overflow = 'visible';
                        }
                    }
                }
            });

            observer.observe(contentRef.current);
        }

        return () => {
            if (observer) {
                observer.disconnect();
            }
        };
    }, [loading, height]);

    return (
        <div className="relative">
            {loading || taskManagementContext.isCreating ? (
                <div className="py-20 text-center">
                    <CircularProgress />
                </div>
            ) : (
                <>
                    {departments.length > 0 ? (
                        <div
                            style={{
                                width: width > 1000 ? '500px' : '90vw',
                                maxWidth: '500px',
                            }}
                        >
                            {/* Modal Header */}
                            <div className="mb-5 flex items-center justify-between px-10 pt-10 sm:px-4 sm:pt-4">
                                <h2 className="font-semibold">{t('Add activity')}</h2>

                                <CloseIcon
                                    className="text-prim ml-10 cursor-pointer text-primary-main opacity-75 hover:opacity-100"
                                    style={{
                                        fontSize: '2.5rem',
                                        transition: 'opacity .2s ease',
                                    }}
                                    onClick={() => {
                                        closeModal();
                                    }}
                                />
                            </div>

                            <div className={`p-10 sm:p-4`} style={{ maxHeight: '70vh' }} ref={contentRef}>
                                <div className="mb-5">
                                    {/* Modal departments */}
                                    <div>
                                        <FormControl component="fieldset">
                                            <RadioGroup
                                                aria-label="status"
                                                name="status-filter"
                                                value={activityDepartment}
                                                onChange={(e) => handleDepartmentChange(e)}
                                            >
                                                <div className="no-user-select-recursive flex items-center text-main-text">
                                                    <div className="flex flex-wrap">
                                                        {departments.map((d) => (
                                                            <FormControlLabel
                                                                key={d.id}
                                                                value={d.id}
                                                                control={<Radio />}
                                                                label={d.name}
                                                            />
                                                        ))}
                                                    </div>
                                                </div>
                                            </RadioGroup>
                                        </FormControl>
                                    </div>

                                    <div className="mb-5">
                                        {/* Modal assigned partners checkbox */}
                                        <div>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={partnersCheck}
                                                        onChange={() => {
                                                            setPartnersCheck(!partnersCheck);

                                                            // This check if it is a partner selected, and if is, set the partner to null to prevent sending the partnerId in request
                                                            if (selectedPartner >= 0) setSelectedPartner(null);
                                                        }}
                                                        name="Email"
                                                        style={{
                                                            color: `rgb(${theme['UI-COLORS']['MAIN-PRIMARY'].color})`,
                                                        }}
                                                        // color="primary"
                                                    />
                                                }
                                                label={<p className="user-select-none">{t('Assign partner')}</p>}
                                            />
                                        </div>

                                        {/* Modal assigned partners tags */}
                                        {partnersCheck && (
                                            <div className="w-1/3">
                                                <Dropdown
                                                    options={allPartners}
                                                    placeholder={t('Choose the option')}
                                                    selectedOption={selectedPartner}
                                                    setSelectedOption={(e) => {
                                                        setSelectedPartner(e);
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </div>

                                    {/* Activity type tab */}
                                    <div className="mb-8">
                                        <Tabs
                                            tabs={[
                                                t('Comments'),
                                                t('Tasks'),
                                                t('Opportunities'),
                                                t('Files'),
                                                t('Documents'),
                                            ]}
                                            activeTab={activityType}
                                            setActiveTab={setActivityType}
                                            small
                                        />
                                    </div>

                                    {activityType === 0 && (
                                        <PMTaskManagementComment
                                            departmentId={activityDepartment}
                                            tenantUsers={tenants}
                                            closeModal={closeModal}
                                            partnerId={partners[selectedPartner]?.id}
                                        />
                                    )}

                                    {activityType === 1 && (
                                        <PMTaskManagementTasks
                                            departmentId={activityDepartment}
                                            tenantUsers={tenants}
                                            closeModal={closeModal}
                                            partnerId={partners[selectedPartner]?.id}
                                        />
                                    )}

                                    {activityType === 2 && (
                                        <PMTaskManagementOpportunities
                                            departmentId={activityDepartment}
                                            tenantUsers={tenants}
                                            closeModal={closeModal}
                                            partnerId={partners[selectedPartner]?.id}
                                        />
                                    )}

                                    {activityType === 3 && (
                                        <PMTaskManagementFiles
                                            departmentId={activityDepartment}
                                            tenantUsers={tenants}
                                            closeModal={closeModal}
                                            partnerId={partners[selectedPartner]?.id}
                                        />
                                    )}

                                    {activityType === 4 && (
                                        <PMTaskManagementDocuments
                                            departmentId={activityDepartment}
                                            tenantUsers={tenants}
                                            closeModal={closeModal}
                                            partnerId={partners[selectedPartner]?.id}
                                        />
                                    )}

                                    {/* {activityType === 4 && (
                                        <PMTaskManagementInvoices
                                            departmentId={activityDepartment}
                                            tenantUsers={tenants}
                                            closeModal={closeModal}
                                            partnerId={partners[selectedPartner]?.id}
                                        />
                                    )} */}
                                </div>
                            </div>
                        </div>
                    ) : (
                        // If the user don't have departments, is redirected to department creation page
                        <div className="px-4 py-20 text-center">
                            <p className="mb-8 text-base font-semibold opacity-75">
                                {t('To add a new wiki, you must add a department first!')}
                            </p>
                            <Button
                                startIcon={<AddIcon />}
                                color="primary"
                                onClick={() =>
                                    history.push(
                                        projectManagement.base +
                                            projectManagement.projectManagementConfigurator.base +
                                            '/add-department',
                                    )
                                }
                            >
                                {t('Add department')}
                            </Button>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

PMTaskManagementModal.propTypes = {
    closeModal: PropTypes.func,
    getWikis: PropTypes.func,
    wikiForEdit: PropTypes.object,
    setWikiForEdit: PropTypes.func,
    departments: PropTypes.array,
    tenants: PropTypes.array,
};

PMTaskManagementModal.defaultProps = {
    closeModal: () => null,
    getWikis: () => null,
    wikiForEdit: {},
    setWikiForEdit: () => null,
    departments: [],
    tenants: [],
};

export default PMTaskManagementModal;
