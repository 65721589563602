import React, { useContext, useEffect, useMemo, useState } from 'react';

import CheckIcon from '@material-ui/icons/Check';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import BasicTooltip from 'components/shared/basic-tooltip';
import Comments from 'components/shared/comments/comments';
import ActivityContext from 'contexts/ActivityContext';
import UserContext from 'contexts/UserContext';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { fileNameFromKey, formatDate, linkStyle } from 'utils';
import API from 'utils/axios';

const taskStatusMapper = {
    NEW: 'New Task',
    IN_PROGRESS: 'In Progress',
    DONE: 'Task Done',
};

const TaskDisplay = (props) => {
    const { t } = useTranslation();
    const {
        task,
        canBeUpgraded,
        expandable,
        userIsResponsible,
        canViewComments,
        canAllComments,
        onAddComment,
        onDeleteComment,
    } = props;
    const [checked, setChecked] = useState(false);
    const [open, setOpen] = useState(false);
    const [taskStatus, setTaskStatus] = useState(task.status);
    const [tipMessage, setTipMessage] = useState('');
    const { fetchActivities } = useContext(ActivityContext);
    const { user } = useContext(UserContext);

    const formattedComments = useMemo(
        () =>
            task.comments.map((element) => ({
                ...element,
                text: element.comment,
                createdAt: element.createAt,
                files: element.files.map((file) => ({
                    ...file,
                    id: file.fileId,
                    url: file.urls,
                    fileName: fileNameFromKey(file.file.key),
                })),
            })),
        [task.comments],
    );

    const canCheckTask = userIsResponsible || task.responsible.map((user) => user.userId).includes(user.id);

    useEffect(() => {
        if (taskStatus === 'DONE') setChecked(true);
        else if (checked) setChecked(false);
    }, [taskStatus]);

    const taskStatusUpgrade = () => {
        if (!canBeUpgraded) return;
        if (taskStatus === 'DONE') return;
        try {
            API.put('/activity_task', {
                id: task.id,
            });
            if (taskStatus === 'NEW') setTaskStatus('IN_PROGRESS');
            if (taskStatus === 'IN_PROGRESS') setTaskStatus('DONE');
            setTimeout(() => {
                fetchActivities();
            }, 250);
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        if (taskStatus === 'NEW') setTipMessage(`${t('Mark task as')}: ${t('To Do')}`);
        if (taskStatus === 'IN_PROGRESS') setTipMessage(`${t('Mark task as')}: ${t('Done')}`);
        if (taskStatus === 'DONE') setTipMessage(t('Task finished'));
    }, [taskStatus]);

    return (
        <>
            <div className="relative mb-1 rounded-xl border border-layout-transparent bg-layout-transparent px-14 pb-3 pt-1.5 sm:px-8">
                {/* Action arrow */}
                {expandable && (
                    <div
                        className="absolute right-2 top-2 flex h-8 w-8 cursor-pointer items-center justify-center rounded-full hover:bg-layout-transparent"
                        onClick={() => setOpen(!open)}
                    >
                        <KeyboardArrowDownIcon
                            fontSize="medium"
                            style={{
                                transition: 'transform .2s ease',
                            }}
                            className={`transform text-main-text  ${open ? 'rotate-180' : 'rotate-0'}`}
                        />
                    </div>
                )}

                <div className="flex flex-col gap-1 sm:items-start sm:gap-3">
                    <div className="no-user-select-recursive mb-0.5 flex cursor-pointer flex-wrap items-center gap-3">
                        <div
                            className={`relative h-4 w-4 flex-shrink-0 rounded-full border-primary-main  transition-colors ${
                                checked ? 'bg-primary-main' : ''
                            }`}
                            style={{ borderWidth: '1px' }}
                        >
                            <CheckIcon
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    fontSize: '0.5rem',
                                }}
                                className={`${
                                    checked ? 'opacity-100' : 'opacity-0'
                                } text-buttons-text transition-opacity`}
                            />
                        </div>
                        <p className="flex-1 font-semibold">{task.name}</p>
                        {canCheckTask && (
                            <div className="flex flex-shrink-0 translate-y-1 transform items-center ">
                                <BasicTooltip tip={tipMessage}>
                                    <div
                                        className="flex flex-shrink-0 rounded-full bg-layout-transparent px-3 py-1 text-sm font-semibold text-main-text transition-colors hover:bg-layout-transparent-dark"
                                        onClick={taskStatusUpgrade}
                                    >
                                        {t(taskStatusMapper[taskStatus])}
                                    </div>
                                </BasicTooltip>
                            </div>
                        )}
                    </div>

                    {/* Deadline */}
                    <p className="no-user-select-recursive pointer-events-none">
                        <span className="text-dark-text">{t('Deadline')}: </span>
                        <span className="font-semibold">{formatDate(task.estimatedEndDate)}</span>
                    </p>
                </div>

                {open && (
                    <div className="mt-1.5 rounded-md bg-layout-transparent px-6 py-4">
                        <div className="mb-4 flex justify-between gap-5">
                            {/* <p className="w-1/4">
                                <span className="pointer-events-none no-user-select-recursive opacity-50">Autor:</span>
                                <br />
                                <span className={`font-semibold ${linkStyle}`}>Marius</span>
                            </p> */}
                            <p className="w-1/4 md:w-full">
                                <span className="no-user-select-recursive pointer-events-none text-dark-text">
                                    {t('Responsible')}:
                                </span>
                                <br />
                                {task.responsible.map((resp) => (
                                    <span key={resp.id} className={`mr-4 break-all font-semibold ${linkStyle}`}>
                                        {resp.user.email}
                                    </span>
                                ))}
                            </p>
                            {/* <p className="w-1/4">
                                <span className="pointer-events-none no-user-select-recursive opacity-50">
                                    Accept final:
                                </span>
                                <br />
                                <span className={`font-semibold ${linkStyle}`}>Alex</span>
                            </p> */}
                            {/* <p className="w-1/4">
                                <span className="pointer-events-none no-user-select-recursive opacity-50">
                                    Watcher:{' '}
                                </span>
                                <br />
                                <span className={`font-semibold ${linkStyle}`}>Octavian</span>
                            </p> */}
                        </div>

                        {/* <p className="mb-4">
                            <span className="pointer-events-none no-user-select-recursive opacity-50">
                                Dependent de:
                            </span>
                            <br />
                            <span className={`font-semibold ${linkStyle}`}>
                                Milestone Name A, Sub-Milestone Name B2
                            </span>
                        </p> */}

                        <p>
                            <span className="no-user-select-recursive pointer-events-none text-dark-text">
                                {t('Timeline:')}
                            </span>
                            <br />
                            <span className={`font-semibold ${linkStyle}`}>
                                {formatDate(task.estimatedStartDate)} - {formatDate(task.estimatedEndDate)}
                            </span>
                        </p>
                    </div>
                )}
            </div>

            {/* Comment section */}
            {canViewComments && (
                <div className="pb-4">
                    <Comments
                        comments={formattedComments}
                        canAdd={canAllComments}
                        canDelete={canAllComments}
                        onSubmit={onAddComment.bind(null, 'tasks', task.id)}
                        onDelete={onDeleteComment.bind(null, 'tasks')}
                        size="small"
                    />
                </div>
            )}
        </>
    );
};

TaskDisplay.propTypes = {
    task: PropTypes.object.isRequired,
    canBeUpgraded: PropTypes.bool,
    expandable: PropTypes.bool,
    userIsResponsible: PropTypes.bool,
    canViewComments: PropTypes.bool,
    canAllComments: PropTypes.bool,
    onAddComment: PropTypes.func,
    onDeleteComment: PropTypes.func,
};

TaskDisplay.defaultProps = {
    task: {},
    canBeUpgraded: false,
    expandable: true,
    userIsResponsible: false,
    canViewComments: false,
    canAllComments: false,
    onAddComment: () => null,
    onDeleteComment: () => null,
};

export default TaskDisplay;
