import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import CallMergeIcon from '@material-ui/icons/CallMerge';
import EditAttributesIcon from '@material-ui/icons/EditAttributes';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';

import {
    Box,
    Button,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core';

import ProjectDisplay from 'components/shared/configurator/project-display';
import DisplayPaginatedResults from 'components/shared/display-paginated-results';
import NoDataPlaceholder from 'components/shared/no-data-placeholder';
import GlobalContext from 'contexts/GlobalContext';
import UserContext from 'contexts/UserContext';
import PropTypes from 'prop-types';
import { ExpandableRow, LabelWrapper, TableSeparator, Tabs } from 'RaisisComponents';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { quotes } from 'routes';
import { calculateTotal, calculateVATValue, formatDate, toLocaleNumber } from 'utils';
import API from 'utils/axios';

import OfferArticleHistory from '../newBidding/offer-article-history';

const StockRow = (props) => {
    const { stock } = props;
    const history = useHistory();
    const { currencyObj, language } = useContext(GlobalContext);
    const { t } = useTranslation();

    const [selectedArticle, setSelectedArticle] = useState(0);
    const article = stock.articles[selectedArticle];

    const optionClass = 'text-md text-dark-text mb-4';
    const optionClassValues = 'text-md text-dark-text mb-4 flex flex-col';
    const optionSpanClass = 'text-main-text font-bold';

    /** Functions for calculation of VAT and finale price with VAT  */
    const totalPrice =
        Number(article.price.pricePerUnit) +
        article.options.map((a) => Number(a.price.pricePerUnit))?.reduce((a, b) => a + b, 0);
    const totalVatValue =
        Number(calculateVATValue(article.price.pricePerUnit, article.price.procentualVAT)) +
        article.options
            .map((a) => Number(calculateVATValue(a.price.pricePerUnit, a.price.procentualVAT)))
            ?.reduce((a, b) => a + b, 0);

    const { checkPerm } = useContext(UserContext);

    const canAll = checkPerm([
        {
            permissionId: '18',
            permissionType: 'ALL',
        },
    ]);

    return (
        <ExpandableRow
            colSpan={5}
            row={
                <>
                    <TableCell>{stock.name}</TableCell>
                    <TableCell>{stock.articles.length}</TableCell>
                    <TableCell>{stock.OriginalArticle.name}</TableCell>
                    <TableCell>{formatDate(stock.createdAt)}</TableCell>
                </>
            }
            collapse={
                <>
                    <div className="flex flex-col gap-3 p-3">
                        <h2>{t('Select article')}</h2>
                        <div>
                            <Tabs
                                small
                                activeTab={selectedArticle}
                                setActiveTab={setSelectedArticle}
                                tabs={Array.from({ length: stock.articles.length }, (_, i) => i + 1)}
                            />
                        </div>
                    </div>

                    {canAll && (
                        <div className="mt-10 flex justify-between gap-3 p-3">
                            <Button
                                color="secondary"
                                startIcon={<VisibilityOutlinedIcon style={{ fontSize: 25 }} />}
                                onClick={() =>
                                    history.push(
                                        quotes.base +
                                            quotes.nomenclature.base +
                                            quotes.stocks.base +
                                            quotes.stocks.update +
                                            '/' +
                                            stock.id +
                                            `?articleId=${article.id}`,
                                    )
                                }
                            >
                                {t('View stock & edit')}
                            </Button>

                            <div className="flex gap-3">
                                <Button
                                    color="primary"
                                    startIcon={<EditAttributesIcon style={{ fontSize: 25 }} />}
                                    onClick={() =>
                                        history.push(quotes.base + quotes.attributes.base + '/' + article.id)
                                    }
                                >
                                    {article.ArticleAttribute.length
                                        ? t('Add or view attributes')
                                        : t('Add attributes')}
                                </Button>

                                <Button
                                    color="primary"
                                    startIcon={<CallMergeIcon style={{ fontSize: 25 }} />}
                                    onClick={() =>
                                        history.push(quotes.base + quotes.variations.base + '/' + article.id)
                                    }
                                >
                                    {article.ArticleVariations.length
                                        ? t('Add or view variations')
                                        : t('Add variations')}
                                </Button>
                            </div>
                        </div>
                    )}

                    <div className="-ml-3 grid w-10/12 grid-cols-2 gap-16 2xl:w-full lg:-ml-0 lg:grid-cols-1 lg:p-4">
                        <div>
                            <div className="mt-10 bg-layout-transparent p-6">
                                <h4 className="mb-5 text-2xl">{article.name}</h4>
                                <p className={optionClass}>
                                    {t('Article type')}:{' '}
                                    <span className={optionSpanClass}>
                                        {article.type === 'SERVICES'
                                            ? t('Service')
                                            : article.type === 'FIZIC'
                                              ? t('Physical product')
                                              : article.type === 'DIGITAL'
                                                ? t('Digital product')
                                                : ''}
                                    </span>
                                </p>
                                <p className={optionClass}>
                                    {t('Article category')}:{' '}
                                    <span className={optionSpanClass}>
                                        {article.categories.map(
                                            (c, index) =>
                                                c.categorie.name + (index < article.categories.length - 1 ? ', ' : ''),
                                        )}
                                    </span>
                                </p>
                                <p className={optionClass}>
                                    {t('Article code(SKU)')}: <span className={optionSpanClass}>{article.sku}</span>
                                </p>
                                <p className={optionClass}>
                                    {t('Description')}: <span className={optionSpanClass}>{article.description}</span>
                                </p>

                                <div className="inline-flex gap-6">
                                    <p className={optionClassValues}>
                                        {t('Price without VAT')}:{' '}
                                        <span className={optionSpanClass}>
                                            {toLocaleNumber(article.price.pricePerUnit, language, 2)}{' '}
                                            {currencyObj.currency}
                                        </span>
                                    </p>
                                    <p className={optionClassValues}>
                                        {t('VAT rate')}:{' '}
                                        <span className={optionSpanClass}>
                                            {toLocaleNumber(article.price.procentualVAT, language, 2)} %
                                        </span>
                                    </p>
                                    <p className={optionClassValues}>
                                        {t('VAT value')}:{' '}
                                        <span className={optionSpanClass}>
                                            {toLocaleNumber(
                                                calculateVATValue(
                                                    article.price.pricePerUnit,
                                                    article.price.procentualVAT,
                                                ),
                                                language,
                                                2,
                                            )}{' '}
                                            {currencyObj.currency}
                                        </span>
                                    </p>
                                    <p className={optionClassValues}>
                                        {t('Final price with VAT')}:{' '}
                                        <span className={optionSpanClass}>
                                            {toLocaleNumber(
                                                calculateTotal(
                                                    calculateVATValue(
                                                        article.price.pricePerUnit,
                                                        article.price.procentualVAT,
                                                    ),
                                                    article.price.pricePerUnit,
                                                ),
                                                language,
                                                2,
                                            )}{' '}
                                            {currencyObj.currency}
                                        </span>
                                    </p>
                                </div>
                            </div>
                            {article.options.map((o) => (
                                <div
                                    className="border-t-2 border-layout-transparent-dark p-6 hover:bg-layout-transparent"
                                    key={o.id}
                                >
                                    <h4 className="mb-5 text-2xl">{o.name}</h4>
                                    <p className={optionClass}>
                                        {t('Variable code(SKU)')}: <span className={optionSpanClass}>{o.sku}</span>
                                    </p>
                                    <p className={optionClass}>
                                        {t('Description')}: <span className={optionSpanClass}>{o.description}</span>
                                    </p>
                                    {o.price.type === 'BASE_PROCENT' ? (
                                        <p className={optionClass}>
                                            {t('Percentage of basic item')}:{' '}
                                            <span className={optionSpanClass}>{o.price.priceProcent} %</span>
                                        </p>
                                    ) : o.price.type === 'BASE_PROCENT_AND_VARIABLE' ? (
                                        <p className={optionClass}>
                                            {t('Percentage of the entire article(article + all variables)')}:{' '}
                                            <span className={optionSpanClass}>{o.price.priceProcent} %</span>
                                        </p>
                                    ) : null}

                                    <div className="inline-flex gap-6">
                                        <p className={optionClassValues}>
                                            {t('Price without VAT')}:{' '}
                                            <span className={optionSpanClass}>
                                                {toLocaleNumber(o.price.pricePerUnit, language, 2)}{' '}
                                                {currencyObj.currency}
                                            </span>
                                        </p>
                                        <p className={optionClassValues}>
                                            {t('VAT rate')}:{' '}
                                            <span className={optionSpanClass}>
                                                {toLocaleNumber(o.price.procentualVAT, language, 2)} %
                                            </span>
                                        </p>
                                        <p className={optionClassValues}>
                                            {t('VAT value')}:{' '}
                                            <span className={optionSpanClass}>
                                                {toLocaleNumber(
                                                    calculateVATValue(o.price.pricePerUnit, o.price.procentualVAT),
                                                    language,
                                                    2,
                                                )}{' '}
                                                {currencyObj.currency}
                                            </span>
                                        </p>
                                        <p className={optionClassValues}>
                                            {t('Final price with VAT')}:{' '}
                                            <span className={optionSpanClass}>
                                                {toLocaleNumber(
                                                    calculateTotal(
                                                        calculateVATValue(o.price.pricePerUnit, o.price.procentualVAT),
                                                        o.price.pricePerUnit,
                                                    ),
                                                    language,
                                                    2,
                                                )}{' '}
                                                {currencyObj.currency}
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div className="w-9/12 sm:w-full">
                            <OfferArticleHistory
                                article={article}
                                style={{
                                    marginTop: '2.5rem',
                                    borderRadius: 'unset',
                                    backgroundColor: 'var(--layout-transparent)',
                                }}
                            />
                            <div className="mt-10 bg-layout-transparent p-7">
                                <h4 className="mb-4 text-2xl">{t('Price with all variables')}</h4>
                                <div className="grid grid-cols-3 gap-2 xl:grid-cols-1">
                                    <div>
                                        <LabelWrapper label={t('Price without VAT')} noPadding>
                                            <div className="pointer-events-none rounded-md bg-layout-transparent p-3 text-main-text">
                                                {toLocaleNumber(totalPrice, language, 2)} {currencyObj.currency}
                                            </div>
                                        </LabelWrapper>
                                    </div>

                                    <div>
                                        <LabelWrapper label={t('VAT value')} noPadding>
                                            <div className="pointer-events-none rounded-md bg-layout-transparent p-3 text-main-text">
                                                {toLocaleNumber(totalVatValue, language, 2)} {currencyObj.currency}
                                            </div>
                                        </LabelWrapper>
                                    </div>

                                    <div>
                                        <LabelWrapper label={t('Final price with VAT')} noPadding>
                                            <div className="pointer-events-none rounded-md bg-layout-transparent p-3 text-main-text">
                                                {toLocaleNumber(totalPrice + totalVatValue, language, 2)}{' '}
                                                {currencyObj.currency}
                                            </div>
                                        </LabelWrapper>
                                    </div>
                                </div>
                            </div>
                            {article.pm_planningOverviewProject && (
                                <ProjectDisplay
                                    project={article.pm_planningOverviewProject}
                                    style={{
                                        containerStyle: {
                                            marginTop: '4rem',
                                            padding: '1.75rem',
                                            backgroundColor: 'var(--layout-transparent)',
                                        },
                                        headerStyle: { fontSize: '1.5rem', lineHeight: '1.5rem' },
                                    }}
                                />
                            )}
                        </div>
                    </div>
                </>
            }
        />
    );
};

StockRow.propTypes = {
    stock: PropTypes.object,
};
StockRow.defaultProps = {
    stock: {},
};

const ManageStocks = () => {
    const { t } = useTranslation();

    const [loading, setLoading] = useState(true);
    const [stocks, setStocks] = useState([]);

    const getStocks = async (currentPage, perPage, controller) => {
        try {
            const response = await API.get('/stocks', {
                params: {
                    perPage,
                    currentPage,
                    pagesToLoad: 1,
                },
                signal: controller.signal,
            });

            setStocks(response.data.stocks.content);

            return response.data.stocks.length;
        } catch (err) {
            throw new Error(err);
        }
    };

    return (
        <>
            <Helmet>
                <title>{t('Stocks')}</title>
            </Helmet>

            <DisplayPaginatedResults loading={loading} setLoading={setLoading} getFunction={getStocks}>
                {loading ? (
                    <div className="flex h-64 w-full flex-grow items-center justify-center bg-layout-main">
                        <CircularProgress />
                    </div>
                ) : stocks.length ? (
                    <TableContainer component={Box}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t('Stock name')}</TableCell>
                                    <TableCell>{t('Quantity')}</TableCell>
                                    <TableCell>{t('Article name')}</TableCell>
                                    <TableCell>{t('Created at')}</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableSeparator />
                                {stocks.map((stock) => (
                                    <StockRow key={stock.id} stock={stock} />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <NoDataPlaceholder />
                )}
            </DisplayPaginatedResults>
        </>
    );
};

export default ManageStocks;
