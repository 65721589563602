import React, { useContext, useEffect, useState } from 'react';
import { Route, Switch, useParams } from 'react-router-dom';

import Footer from 'components/footer/footer';
import UserContext from 'contexts/UserContext';
import useNavProps from 'hooks/useNavProps';
import { useSnackbar } from 'notistack';
import CreateContract from 'pages/crm/contracts/create-contracts';
import EditContract from 'pages/crm/contracts/edit-contract';
// import ManageContacts from 'pages/crm/contacts/manage-contacts';
import ManageContracts from 'pages/crm/contracts/manage-contracts';
import ContractsPipeline from 'pages/crm/contracts/pipeline/contracts-pipeline';
import FinancialTools from 'pages/financial-tool/financial-tool';
import TasksPipeline from 'pages/internal-activity/tasks-pipeline/tasks-pipeline';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { projectInManagement as pmRoute } from 'routes';
import { errorHandling } from 'utils';
import API from 'utils/axios';

import ProjectPlanningOverview from './project-planning-overview';
import PMTaskManagementTimeline from './project-task-management';
import PMWikiTimeline from './project-wiki';
import Riem from './riem/riem';
import SinglePMConfiguratorRouter from './single-pm-configurator/single-pm-configurator';

const Projects = () => {
    const { t } = useTranslation();
    const { user } = useContext(UserContext);
    const enqueueSnackbar = useSnackbar();
    const { changeNavName } = useNavProps();
    const { projectId } = useParams();
    const rootUrl = pmRoute.base + '/:projectId';

    const [project, setProject] = useState(null);

    useEffect(() => {
        (async () => {
            try {
                const response = await API.get('/pm_projectPlanningOverview', {
                    params: {
                        id: projectId,
                    },
                });

                setProject(response.data.pm);
                changeNavName(response.data.pm.name);
            } catch (err) {
                enqueueSnackbar(errorHandling(err).length > 100 ? errorHandling(err) : t(errorHandling(err)), {
                    variant: 'error',
                });
            }
        })();
    }, []);

    return (
        !!project && (
            <React.Fragment>
                <Helmet>
                    <title>{project.name}</title>
                </Helmet>

                <Switch>
                    {/* Project Wiki Timeline */}
                    <Route path={rootUrl + pmRoute.wiki.base}>
                        <PMWikiTimeline />
                    </Route>

                    {/* Project Activity Timeline */}
                    <Route path={rootUrl + pmRoute.projectsTaskManagement.base}>
                        <PMTaskManagementTimeline projectName={project.name} />
                    </Route>

                    <Route path={rootUrl + pmRoute.projectsTasksPipeline.base}>
                        <TasksPipeline purpose="PROJECT" />
                    </Route>

                    {/* Planning overview */}
                    <Route path={rootUrl + pmRoute.planingOverviewOnProject.base}>
                        <ProjectPlanningOverview />
                    </Route>

                    {/* P&L and Cashflow for each project  */}
                    <Route path={rootUrl + pmRoute.projectPL.base} key={rootUrl + pmRoute.projectPL.base}>
                        <FinancialTools type="pl_project" />
                    </Route>
                    <Route path={rootUrl + pmRoute.projectCashflow.base} key={rootUrl + pmRoute.projectCashflow.base}>
                        <FinancialTools type="cashflow_project" />
                    </Route>

                    {/* Configurator */}
                    {user && !user.isInvestor && (
                        <Route path={rootUrl + pmRoute.configurator}>
                            <SinglePMConfiguratorRouter projectName={project.name} />
                        </Route>
                    )}

                    {/* Contacts on PM */}
                    {/* <Route path={rootUrl + pmRoute.projectClients.base}>
                        <ProjectClients projectId={projectId} />
                    </Route> */}

                    {/* Articles on PM */}
                    {/* <Route path={rootUrl + pmRoute.projectArticles.base}>
                        <ProjectArticles projectId={projectId} />
                    </Route> */}

                    {/* Contracts on PM */}
                    <Route path={rootUrl + pmRoute.projectContracts.base + pmRoute.projectContracts.create}>
                        <CreateContract pmProjectId={projectId} />
                    </Route>
                    <Route exact path={rootUrl + pmRoute.projectContracts.base + pmRoute.projectContracts.read}>
                        <ManageContracts pmProjectId={projectId} />
                    </Route>
                    <Route path={rootUrl + pmRoute.projectContracts.base + pmRoute.projectContracts.updateFull}>
                        <EditContract pmProjectId={projectId} />
                    </Route>
                    <Route path={rootUrl + pmRoute.projectContracts.base + pmRoute.projectContracts.pipeline}>
                        <ContractsPipeline pmProjectId={projectId} />
                    </Route>

                    <Route path={rootUrl + pmRoute.riem.base}>
                        <Riem project={project} />
                    </Route>

                    {/* Internal Resources CRUD */}
                    {/* <Route
                        path={
                            pmRoute.base +
                            '/:projectId' +
                            pmRoute.internalResources.base +
                            pmRoute.internalResources.create
                        }
                    >
                        <CreateInternalResource type="pm" />
                    </Route> */}

                    {/* <Route
                        path={
                            pmRoute.base +
                            '/:projectId' +
                            pmRoute.internalResources.base +
                            pmRoute.internalResources.updateFull
                        }
                    >
                        <EditInternalResource type="pm" />
                    </Route> */}

                    {/* External Resources CRUD */}
                    {/* <Route
                        exact
                        path={
                            pmRoute.base +
                            '/:projectId' +
                            pmRoute.externalResources.base +
                            pmRoute.externalResources.create
                        }
                    >
                        <CreateExternalResource type="pm" />
                    </Route> */}

                    {/* <Route
                        path={
                            pmRoute.base +
                            '/:projectId' +
                            pmRoute.externalResources.base +
                            pmRoute.externalResources.updateFull
                        }
                    >
                        <EditExternalResource type="pm" />
                    </Route> */}

                    <Route path={rootUrl + pmRoute.timeManagement.base}>
                        <div className="page-container relative">
                            <h3>Feature in development!</h3>
                        </div>
                    </Route>

                    <Route path={pmRoute.base + '/:projectId' + pmRoute.filesAndDocumentsManagement.base}>
                        <div className="page-container relative">
                            <h3>Feature in development!</h3>
                        </div>
                    </Route>

                    <Route path={rootUrl + pmRoute.projectBudgets.base}>
                        <div className="page-container relative">
                            <h3>Feature in development!</h3>
                        </div>
                    </Route>

                    <Route path={rootUrl + pmRoute.riskManagement.base}>
                        <div className="page-container relative">
                            <h3>Feature in development!</h3>
                        </div>
                    </Route>

                    <Route path={rootUrl + pmRoute.projectManagementReports.base}>
                        <div className="page-container relative">
                            <h3>Feature in development!</h3>
                        </div>
                    </Route>

                    <Route path={rootUrl + pmRoute.projectSettings.base}>
                        <div className="page-container relative">
                            <h3>Feature in development!</h3>
                        </div>
                    </Route>

                    {/* <Route path={rootUrl + '/*'}>
                        <Redirect to={rootUrl} />
                    </Route> */}
                </Switch>

                <Footer />
            </React.Fragment>
        )
    );
};

export default Projects;
