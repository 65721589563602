import React from 'react';

import Pagination from 'components/shared/pagination';
import PropTypes from 'prop-types';

const DisplayPaginatedResults = ({
    children,
    query,
    loading,
    setLoading,
    getFunction,
    getSearchFunction,
    refreshList,
}) => {
    const isSearch = query.trim().length > 0;

    return (
        <Pagination
            query={query}
            loading={loading}
            setLoading={setLoading}
            getFunction={isSearch ? getSearchFunction : getFunction}
            refreshList={refreshList}
        >
            {children}
        </Pagination>
    );
};

DisplayPaginatedResults.propTypes = {
    children: PropTypes.element,
    query: PropTypes.string,
    getFunction: PropTypes.func,
    getSearchFunction: PropTypes.func,
    setLoading: PropTypes.func,
    loading: PropTypes.bool,
    refreshList: PropTypes.array,
};

DisplayPaginatedResults.defaultProps = {
    children: null,
    query: '',
    getFunction: () => {},
    getSearchFunction: () => {},
    setLoading: () => {},
    loading: false,
    refreshList: [],
};

export default DisplayPaginatedResults;
