import React, { Fragment, useContext, useRef, useState } from 'react';

import AddIcon from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteIcon from '@material-ui/icons/Delete';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import EditIcon from '@material-ui/icons/Edit';

import NoImage from '../../../../assets/common/no-image.png';

import { Button, makeStyles } from '@material-ui/core';

import Gallery from 'components/crm/estate/gallery';
import GalleryEditable from 'components/crm/estate/gallery-editable';
import CustomModal from 'components/modals/custom_modal';
import BasicTooltip from 'components/shared/basic-tooltip';
import ErrorButton from 'components/shared/error-button';
import FileUploadContainer from 'components/shared/file-upload-container';
import DOMPurify from 'dompurify';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { ActionButton, Toggle } from 'RaisisComponents';
import { Draggable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

import { ConfiguratorContext } from '../add-document-template';
import EditElementModal from './edit-element-modal';

const useStyles = makeStyles(() => {
    return {
        error: {
            backgroundColor: `var(--error)`,
            color: `var(--buttons-text)`,
            '&:hover': {
                backgroundColor: `var(--error-light)`,
            },
        },
    };
});

const DocumentItem = ({ itemData, index, isDragDisabled }) => {
    const { enqueueSnackbar } = useSnackbar();

    const {
        contextData,
        isMultiView,
        highlightPair,
        setHighlightPair,
        highlightReference,
        setHighlightReference,
        handleRemoveItem,
        handleRemovePair,
        handleChangeItem,
    } = useContext(ConfiguratorContext);

    const { t } = useTranslation();
    const classes = useStyles();

    const purposeMapper = {
        default: '',
        milestone: ' - Milestone',
        milestones: ` - ${t('Milestones')}`,
        offer: ` - ${t('Financial')}`,
    };

    const titleMapper = (type, purpose) =>
        ({
            title: (
                <h4 className="text-center">
                    {t('Title')}
                    {purposeMapper[purpose]}
                </h4>
            ),
            subtitle: (
                <h4 className="text-center">
                    {t('Subtitle')}
                    {purposeMapper[purpose]}
                </h4>
            ),
            image: (
                <h4 className="text-center">
                    {t('Image')}
                    {purposeMapper[purpose]}
                </h4>
            ),
            gallery: (
                <h4 className="text-center">
                    {t('Gallery')}
                    {purposeMapper[purpose]}
                </h4>
            ),
            content: (
                <h4 className="text-center">
                    {t('Content')}
                    {purposeMapper[purpose]}
                </h4>
            ),
            table: (
                <h4 className="text-center">
                    {t('Table')}
                    {purposeMapper[purpose]}
                </h4>
            ),
            milestone: (
                <h4 className="text-center">
                    {t('Milestone template')}
                    {purposeMapper[purpose]}
                </h4>
            ),
            milestones: (
                <h4 className="text-center">
                    {t('Milestones template')}
                    {purposeMapper[purpose]}
                </h4>
            ),
            offer: (
                <h4 className="text-center">
                    {t('Financial details')}
                    {purposeMapper[purpose]}
                </h4>
            ),
            signature: (
                <h4 className="text-center">
                    {t('Signature')}
                    {purposeMapper[purpose]}
                </h4>
            ),
        })[type];

    const [open, setOpen] = useState(false);
    const [galleryOpen, setGalleryOpen] = useState(false);
    const [galleryPhotoIndex, setGalleryPhotoIndex] = useState(0);

    //? We set the actual element to the element in the main column only when the element is not multi language
    const actualElement =
        itemData.referenceId && !itemData.isMultiLanguage
            ? Object.values(contextData.elements).find((el) => el.id === itemData.referenceId)
            : itemData;
    const isMainColumn = itemData.id.includes('column-main');
    const isImage = itemData.itemType === 'image';
    const isGallery = itemData.itemType === 'gallery';
    const isTable = itemData.itemType === 'table';
    const isText = !isImage && !isGallery && !isTable;
    const isDefaultPurpose = itemData.purpose === 'default';
    const isMilestonePurpose = itemData.purpose === 'milestone';
    const isMilestonesPurpose = itemData.purpose === 'milestones';

    //? We use this reference in order to keep track of what row or column is being edited in a table
    const tableIndexInfo = useRef(null);

    const handleOpenModal = () => setOpen(true);
    const handleCloseModal = () => setOpen(false);

    const handleImageUpload = (e) => {
        const rawImage = e.target.files[0];
        const fileReader = new FileReader();
        fileReader.readAsDataURL(rawImage);
        fileReader.onload = async (event) => {
            try {
                handleChangeItem(actualElement.id, 'value', { url: event.target.result, blob: rawImage });
            } catch (err) {
                console.error(err);
                enqueueSnackbar(t('Could not upload file! Try again!'), { variant: 'error' });
            }
        };
    };
    const handleDeleteImage = () => handleChangeItem(actualElement.id, 'value', { url: null, blob: null });

    const handleGalleryUpload = (e) => handleChangeItem(actualElement.id, 'value', e);

    const mappedEditTextValue = {
        default: actualElement.value,
        table: tableIndexInfo.current?.value,
    };

    const editTextValue =
        mappedEditTextValue[
            (() => {
                if (isText) return 'default';
                if (isTable) return 'table';
            })()
        ];

    /* The below code defines an object `mappedEditTextFunctions` that contains different functions for
    handling text editing based on different scenarios. The `default` function is used for general
    text editing and updates the value of an element and closes a modal. The `table` function is
    specifically used for editing text within a table structure. It updates the value of a table's
    columns or rows based on the type of table info provided and closes a modal afterwards. The
    `tableIndexInfo.current` reference is used to keep track of the table information being edited. */
    const mappedEditTextFunctions = {
        default: (value) => {
            handleChangeItem(actualElement.id, 'value', value);
            setOpen(false);
        },
        table: (value) => {
            const tableInfo = tableIndexInfo.current;

            if (tableInfo.type === 'column') {
                const newColumns = structuredClone(actualElement.value.columns);
                newColumns[tableInfo.index] = value;

                const newValue = { ...actualElement.value, columns: newColumns };
                handleChangeItem(actualElement.id, 'value', newValue);
            }

            if (tableInfo.type === 'row') {
                const newRows = structuredClone(actualElement.value.rows);
                newRows[tableInfo.index][tableInfo.vIndex] = value;

                const newValue = { ...actualElement.value, rows: newRows };
                handleChangeItem(actualElement.id, 'value', newValue);
            }

            tableIndexInfo.current = null;
            setOpen(false);
        },
    };

    const handleEditText =
        mappedEditTextFunctions[
            (() => {
                if (isText) return 'default';
                if (isTable) return 'table';
            })()
        ];

    const handleAddNewColumn = () => {
        const newColumns = structuredClone(actualElement.value.columns);
        newColumns.push(`<p>${t('New header')}</p>`);

        const newRows = structuredClone(actualElement.value.rows);
        for (let i = 0; i < newRows.length; i++) {
            for (let j = 0; j < newColumns.length; j++) {
                if (j < newColumns.length - 1) continue;
                newRows[i].push(`<p>${t('New value')}</p>`);
            }
        }

        const newValue = { ...actualElement.value, rows: newRows, columns: newColumns };
        handleChangeItem(actualElement.id, 'value', newValue);
    };

    const handleAddNewRow = () => {
        const newRows = structuredClone(actualElement.value.rows);
        newRows.push(Array.from({ length: actualElement.value.columns.length }, () => `<p>${t('New value')}</p>`));

        const newValue = { ...actualElement.value, rows: newRows };
        handleChangeItem(actualElement.id, 'value', newValue);
    };

    const handleDeleteColumn = (index) => {
        const newColumns = structuredClone(actualElement.value.columns);
        newColumns.splice(index, 1);

        const newRows = structuredClone(actualElement.value.rows);
        for (let i = 0; i < newRows.length; i++) {
            newRows[i].splice(index, 1);
        }

        const newValue = { ...actualElement.value, rows: newRows, columns: newColumns };
        handleChangeItem(actualElement.id, 'value', newValue);
    };

    const handleDeleteRow = (index) => {
        const newRows = structuredClone(actualElement.value.rows);
        newRows.splice(index, 1);

        const newValue = { ...actualElement.value, rows: newRows };
        handleChangeItem(actualElement.id, 'value', newValue);
    };

    return (
        <>
            <Draggable draggableId={itemData.id} index={index} isDragDisabled={isDragDisabled}>
                {(provided, snapshot) => {
                    if (!itemData.isUsed)
                        return (
                            <div
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                ref={provided.innerRef}
                                className={`focus:outline-white mb-4 rounded-md px-8 py-4 ${
                                    snapshot.isDragging ? 'bg-layout-main' : 'bg-layout-lighter'
                                }`}
                            >
                                {titleMapper(itemData.itemType, itemData.purpose)}
                            </div>
                        );

                    return (
                        <div
                            {...provided.draggableProps}
                            ref={provided.innerRef}
                            className={`relative rounded-md px-4 py-2 sm:px-2 ${
                                snapshot.isDragging ||
                                highlightPair === itemData.pairKey ||
                                highlightReference.referenceId === itemData.id
                                    ? 'bg-layout-main'
                                    : 'bg-layout-lighter'
                            }`}
                        >
                            <div
                                {...provided.dragHandleProps}
                                className={`focus:outline-black group absolute left-0 top-1/2 flex h-12 w-max -translate-x-full -translate-y-1/2 transform flex-col items-center justify-center ${
                                    !isMainColumn ? 'hidden' : ''
                                }`}
                            >
                                <DragIndicatorIcon
                                    style={{ height: '2.5rem', width: 'max-content' }}
                                    className="mb-1 rounded-full text-layout-lighter transition-colors last:mb-0 group-hover:text-layout-light"
                                />
                            </div>
                            <div className="flex flex-col gap-4">
                                <div className="flex items-center justify-between gap-4">
                                    {titleMapper(itemData.itemType, itemData.purpose)}
                                    <div className="flex items-center gap-1 sm:flex-col">
                                        {isMultiView && itemData.referenceId && (
                                            <p
                                                onMouseEnter={() =>
                                                    setHighlightReference({
                                                        id: itemData.id,
                                                        referenceId: itemData.referenceId,
                                                    })
                                                }
                                                onMouseLeave={() => setHighlightReference({ id: '', referenceId: '' })}
                                                className={`cursor-view whitespace-nowrap rounded-full p-1 text-center ${
                                                    itemData.referenceId === highlightReference.referenceId &&
                                                    itemData.id === highlightReference.id
                                                        ? 'bg-warning-light text-dark-text'
                                                        : 'bg-warning text-main-text'
                                                } text-sm`}
                                            >
                                                {t('See reference')}
                                            </p>
                                        )}
                                        {itemData.pairKey && (
                                            <p
                                                onMouseEnter={() => setHighlightPair(itemData.pairKey)}
                                                onMouseLeave={() => setHighlightPair('')}
                                                className={`cursor-view whitespace-nowrap rounded-full p-1 text-center ${
                                                    itemData.pairKey === highlightPair
                                                        ? 'bg-success-light text-dark-text'
                                                        : 'bg-success text-main-text'
                                                } text-sm`}
                                            >
                                                {t('See pair')}
                                            </p>
                                        )}
                                    </div>

                                    <BasicTooltip
                                        disabled={isMainColumn}
                                        tip={t('You can delete an element only in the main language column')}
                                    >
                                        <Button
                                            onClick={() =>
                                                itemData.pairKey
                                                    ? handleRemovePair(itemData.pairKey)
                                                    : handleRemoveItem(itemData.id)
                                            }
                                            onMouseEnter={
                                                itemData.pairKey ? () => setHighlightPair(itemData.pairKey) : undefined
                                            }
                                            onMouseLeave={itemData.pairKey ? () => setHighlightPair('') : undefined}
                                            className={`${classes.error} flex-shrink-0`}
                                            size="small"
                                            endIcon={<CancelIcon />}
                                            disabled={!isMainColumn}
                                        >
                                            {t(itemData.pairKey ? 'Delete pair' : 'Delete element')}
                                        </Button>
                                    </BasicTooltip>
                                </div>
                                <div className="flex flex-col justify-between gap-2">
                                    <div className="flex overflow-auto">
                                        {isText && isDefaultPurpose && (
                                            <div className="flex-grow">
                                                <iframe
                                                    className="h-40 w-full rounded-md border border-layout-lighter bg-white p-2"
                                                    srcDoc={`<base target="_blank" /> ${DOMPurify.sanitize(
                                                        actualElement.value,
                                                    )}`}
                                                />
                                            </div>
                                        )}
                                        {isImage && isDefaultPurpose && (
                                            <div className="flex h-64 w-full items-center justify-center">
                                                <img
                                                    className="h-full w-full object-contain"
                                                    src={actualElement.value.url ?? NoImage}
                                                    alt={`Image ${actualElement.id}`}
                                                />
                                            </div>
                                        )}
                                        {isTable && isDefaultPurpose && (
                                            <div className="pl-10 pt-10">
                                                <table
                                                    className="border-collapse rounded-sm border-none"
                                                    style={{ minWidth: '100px' }}
                                                >
                                                    <thead>
                                                        <tr className="bg-layout-transparent-dark">
                                                            {itemData.value.columns.map((header, index) => {
                                                                return (
                                                                    <th
                                                                        className="relative border border-layout-main p-1"
                                                                        key={index}
                                                                    >
                                                                        <div className="flex items-center justify-between gap-1">
                                                                            <span
                                                                                dangerouslySetInnerHTML={{
                                                                                    __html: DOMPurify.sanitize(header),
                                                                                }}
                                                                            />
                                                                            <BasicTooltip
                                                                                disabled={
                                                                                    isMainColumn ||
                                                                                    itemData.isMultiLanguage
                                                                                }
                                                                                tip={t(
                                                                                    'You can edit this element only in the main language column because is not a multi language element',
                                                                                )}
                                                                            >
                                                                                <span>
                                                                                    <ActionButton
                                                                                        disabled={
                                                                                            !isMainColumn &&
                                                                                            !itemData.isMultiLanguage
                                                                                        }
                                                                                        onClick={() => {
                                                                                            tableIndexInfo.current = {
                                                                                                type: 'column',
                                                                                                index,
                                                                                                value: header,
                                                                                            };
                                                                                            setOpen(true);
                                                                                        }}
                                                                                        icon={<EditIcon />}
                                                                                    />
                                                                                </span>
                                                                            </BasicTooltip>
                                                                        </div>
                                                                        <div className="absolute -top-9 left-1/2 -translate-x-1/2 transform">
                                                                            <BasicTooltip
                                                                                tip={
                                                                                    !isMainColumn &&
                                                                                    !itemData.isMultiLanguage
                                                                                        ? t(
                                                                                              'You can edit this element only in the main language column because is not a multi language element',
                                                                                          )
                                                                                        : t('Delete column')
                                                                                }
                                                                                position="top"
                                                                            >
                                                                                <ActionButton
                                                                                    color={
                                                                                        actualElement.value.columns
                                                                                            .length === 1
                                                                                            ? 'var(--disabled)'
                                                                                            : 'var(--error)'
                                                                                    }
                                                                                    onClick={() =>
                                                                                        handleDeleteColumn(index)
                                                                                    }
                                                                                    icon={<DeleteIcon />}
                                                                                    disabled={
                                                                                        (!isMainColumn &&
                                                                                            !itemData.isMultiLanguage) ||
                                                                                        actualElement.value.columns
                                                                                            .length === 1
                                                                                    }
                                                                                />
                                                                            </BasicTooltip>
                                                                        </div>
                                                                    </th>
                                                                );
                                                            })}

                                                            <th className="bg-layout-lighter px-2 py-1">
                                                                <BasicTooltip
                                                                    tip={
                                                                        !isMainColumn && !itemData.isMultiLanguage
                                                                            ? t(
                                                                                  'You can edit this element only in the main language column because is not a multi language element',
                                                                              )
                                                                            : t('Add new column')
                                                                    }
                                                                    position="top"
                                                                >
                                                                    <ActionButton
                                                                        disabled={
                                                                            !isMainColumn && !itemData.isMultiLanguage
                                                                        }
                                                                        color="var(--success)"
                                                                        onClick={handleAddNewColumn}
                                                                        icon={<AddIcon />}
                                                                    />
                                                                </BasicTooltip>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {itemData.value.rows.map((row, index) => {
                                                            return (
                                                                <tr key={index} className="bg-layout-transparent">
                                                                    {row.map((value, vIndex) => {
                                                                        return (
                                                                            <td
                                                                                className="relative border border-layout-main p-1"
                                                                                key={vIndex}
                                                                            >
                                                                                <div className="flex items-center justify-between gap-1">
                                                                                    <span
                                                                                        dangerouslySetInnerHTML={{
                                                                                            __html: DOMPurify.sanitize(
                                                                                                value,
                                                                                            ),
                                                                                        }}
                                                                                    />

                                                                                    <BasicTooltip
                                                                                        disabled={
                                                                                            isMainColumn ||
                                                                                            itemData.isMultiLanguage
                                                                                        }
                                                                                        tip={t(
                                                                                            'You can edit this element only in the main language column because is not a multi language element',
                                                                                        )}
                                                                                    >
                                                                                        <span>
                                                                                            <ActionButton
                                                                                                disabled={
                                                                                                    !isMainColumn &&
                                                                                                    !itemData.isMultiLanguage
                                                                                                }
                                                                                                onClick={() => {
                                                                                                    tableIndexInfo.current =
                                                                                                        {
                                                                                                            type: 'row',
                                                                                                            index,
                                                                                                            vIndex,
                                                                                                            value,
                                                                                                        };
                                                                                                    setOpen(true);
                                                                                                }}
                                                                                                icon={<EditIcon />}
                                                                                            />
                                                                                        </span>
                                                                                    </BasicTooltip>
                                                                                </div>
                                                                                {vIndex === 0 && (
                                                                                    <div className="absolute -left-9 top-1/2 -translate-y-1/2 transform">
                                                                                        <BasicTooltip
                                                                                            tip={
                                                                                                !isMainColumn &&
                                                                                                !itemData.isMultiLanguage
                                                                                                    ? t(
                                                                                                          'You can edit this element only in the main language column because is not a multi language element',
                                                                                                      )
                                                                                                    : t('Delete row')
                                                                                            }
                                                                                            position="top"
                                                                                        >
                                                                                            <ActionButton
                                                                                                color={
                                                                                                    actualElement.value
                                                                                                        .rows.length ===
                                                                                                    1
                                                                                                        ? 'var(--disabled)'
                                                                                                        : 'var(--error)'
                                                                                                }
                                                                                                onClick={() =>
                                                                                                    handleDeleteRow(
                                                                                                        index,
                                                                                                    )
                                                                                                }
                                                                                                icon={<DeleteIcon />}
                                                                                                disabled={
                                                                                                    (!isMainColumn &&
                                                                                                        !itemData.isMultiLanguage) ||
                                                                                                    actualElement.value
                                                                                                        .rows.length ===
                                                                                                        1
                                                                                                }
                                                                                            />
                                                                                        </BasicTooltip>
                                                                                    </div>
                                                                                )}
                                                                            </td>
                                                                        );
                                                                    })}
                                                                </tr>
                                                            );
                                                        })}

                                                        <tr>
                                                            <td className="px-1 py-2">
                                                                <div className="inline-block">
                                                                    <BasicTooltip
                                                                        tip={
                                                                            !isMainColumn && !itemData.isMultiLanguage
                                                                                ? t(
                                                                                      'You can edit this element only in the main language column because is not a multi language element',
                                                                                  )
                                                                                : t('Add new row')
                                                                        }
                                                                        position="top"
                                                                    >
                                                                        <ActionButton
                                                                            disabled={
                                                                                !isMainColumn &&
                                                                                !itemData.isMultiLanguage
                                                                            }
                                                                            color="var(--success)"
                                                                            onClick={handleAddNewRow}
                                                                            icon={<AddIcon />}
                                                                        />
                                                                    </BasicTooltip>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        )}
                                        {isGallery && isDefaultPurpose && (
                                            <div className="h-64 w-full">
                                                <BasicTooltip
                                                    disabled={isMainColumn || itemData.isMultiLanguage}
                                                    tip={t(
                                                        'You can edit this element only in the main language column because is not a multi language element',
                                                    )}
                                                >
                                                    <GalleryEditable
                                                        type="Multi-select"
                                                        view={!isMainColumn && !itemData.isMultiLanguage}
                                                        gallery={actualElement.value}
                                                        setGallery={handleGalleryUpload}
                                                        setOpen={setGalleryOpen}
                                                        photoClickCallback={(photoIndex) => {
                                                            setGalleryPhotoIndex(photoIndex);
                                                        }}
                                                    />
                                                    <Gallery
                                                        open={galleryOpen}
                                                        setOpen={setGalleryOpen}
                                                        initialIndex={galleryPhotoIndex}
                                                        urlList={actualElement.value.map((item) => item.url)}
                                                    />
                                                </BasicTooltip>
                                            </div>
                                        )}
                                    </div>

                                    <div
                                        className="mx-auto grid w-full grid-cols-4 place-content-center place-items-center gap-2 rounded-md bg-layout-light p-2 sm:grid-cols-1"
                                        style={{ gridTemplateColumns: 'repeat(auto-fit, minmax(18rem, max-content))' }}
                                    >
                                        <BasicTooltip
                                            disabled={
                                                isMainColumn &&
                                                (!isMilestonePurpose || (isMilestonePurpose && !isGallery)) &&
                                                (!isMilestonesPurpose || (isMilestonesPurpose && !isGallery))
                                            }
                                            tip={
                                                (isMilestonePurpose && isGallery) || (isMilestonesPurpose && isGallery)
                                                    ? t(
                                                          "The milestone gallery can't be multi language because the gantt can't be displayed in two or more columns. It will displayed only in language of the platform at the time of creation",
                                                      )
                                                    : t(
                                                          'You can change this property of the element only in the main language column',
                                                      )
                                            }
                                        >
                                            <div className="flex items-center gap-2 rounded-md bg-layout-transparent p-2">
                                                <Toggle
                                                    disabled={
                                                        (isMilestonePurpose && isGallery) ||
                                                        (isMilestonesPurpose && isGallery) ||
                                                        !isMainColumn
                                                    }
                                                    checked={itemData.isMultiLanguage}
                                                    setChecked={(value) =>
                                                        handleChangeItem(itemData.id, 'isMultiLanguage', value)
                                                    }
                                                />
                                                {itemData.isMultiLanguage ? (
                                                    <p>{t('Deactivate multi language')}</p>
                                                ) : (
                                                    <p>{t('Activate multi language')}</p>
                                                )}
                                            </div>
                                        </BasicTooltip>

                                        <BasicTooltip
                                            disabled={isMainColumn}
                                            tip={t(
                                                'You can change this property of the element only in the main language column',
                                            )}
                                        >
                                            <div className="flex items-center gap-2 rounded-md bg-layout-transparent p-2">
                                                <Toggle
                                                    disabled={!isMainColumn}
                                                    checked={itemData.flagManualEdit}
                                                    setChecked={(value) =>
                                                        handleChangeItem(itemData.id, 'flagManualEdit', value)
                                                    }
                                                />
                                                {itemData.flagManualEdit ? (
                                                    <p>{t('Deactivate manual editing')}</p>
                                                ) : (
                                                    <p>{t('Activate manual editing')}</p>
                                                )}
                                            </div>
                                        </BasicTooltip>

                                        <BasicTooltip
                                            disabled={isMainColumn}
                                            tip={t(
                                                'You can change this property of the element only in the main language column',
                                            )}
                                        >
                                            <div className="flex items-center gap-2 rounded-md bg-layout-transparent p-2">
                                                <Toggle
                                                    disabled={!isMainColumn}
                                                    checked={!itemData.flagDisabled}
                                                    setChecked={(value) =>
                                                        handleChangeItem(itemData.id, 'flagDisabled', !value)
                                                    }
                                                />
                                                {!itemData.flagDisabled ? (
                                                    <p>{t('Deactivate element')}</p>
                                                ) : (
                                                    <p>{t('Activate element')}</p>
                                                )}
                                            </div>
                                        </BasicTooltip>

                                        {isText && isDefaultPurpose && (
                                            <div className="place-self-center">
                                                <BasicTooltip
                                                    disabled={isMainColumn || itemData.isMultiLanguage}
                                                    tip={t(
                                                        'You can edit this element only in the main language column because is not a multi language element',
                                                    )}
                                                >
                                                    <Button
                                                        disabled={!isMainColumn && !itemData.isMultiLanguage}
                                                        onClick={handleOpenModal}
                                                        color="primary"
                                                    >
                                                        {t('Edit element')}
                                                    </Button>
                                                </BasicTooltip>
                                            </div>
                                        )}
                                        {isImage && isDefaultPurpose && (
                                            <>
                                                <div className="place-self-center">
                                                    <BasicTooltip
                                                        disabled={isMainColumn || itemData.isMultiLanguage}
                                                        tip={t(
                                                            'You can edit this element only in the main language column because is not a multi language element',
                                                        )}
                                                    >
                                                        <FileUploadContainer
                                                            disabled={!isMainColumn && !itemData.isMultiLanguage}
                                                            onUpload={handleImageUpload}
                                                            multiple={false}
                                                            accept="image"
                                                        >
                                                            <Button
                                                                disabled={!isMainColumn && !itemData.isMultiLanguage}
                                                                color="primary"
                                                            >
                                                                {t(
                                                                    actualElement.value.url
                                                                        ? 'Edit image'
                                                                        : 'Add image',
                                                                )}
                                                            </Button>
                                                        </FileUploadContainer>
                                                    </BasicTooltip>
                                                </div>
                                                {actualElement.value.url && (
                                                    <div className="place-self-center">
                                                        <ErrorButton
                                                            disabled={!isMainColumn && !itemData.isMultiLanguage}
                                                            onClick={handleDeleteImage}
                                                        >
                                                            {t('Delete image')}
                                                        </ErrorButton>
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                }}
            </Draggable>

            {(isText || isTable) && (
                <CustomModal open={open}>
                    <EditElementModal onClose={handleCloseModal} value={editTextValue} onFinish={handleEditText} />
                </CustomModal>
            )}
        </>
    );
};

DocumentItem.propTypes = {
    itemData: PropTypes.object,
    index: PropTypes.number,
    isDragDisabled: PropTypes.bool,
};

DocumentItem.defaultProps = {
    itemData: null,
    index: 0,
    isDragDisabled: false,
};

export default DocumentItem;
