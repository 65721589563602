import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';

import { Button, CircularProgress, FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';

import CommentActivity from 'components/internal-activity/comment-activity';
import DocumentActivity from 'components/internal-activity/document-activity';
import FileActivity from 'components/internal-activity/file-activity';
import TaskActivity from 'components/internal-activity/task-activity';
import GroupsSelector from 'components/shared/groups-selector/groups-selector';
import ActivityContext from 'contexts/ActivityContext';
import useScreenSizes from 'hooks/useScreenSizes.js';
import PropTypes from 'prop-types';
import { Tabs } from 'RaisisComponents/index.js';
import { useTranslation } from 'react-i18next';
import { configurator } from 'routes';
import { getDepartments, getPartners, getTenantUsers } from 'utils/getterFunctions';

import OpportunityActivity from './opportunity-activity';

export const getActivityMappedIndex = (activity) => {
    const { actionType, activitiesTasks, pipelineType } = activity;

    if (actionType === 'COMMENT') return 0;
    if (
        actionType === 'TASK' &&
        (!activitiesTasks.length || (activitiesTasks.length && pipelineType !== 'LEADS_TO_POTENTIAL'))
    )
        return 1;
    if (actionType === 'TASK' && activitiesTasks.length && pipelineType === 'LEADS_TO_POTENTIAL') return 2;
    if (actionType === 'FILES') return 3;
    if (actionType === 'DOCUMENTS') return 4;
};

const ActivityModal = (props) => {
    const { close, activity, contactId, selectedDepartment, editDocuments } = props;

    const contentRef = useRef(null);

    const [tenantUsers, setTenantUsers] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [groups, setGroups] = useState([]);
    const [activityType, setActivityType] = useState(0);
    // All partners for tag component
    const [allPartners, setAllPartners] = useState([]);
    // Selected tags for partners
    const [selectedPartner, setSelectedPartner] = useState(null);
    const [activityDepartment, setActivityDepartment] = useState(null);
    const [loading, setLoading] = useState(false);
    const [width, height] = useScreenSizes();

    const history = useHistory();
    const { t } = useTranslation();

    const activityContext = useContext(ActivityContext);

    const handleDepartmentChange = (e) => {
        setActivityDepartment(e.target.value);
    };

    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                const tens = await getTenantUsers();
                setTenantUsers(tens);

                const deps = await getDepartments();
                setDepartments(deps);
                if (deps.length && activityContext.activityForEdit === null) setActivityDepartment(deps[0].id);

                const parts = await getPartners();
                setAllPartners(parts);
            } catch (err) {
                console.error(err);
            } finally {
                setTimeout(() => {
                    setLoading(false);
                }, 250);
            }
        })();
    }, []);

    // changing the department of the add activity form so it matches the one from the tabs
    useEffect(() => {
        if (selectedDepartment > 0 && departments.length > 0) {
            setActivityDepartment(departments[selectedDepartment - 1].id);
        }
    }, [selectedDepartment, departments]);
    // comnmnebfhf shdcsd

    useEffect(() => {
        if (activityContext.activityForEdit !== null) {
            setActivityDepartment(activityContext.activityForEdit.departmentId);
            setActivityType(getActivityMappedIndex(activityContext.activityForEdit));
            setGroups(activityContext.activityForEdit.groups.map((group) => group.id));
        }
    }, [activityContext]);

    useEffect(() => {
        let observer = null;

        if (contentRef.current) {
            observer = new ResizeObserver((entries) => {
                if (contentRef.current) {
                    for (const entry of entries) {
                        if (entry.target.getBoundingClientRect().height >= height * 0.6) {
                            contentRef.current.style.overflowY = 'auto';
                            contentRef.current.style.overflowX = 'hidden';
                        } else {
                            contentRef.current.style.overflow = 'visible';
                        }
                    }
                }
            });

            observer.observe(contentRef.current);
        }

        return () => {
            if (observer) {
                observer.disconnect();
            }
        };
    }, [loading, height]);

    return (
        <div className="relative">
            {loading ? (
                <div className="py-20 text-center">
                    <CircularProgress />
                </div>
            ) : (
                <>
                    {departments.length > 0 ? (
                        <div
                            style={{
                                width: width > 1000 ? '500px' : '90vw',
                                maxWidth: '500px',
                            }}
                        >
                            {/* Modal Header */}
                            <div className="mb-5 flex items-center justify-between px-10 pt-10 sm:px-4 sm:pt-4">
                                <h2 className="font-semibold">
                                    {activityContext.activityForEdit ? t('Modify Activity') : t('Add Activity')}
                                    {contactId ? ' contact' : ''}
                                </h2>
                                <CloseIcon
                                    className="ml-10 cursor-pointer text-primary-main opacity-75 hover:opacity-100"
                                    style={{ fontSize: '2.5rem', transition: 'opacity .2s ease' }}
                                    onClick={() => {
                                        activityContext.setActivityForEdit(null);
                                        close();
                                    }}
                                />
                            </div>

                            <div className={`p-10 sm:p-4`} style={{ maxHeight: '70vh' }} ref={contentRef}>
                                <div className="mb-5">
                                    <FormControl component="fieldset">
                                        <RadioGroup
                                            aria-label="status"
                                            name="status-filter"
                                            value={activityDepartment}
                                            onChange={handleDepartmentChange}
                                        >
                                            <div className="no-user-select-recursive flex items-center text-main-text">
                                                <div className="flex flex-wrap">
                                                    {departments.map((d) => (
                                                        <FormControlLabel
                                                            key={d.id}
                                                            value={d.id}
                                                            control={<Radio />}
                                                            label={d.name}
                                                        />
                                                    ))}
                                                </div>
                                            </div>
                                        </RadioGroup>
                                    </FormControl>
                                </div>

                                {!activity && (
                                    <div className="mb-5">
                                        <Tabs
                                            tabs={
                                                editDocuments
                                                    ? [
                                                          t('Comments'),
                                                          t('Tasks'),
                                                          t('Opportunities'),
                                                          t('Files'),
                                                          t('Documents'),
                                                      ]
                                                    : [t('Comments'), t('Tasks'), t('Opportunities')]
                                            }
                                            activeTab={activityType}
                                            setActiveTab={(e) => {
                                                setActivityType(e);
                                                activityContext.setActivityForEdit(null);
                                            }}
                                            small
                                            disabled={activityContext.activityForEdit}
                                        />
                                    </div>
                                )}

                                <div className="mb-5">
                                    <GroupsSelector groups={groups} setGroups={setGroups} />
                                </div>

                                {activityType === 0 && (
                                    <CommentActivity
                                        departmentId={activityDepartment}
                                        partnerId={selectedPartner ? allPartners[selectedPartner].id : null}
                                        tenantUsers={tenantUsers}
                                        contactId={contactId}
                                        activity={activity}
                                        groups={groups}
                                    />
                                )}

                                {activityType === 1 && (
                                    <TaskActivity
                                        departmentId={activityDepartment}
                                        partnerId={selectedPartner ? allPartners[selectedPartner].id : null}
                                        tenantUsers={tenantUsers}
                                        contactId={contactId}
                                        activity={activity}
                                        groups={groups}
                                    />
                                )}

                                {activityType === 2 && (
                                    <OpportunityActivity
                                        departmentId={activityDepartment}
                                        partnerId={selectedPartner ? allPartners[selectedPartner].id : null}
                                        tenantUsers={tenantUsers}
                                        contactId={contactId}
                                        activity={activity}
                                        groups={groups}
                                    />
                                )}

                                {activityType === 3 && (
                                    <FileActivity
                                        departmentId={activityDepartment}
                                        partnerId={selectedPartner ? allPartners[selectedPartner].id : null}
                                        tenantUsers={tenantUsers}
                                        contactId={contactId}
                                        activity={activity}
                                        editDocuments={editDocuments}
                                        groups={groups}
                                    />
                                )}

                                {activityType === 4 && (
                                    <DocumentActivity
                                        departmentId={activityDepartment}
                                        partnerId={selectedPartner ? allPartners[selectedPartner].id : null}
                                        tenantUsers={tenantUsers}
                                        contactId={contactId}
                                        activity={activity}
                                        editDocuments={editDocuments}
                                        groups={groups}
                                    />
                                )}
                            </div>
                        </div>
                    ) : (
                        <div className="px-4 py-20 text-center">
                            <p className="mb-8 text-base font-semibold opacity-75">
                                {t('To add an activity, you must add a department first!')}
                            </p>
                            <Button
                                startIcon={<AddIcon />}
                                color="primary"
                                onClick={() =>
                                    history.push(
                                        configurator.base +
                                            configurator.departments.base +
                                            configurator.departments.create,
                                    )
                                }
                            >
                                {t('Add department')}
                            </Button>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

ActivityModal.propTypes = {
    close: PropTypes.func,
    activity: PropTypes.object,
    contactId: PropTypes.string,
    selectedDepartment: PropTypes.number,
    activityForEdit: PropTypes.object,
    setActivityForEdit: PropTypes.func,
    editDocuments: PropTypes.bool,
};

ActivityModal.defaultProps = {
    close: () => null,
    activity: null,
    contactId: null,
    selectedDepartment: null,
    activityForEdit: null,
    setActivityForEdit: () => null,
    editDocuments: false,
};

export default ActivityModal;
