import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import Loading from 'components/shared/loading';
import OfferContext from 'contexts/OfferContext';
import UserContext from 'contexts/UserContext';
import { useSnackbar } from 'notistack';
import { Header, Stepper } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import API from 'utils/axios';
import { errorHandling } from 'utils/index';

import OfferArticles from './offer-articles';
import OfferDetails from './offer-details';
import OfferPDF from './offer-pdf';
import OfferTags from './offer-tags';

const defaultOfferContextValue = {
    step: 0,
    offerId: undefined,
    offer: null,
    pdfInfo: { items: [] },
};

const NewOffer = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const [offerContext, setOfferContext] = useState(defaultOfferContextValue);
    const editOfferContext = (key, value) => setOfferContext((prev) => ({ ...prev, [key]: value }));
    const resetOfferContext = () => setOfferContext(defaultOfferContextValue);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const offerId = queryParams.get('id');

    const [loading, setLoading] = useState(true);

    const { checkPerm } = useContext(UserContext);

    const canAll = checkPerm([
        {
            permissionId: '9',
            permissionType: 'ALL',
        },
    ]);

    const canView = checkPerm([
        {
            permissionId: '9',
            permissionType: 'VIEW',
        },
    ]);

    useEffect(() => {
        if (!canView) history.push('/');

        (async () => {
            try {
                if (offerId) {
                    const res = await API.get('offer', {
                        params: {
                            id: offerId,
                        },
                    });
                    const resOffer = res.data.offer;

                    switch (resOffer.processStatus) {
                        case 'ARTICLE':
                            editOfferContext('step', 1);
                            break;

                        case 'PDF':
                            editOfferContext('step', 2);
                            break;

                        case 'TAG':
                            editOfferContext('step', 3);
                            break;

                        default:
                            editOfferContext('step', 0);
                            break;
                    }

                    editOfferContext('offerId', offerId);
                    editOfferContext('offer', resOffer);
                }
            } catch (err) {
                console.error(err);
                enqueueSnackbar(errorHandling(err).length > 100 ? errorHandling(err) : t(errorHandling(err)), {
                    variant: 'error',
                });
            } finally {
                setLoading(false);
            }
        })();

        if (offerId) return () => resetOfferContext();
    }, [canView, offerId]);

    if (loading) return <Loading style={{ height: '100svh' }} />;

    return (
        <>
            <Helmet>
                <title>{offerContext.offer?.originalOfferId ? t('New revision') : t('New offer')}</title>
            </Helmet>

            <Header
                pageTitle={offerContext.offer?.originalOfferId ? t('New revision') : t('New offer')}
                toolbar={
                    canView && (
                        <Stepper
                            disabled
                            step={offerContext.step}
                            steps={
                                offerContext.offer?.originalOfferId
                                    ? [
                                          t('Update offer details'),
                                          t('Update offer articles'),
                                          t('Update offer PDF'),
                                          t('Update offer tags'),
                                      ]
                                    : [
                                          t('Create offer details'),
                                          t('Add articles to offer'),
                                          t('Create PDF for offer'),
                                          t('Add offer tags'),
                                      ]
                            }
                        />
                    )
                }
            />

            {!canAll && !offerContext.offerId ? (
                <div className="p-10 text-xl text-main-text">
                    {t('You not have the permission to add a new offer, you can only view offers from Offer History!')}
                </div>
            ) : (
                <div className="page-container">
                    <OfferContext.Provider
                        value={{
                            offerContext,
                            editOfferContext,
                            resetOfferContext,
                        }}
                    >
                        {offerContext.step === 0 && <OfferDetails key={`DETAILS_${offerContext.offerId}`} />}
                        {offerContext.step === 1 && <OfferArticles key={`ARTICLES_${offerContext.offerId}`} />}
                        {offerContext.step === 2 && <OfferPDF key={`PDF_${offerContext.offerId}`} />}
                        {offerContext.step === 3 && <OfferTags key={`TAGS_${offerContext.offerId}`} />}
                    </OfferContext.Provider>
                </div>
            )}
        </>
    );
};

export default NewOffer;
