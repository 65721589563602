import React, { useEffect, useState } from 'react';

import CreateIcon from '@material-ui/icons/Create';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Toggle } from '../Toggle/Toggle';

export const Permission = (props) => {
    const { t } = useTranslation();
    const [checked, setChecked] = useState(false);
    const { permissionGroup, userPermissions, setUserPermissions, children, isSingle, enabled } = props;

    useEffect(() => {
        let hasAny = false;
        permissionGroup.Permissions.every((p) => {
            if (userPermissions.findIndex((up) => up.permissionId === p.id) >= 0) {
                hasAny = true;
            }
            return !hasAny;
        });
        setChecked(hasAny);
    }, [permissionGroup, userPermissions]);

    const getTranslatedPermissionName = (permName) => {
        switch (permName) {
            case 'Activitate Interna':
                return t('Internal Activity');
            case 'Business Inteligence':
                return t('Business Inteligence');
            case 'CRM':
                return t('CRM');
            case 'Configurator ERP':
                return t('ERP Configurator');
            case 'Module':
                return t('Modules');
            case 'Contabilitate':
                return t('Accountancy');
        }
    };

    return (
        <div className="no-user-select-recursive overflow-hidden rounded-md border-2 border-layout-transparent hover:border-layout-transparent-dark">
            {/* eslint-disable-next-line */}
            <div className="pointer-events-none flex items-center bg-layout-transparent px-1.5 py-3 hover:bg-layout-transparent-dark">
                <Toggle checked={checked} />
                {/* <p className="text-lg font-bold ml-1.5 whitespace-nowrap">{permissionGroup.name}</p> */}
                <p className="ml-1.5 whitespace-nowrap text-lg font-bold">
                    {getTranslatedPermissionName(permissionGroup.name)}
                </p>
            </div>

            <div className={`${(permissionGroup.Permissions.length > 0 || isSingle) && 'p-1.5'}`}>
                {isSingle ? (
                    <div className="text-white">{children}</div>
                ) : (
                    <>
                        {permissionGroup.Permissions.map((permission) => (
                            <Row
                                key={permission.id}
                                enabled={enabled}
                                permission={permission}
                                userPermissions={userPermissions}
                                setUserPermissions={setUserPermissions}
                            />
                        ))}
                    </>
                )}
            </div>
        </div>
    );
};

const Row = (props) => {
    const { permission, userPermissions, setUserPermissions, enabled } = props;
    const [activePermission, setActivePermission] = useState(0);
    const { t } = useTranslation();

    const handlePermissionChange = (type) => {
        if (type === 'NONE') {
            setUserPermissions(userPermissions.filter((p) => p.permissionId !== permission.id));
            return;
        }

        const pIndex = userPermissions.findIndex((p) => p.permissionId === permission.id);

        if (pIndex >= 0) {
            const newUserPermissions = [...userPermissions];
            const newPermission = { ...userPermissions[pIndex] };
            newPermission.permissionType = type;
            newUserPermissions[pIndex] = newPermission;
            setUserPermissions(newUserPermissions);
        } else {
            setUserPermissions([
                ...userPermissions,
                {
                    permissionId: permission.id,
                    permissionType: type,
                },
            ]);
        }
    };

    useEffect(() => {
        const find = userPermissions.filter((p) => p.permissionId === permission.id);
        if (find.length > 0) {
            // eslint-disable-next-line
            find[0].permissionType === 'VIEW' ? setActivePermission(1) : setActivePermission(2);
        } else setActivePermission(0);
    }, [userPermissions, permission]);

    const getRowPermissionNameTranslated = (permName) => {
        switch (permName) {
            case 'Timeline Activity':
                return t('Timeline Activity');
            case 'Pipeline Activity':
                return t('Pipeline Activity');
            case 'Planning Overview':
                return t('Planning Overview');
            case 'Parteneri':
                return t('Partners');
            case 'Modul Contabilitate':
                return t('Accountancy Module');
            case 'Email Activitate Interna':
                return t('Internal Activity Email');
            case 'Manager Fisiere Activitate interna':
                return t('Files Manager Internal Activity');
            case 'Project Structure':
                return t('Project Structure');
            case 'Analitici':
                return t('Analitics');
            case 'Previziuni':
                return t('Forecast');
            case 'Obiective':
                return t('Objectives');
            case 'Indicatori de performanta':
                return t('Performance indicators');
            case 'Activitate Clienti':
                return t('Clients Activity');
            case 'Contacte':
                return t('Contacts');
            case 'SIP':
                return t('Catalogue');
            case 'Contracte':
                return t('Contracts');
            case 'Ofertare':
                return t('Offers');
            case 'Raportare':
                return t('Reports');
            case 'Articole':
                return t('Articles');
            case 'Email CRM':
                return t('CRM Email');
            case 'Manager fisiere CRM':
                return t('CRM Files Manager');
            case 'Utilizatori':
                return t('Users');
            case 'Zone':
                return t('Areas');
            case 'Configurator CRM':
                return t('CRM Configurator');
            case 'Departamente CRM':
                return t('CRM Departments');
            case 'Milestone':
                return t('Milestone');
            case 'Proiecte':
                return t('Projects');
            case 'Deltalii companie':
                return t('Company Details');
            case 'Tag Oferta':
                return t('Offer tags');
            case 'Template Pdf':
                return t('PDF Templates');
            case 'Modul Project Manager':
                return t('Project Management Module');
            case 'Modul Antrepriză Lucrări':
                return t('Works Undertaking Module');
            case 'Modul Vânzări':
                return t('Sales Module');
            case 'Modul Booking':
                return t('Booking Module');
            case 'Modul Management Facilitate':
                return t('Facility Management Module');
            case 'Nomenclator cheltuieli':
                return t('Expenses nomenclature');
            case 'Nomenclator venituri':
                return t('Revenues nomenclature');
            case 'Conturi contabile':
                return t('Accounting accounts');
            case 'Nomenclator tipuri de contract':
                return t('Contract type nomenclature');
            case 'Facturi':
                return t('Invoices');
            case 'P&L':
                return t('P&L');
            case 'Cashflow':
                return t('Cashflow');
        }
    };

    return (
        <div className="mb-1.5 flex items-center last:mb-0">
            <div className="mr-4 flex cursor-pointer items-center justify-start text-white">
                {/* eslint-disable-next-line */}
                <div
                    onClick={() => (enabled ? handlePermissionChange('NONE') : null)}
                    className={`rounded-bl-sm rounded-tl-sm p-1 duration-200 ${
                        activePermission === 0 ? 'bg-primary-main' : 'bg-layout-transparent'
                    }`}
                >
                    <VisibilityOffOutlinedIcon />
                </div>

                {/* eslint-disable-next-line */}
                <div
                    onClick={() => (enabled ? handlePermissionChange('VIEW') : null)}
                    className={`p-1 duration-200 ${
                        activePermission === 1 ? 'bg-primary-main' : 'bg-layout-transparent'
                    }`}
                >
                    <VisibilityOutlinedIcon />
                </div>

                {/* eslint-disable-next-line */}
                <div
                    onClick={() => (enabled ? handlePermissionChange('ALL') : null)}
                    className={`rounded-br-sm rounded-tr-sm p-1 duration-200 ${
                        activePermission === 2 ? 'bg-primary-main' : 'bg-layout-transparent'
                    }`}
                >
                    <CreateIcon />
                </div>
            </div>
            <p className="pr-8 font-semibold"> {getRowPermissionNameTranslated(permission.name)}</p>
        </div>
    );
};

Row.propTypes = {
    // eslint-disable-next-line
    permission: PropTypes.object,
    // eslint-disable-next-line
    userPermissions: PropTypes.array,
    setUserPermissions: PropTypes.func,
    enabled: PropTypes.bool,
};

Row.defaultProps = {
    permission: null,
    userPermissions: [],
    setUserPermissions: () => null,
    enabled: true,
};

Permission.propTypes = {
    // eslint-disable-next-line
    permissionGroup: PropTypes.object,
    // eslint-disable-next-line
    userPermissions: PropTypes.array,
    setUserPermissions: PropTypes.func,
    isSingle: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
    enabled: PropTypes.bool,
};

Permission.defaultProps = {
    permissionGroup: null,
    userPermissions: [],
    setUserPermissions: () => null,
    isSingle: false,
    children: <></>,
    enabled: true,
};
