import React, { createContext, useContext, useEffect, useRef, useState } from 'react';

import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import CheckIcon from '@material-ui/icons/Check';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CloseIcon from '@material-ui/icons/Close';

import { Button, CircularProgress, makeStyles } from '@material-ui/core';

import LoadingExportModal from 'components/modals/loading-export-modal';
import GlobalContext from 'contexts/GlobalContext';
import useScreenSizes from 'hooks/useScreenSizes';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { ActionButton, Toggle } from 'RaisisComponents';
import { useTranslation } from 'react-i18next';
import { formatDate, generateUUID, getRawImageFromPath, toLocaleNumber } from 'utils';
import API from 'utils/axios';
import { getImagesToCanvas, handleMultiPageExport } from 'utils/exportUtils';
import { generateMilestoneContent } from 'utils/notificationsUtils';

import BasicTooltip from '../basic-tooltip';
import DocumentTemplateColumn from './document-template-column';
import DocumentTemplateItem from './document-template-items/document-template-item';

const useStyles = makeStyles(() => {
    return {
        error: {
            backgroundColor: `var(--error)`,
            color: `var(--buttons-text)`,
            '&:hover': {
                backgroundColor: `var(--error-light)`,
            },
        },
        success: {
            backgroundColor: `var(--success)`,
            color: `var(--buttons-text)`,
            '&:hover': {
                backgroundColor: `var(--success-light)`,
            },
        },
    };
});

const defaultValues = {
    templateData: {},
    usedTemplate: {},
    rawTemplate: {},
    milestones: [],
    offers: [],
    isMultiView: false,
    loading: false,
    setLoading: () => {},
    highlightPair: '',
    highlightReference: '',
    setHighlightPair: () => {},
    setHighlightReference: () => {},
    handleChangeItem: () => {},
    handleRemoveItem: () => {},
    handleRemovePair: () => {},
    handleChangeSelectedMilestone: () => {},
    handleChangeSelectedMilestones: () => {},
    handleChangeSelectedOffer: () => {},
};

export const DocumentContext = createContext(defaultValues);

/**
 * The function `generateSignatureHtml` creates an HTML signature block with client and tenant
 * information for a React application.
 * @param {object} offer - A offer object used to get the client name
 * @param {object} tenant - Tenant object used to get the provider name
 * @returns The `generateSignatureHtml` function returns an HTML string that includes placeholders for
 * the tenant's company name, the provider label translated using i18next, the client's name extracted
 * from the offer data, and the beneficiary label translated using i18next. The HTML string contains
 * multiple `<p>` elements with inline styles for font size, color, and text alignment.
 */
const generateSignatureHtml = (offer, tenant) => {
    const { t } = i18next;
    const spacingHTML = `\u200B\t`;

    const clientName =
        typeof offer.contact.data === 'string'
            ? JSON.parse(offer.contact.data).standard.name
            : offer.contact.data.standard.name;

    return `<p style="font-size: 12px; color: rgb(0,0,0)">${spacingHTML}</p>
            <p style="font-size: 12px; color: rgb(0,0,0)">${spacingHTML}</p>
            <p style="font-size: 12px; color: rgb(0,0,0); text-align: right;">__________________________________</p>
            <p style="font-size: 12px; color: rgb(0,0,0); text-align: right;"><strong>${tenant.companyName}</strong></p>
            <p style="font-size: 12px; color: rgb(0,0,0); text-align: right;">${t('Provider')}</p>
            <p style="font-size: 12px; color: rgb(0,0,0)">${spacingHTML}</p>
            <p style="font-size: 12px; color: rgb(0,0,0); text-align: right;">__________________________________</p>
            <p style="font-size: 12px; color: rgb(0,0,0); text-align: right;"><strong>${clientName}</strong></p>
            <p style="font-size: 12px; color: rgb(0,0,0); text-align: right;">${t('Beneficiary')}</p>`;
};

/**
 * The function `generateOfferHtml` creates HTML content for a financial offer using data from the
 * offer object and specified currency objects.
 * @param {object} offer - A offer object used to get the items details
 * @param {object} currencyObj - The currency object used to get the national currency
 * @param {object} referenceCurrencyObj - The currency object used to get the reference currency
 * @returns The function `generateOfferHtml` returns a formatted HTML content string that includes
 * information about the financial centraliser of the offer, item details, revenues, currencies, VAT
 * information, offer date, and expiration date.
 */
const generateOfferHtml = (offer, currencyObj, referenceCurrencyObj, language) => {
    const { t } = i18next;
    const spacingHTML = `\u200B\t`;

    let contentHtml = `<h2 style="text-align: center; font-size: 24px; color: rgb(0,0,0)">${t(
        'Financial centralizer',
    )}</h2><p>${spacingHTML}</p>`;

    let itemsCount = 1;
    offer.items.forEach((item) => {
        if (item.baseItem) {
            Array.from({ length: item.amount }, (_, index) => index).forEach(() => {
                const value = item.baseItem.revenues.reduce(
                    (acc, revenue) =>
                        (acc += revenue.list.reduce((subAcc, subRevenue) => (subAcc += subRevenue.value), 0)),
                    0,
                );

                const secondaryValue = item.baseItem.revenues.reduce(
                    (acc, revenue) =>
                        (acc += revenue.list.reduce(
                            (subAcc, subRevenue) => (subAcc += subRevenue.secondCurrencyValue),
                            0,
                        )),
                    0,
                );

                contentHtml += `<p style="font-size: 12px; color: rgb(0,0,0)">${itemsCount}. ${
                    item.baseItem.itemName
                }: ${toLocaleNumber(value, language, 2, 4)} ${currencyObj.currency} / ${toLocaleNumber(secondaryValue, language, 2, 4)} ${
                    referenceCurrencyObj.currency
                }, ${t('without VAT')}, ${t('out of which')}:</p>`;

                item.baseItem.revenues.forEach((revenue, index) => {
                    const value = revenue.list.reduce((subAcc, subRevenue) => (subAcc += subRevenue.value), 0);
                    const secondaryValue = revenue.list.reduce(
                        (subAcc, subRevenue) => (subAcc += subRevenue.secondCurrencyValue),
                        0,
                    );

                    contentHtml += `<p style="font-size: 12px; color: rgb(0,0,0)">${spacingHTML}${index + 1}. ${
                        revenue.Revenue.name
                    }: ${toLocaleNumber(value, language, 2, 4)} ${currencyObj.currency} / ${toLocaleNumber(
                        secondaryValue,
                        language,
                        2,
                        4,
                    )} ${referenceCurrencyObj.currency}, ${t('without VAT')}, ${t('out of which')}:</p>`;

                    revenue.list.map((subRevenue, subIndex) => {
                        contentHtml += `<p style="font-size: 12px; color: rgb(0,0,0)">${spacingHTML}${spacingHTML}${
                            subIndex + 1
                        }. ${subRevenue.Revenue.name}: ${toLocaleNumber(subRevenue.value, language, 2, 4)} ${
                            currencyObj.currency
                        } / ${toLocaleNumber(subRevenue.secondCurrencyValue, language, 2, 4)} ${
                            referenceCurrencyObj.currency
                        }, ${t('without VAT')}</p>`;
                    });
                });

                contentHtml += `<p style="font-size: 12px;">${spacingHTML}</p>`;
                itemsCount++;
            });
        }
    });

    contentHtml += `<p style="font-size: 12px; color: rgb(0,0,0); text-align: right;">${t('Offer date')}: ${formatDate(
        offer.offerDate,
        true,
        true,
    )}</p>`;
    contentHtml += `<p style="font-size: 12px; color: rgb(0,0,0); text-align: right;">${t(
        'Expiration date',
    )}: ${formatDate(offer.expirationDate, true, true)}</p>`;

    return contentHtml;
};

const DocumentTemplate = ({
    disabled,
    rawTemplate,
    usedTemplate,
    templateData,
    setTemplateData,
    milestones,
    offers,
    initialData,
    pmProjectId,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { tenant, currencyObj, referenceCurrencyObj, language } = useContext(GlobalContext);

    const [width] = useScreenSizes();
    const [isInitializing, setIsInitializing] = useState(true);

    const [isCollapsed, setIsCollapsed] = useState(false);
    const [isCollapsing, setIsCollapsing] = useState(false);
    const containerRef = useRef(null);
    const [containerHeight, setContainerHeight] = useState(0);

    const [info, setInfo] = useState(null);

    /* The code is creating an object called `mappedInitializeDataItemType` with two key-value pairs.
   The keys are `content` and `signature`, and the values are functions `generateOfferHtml` and
   `generateSignatureHtml` respectively. This object is being used to map different types of
   data to corresponding functions for processing. */
    const mappedInitializeDataItemType = {
        content: generateOfferHtml,
        signature: generateSignatureHtml,
    };

    /**
     * The function `handleInitializeOffer` initializes an offer element based on provided data and maps item
     * type properties accordingly.
     * @param {object} element - Element object to be populated with the offer data
     * @param {object} data - An object which holds important data such as the offer id
     * @returns {undefined}
     */
    const handleInitializeOffer = (element, data) => {
        const offer = offers.find((o) => o.id === data.id);

        const mappedItemTypeProps = {
            content: [offer, currencyObj, referenceCurrencyObj, language],
            signature: [offer, tenant],
        };

        element.entity = [
            {
                ...element.entity[0],
                entityId: offer.id,
                data: {},
            },
        ];

        element.value = mappedInitializeDataItemType[element.itemType](...mappedItemTypeProps[element.itemType]);
    };

    const handleInitializeMilestones = async (element, data) => {};

    /* The code is creating an object called `mappedInitializeDataCases` that contains keys `offer` and
    `milestones`, each mapped to a corresponding function `handleInitializeOffer` and
    `handleInitializeMilestones`. This structure allows for easy access and execution of the
    appropriate function based on the key provided. */
    const mappedInitializeDataCases = {
        offer: handleInitializeOffer,
        milestones: handleInitializeMilestones,
    };

    /**
     * The function `initializeData` is responsible for setting up and initializing data based on a raw
     * template and used template. The raw template is the default template created in the configurator and
     * the used template represents an instance of the last used template in a previous generation
     * of a PDF and it contains data about how the columns have been populated at that time.
     * @returns {undefined}
     */
    const initializeData = async () => {
        try {
            setIsInitializing(true);

            // ? We check if the used template has been created using the current selected template.
            const isUsedTemplate = usedTemplate && usedTemplate.templateId === rawTemplate.id;

            // ? We use structure clone to deep clone the default template in order to avoid any unwanted mutations of the original object.
            const newData = structuredClone(rawTemplate);

            // ? If we have a used template we will populate the columns and elements of the raw template with the data of the used one (where is the case).
            // ? We will also add deleted columns and elements that have been deleted between the last time when this template was used.
            if (isUsedTemplate) {
                Object.values(newData.columns).forEach((column) => {
                    if (usedTemplate.columns[column.id]) {
                        column.elementsIds = [
                            ...new Set([...column.elementsIds, ...usedTemplate.columns[column.id].elementsIds]),
                        ];
                    }
                });

                // ? Add deleted columns
                const usedColumns = {};
                Object.entries(usedTemplate.columns).forEach(([key, value]) => {
                    if (!newData.columns[key]) usedColumns[key] = value;
                });

                newData.columns = { ...newData.columns, ...usedColumns };

                Object.values(newData.elements).forEach((element) => {
                    if (usedTemplate.elements[element.id]) {
                        element.value = usedTemplate.elements[element.id].value;
                        element.entity = usedTemplate.elements[element.id].entity;
                    }
                });

                // ? Add deleted elements
                const usedElements = {};
                Object.entries(usedTemplate.elements).forEach(([key, value]) => {
                    if (!newData.elements[key]) usedElements[key] = value;
                });

                newData.elements = { ...newData.elements, ...usedElements };
            }

            // ? We parse the elements value and entity, and we initialize the elements with the specific data when we don't have a used template
            for (const columnId of newData.columnOrder) {
                const cachedItems = {};
                const column = newData.columns[columnId];
                const elements = column.elementsIds.map((ei) => newData.elements[ei]);

                for (const element of elements.values()) {
                    const pairKey = element.pairKey || 'none';

                    if (!cachedItems[element.purpose]) cachedItems[element.purpose] = {};

                    if (!cachedItems[element.purpose][pairKey]) cachedItems[element.purpose][pairKey] = {};

                    if (isFinite(cachedItems[element.purpose][pairKey][element.itemType])) {
                        cachedItems[element.purpose][pairKey][element.itemType] += 1;
                    } else {
                        cachedItems[element.purpose][pairKey][element.itemType] = element.pairKey
                            ? (Object.values(
                                  Object.entries(cachedItems[element.purpose]).find(([pk]) => pk === pairKey)[1],
                              )[0] ?? Object.entries(cachedItems[element.purpose]).length - 1)
                            : 0;
                    }

                    element.value = JSON.parse(element.value);
                    element.entity = element.entity.map((entry) => ({ ...entry, data: JSON.parse(entry.data) }));

                    if (!isUsedTemplate && initialData.length)
                        for (const data of initialData) {
                            if (
                                data.itemType === element.itemType &&
                                data.purpose === element.purpose &&
                                data.position === cachedItems[element.purpose][pairKey][element.itemType]
                            )
                                await mappedInitializeDataCases[element.purpose](element, data);
                        }
                }
            }

            setTemplateData(newData);
        } catch (error) {
            console.error(error);
        } finally {
            setIsInitializing(false);
        }
    };

    const numberOfColumns = templateData
        ? Object.values(templateData.columns).filter((column) => column.isActive === true).length
        : 0;

    const [loading, setLoading] = useState(false);
    const [isMultiView, setIsMultiView] = useState(false);
    const [displayedColumn, setDisplayedColumn] = useState(() => {
        const initialIndex = Object.values(rawTemplate.columns).findIndex((column) => column.isActive === true);
        return initialIndex >= 0 ? initialIndex : 0;
    });
    const [highlightPair, setHighlightPair] = useState([]);
    const [highlightReference, setHighlightReference] = useState({ id: '', referenceId: '' });

    const handleChangeItem = (elementId, key, value) =>
        setTemplateData((prev) => {
            const newElements = structuredClone(prev.elements);
            newElements[elementId][key] = value;

            const newTemplateData = {
                ...prev,
                elements: newElements,
            };

            return newTemplateData;
        });

    const handleBatchedChangeItem = (elementId, entries) =>
        setTemplateData((prev) => {
            const newElements = structuredClone(prev.elements);
            newElements[elementId] = { ...newElements[elementId], ...entries };

            const newTemplateData = {
                ...prev,
                elements: newElements,
            };

            return newTemplateData;
        });

    /**
     * The `handleRemoveItem` function removes an element and its references from a data structure and
     * updates the context accordingly.
     * @returns {undefined}
     */
    const handleRemoveItem = (elementId) => {
        const newElements = structuredClone(templateData.elements);

        // ? We delete the element on main columns
        delete newElements[elementId];

        // ? We delete the element on every other columns
        const referencesIds = Object.values(newElements)
            .filter((el) => el.referenceId === elementId)
            .map((el) => el.id);

        for (const elId of referencesIds) {
            delete newElements[elId];
        }

        // ? We remove the element form each column and we update it
        const newColumns = structuredClone(templateData.columns);
        [elementId, ...referencesIds].forEach((elId) => {
            const columnId = elId.split('column-')[1];
            const newElementIds = newColumns[`column-${columnId}`].elementsIds.filter((eI) => eI !== elId);

            newColumns[`column-${columnId}`] = {
                ...newColumns[`column-${columnId}`],
                elementsIds: newElementIds,
                isCompleted: newElementIds.length === 0 ? false : newColumns[`column-${columnId}`].isCompleted,
                isActive: newElementIds.length === 0 ? false : newColumns[`column-${columnId}`].isActive,
            };
        });

        const newCurrentContext = {
            ...templateData,
            elements: newElements,
            columns: newColumns,
        };

        setTemplateData(newCurrentContext);
    };

    /**
     * The function `handleRemovePair` removes a pair of elements and their references from a data
     * structure and updates the context accordingly.
     */
    const handleRemovePair = (pairKey) => {
        const newElements = structuredClone(templateData.elements);

        //? We get the elements ids with this pair
        const pairElementsIds = Object.values(newElements)
            .filter((el) => el.pairKey === pairKey)
            .map((el) => el.id);

        //? We delete the elements with this pair on the main column
        for (const elementId of pairElementsIds) {
            delete newElements[elementId];
        }

        // ? We delete the element on every other columns
        const referencesIds = Object.values(newElements)
            .filter((el) => pairElementsIds.includes(el.referenceId))
            .map((el) => el.id);

        for (const elId of referencesIds) {
            delete newElements[elId];
        }

        // ? We remove the element form each column and we update it
        const newColumns = structuredClone(templateData.columns);
        [...pairElementsIds, ...referencesIds].forEach((elId) => {
            const columnId = elId.split('column-')[1];
            const newElementIds = newColumns[`column-${columnId}`].elementsIds.filter((eI) => eI !== elId);

            newColumns[`column-${columnId}`] = {
                ...newColumns[`column-${columnId}`],
                elementsIds: newElementIds,
                isCompleted: newElementIds.length === 0 ? false : newColumns[`column-${columnId}`].isCompleted,
                isActive: newElementIds.length === 0 ? false : newColumns[`column-${columnId}`].isActive,
            };
        });

        const newCurrentContext = {
            ...templateData,
            elements: newElements,
            columns: newColumns,
        };
        setTemplateData(newCurrentContext);
    };

    const handleChangeSelectedMilestone = async (pairKey, milestoneIdx, viewMode) => {
        try {
            setLoading(true);

            const selectedMilestones = [milestones[milestoneIdx].id];

            const contentHtml = generateMilestoneContent(
                {
                    selectedMilestones,
                    milestones,
                    purpose: pmProjectId ? 'PM' : 'CRM',
                    info,
                },
                t,
            );

            const [ganttImage] = await getImagesToCanvas(
                [
                    {
                        ids: ['gantt-wrapper-root'],
                        type: 'gantt',
                        element: {
                            type: 'milestones',
                            purpose: pmProjectId ? 'pm' : 'ia',
                        },
                        data: {
                            view: viewMode,
                            customDates: null,
                            selectedEntities: selectedMilestones,
                        },
                        request: {
                            params: {
                                currentPage: 0,
                                perPage: 99999,
                                pagesToLoad: 1,
                                pmProjectPlanId: pmProjectId ?? undefined,
                            },
                        },
                    },
                ],
                language,
            );

            const dataImages = handleMultiPageExport(
                ganttImage.canvas,
                ganttImage.disabledCropHeightsIntervals,
                'portrait',
            );

            const valueMapper = {
                content: contentHtml,
                gallery: await Promise.all(
                    dataImages.map(async (d, i) => {
                        const blob = await getRawImageFromPath(d.image, true);
                        const file = new File([blob], `gantt-image-${i}.png`, { type: 'image/png' });

                        return { id: generateUUID(), url: d.image, blob: file };
                    }),
                ),
            };

            Object.values(templateData.elements)
                .filter((el) => el.pairKey === pairKey)
                .forEach((el) => {
                    const mainElementId = el.referenceId || el.id;
                    let referencesIds = [];

                    if (el.isMultiLanguage) {
                        referencesIds = Object.values(templateData.elements)
                            .filter((element) => element.referenceId === mainElementId)
                            .map((element) => element.id);
                    }

                    referencesIds.push(mainElementId);

                    referencesIds.forEach((elId) => {
                        handleBatchedChangeItem(elId, {
                            entity: [
                                {
                                    entityId: milestones[milestoneIdx].id,
                                    data: { ...templateData.elements[elId].entity[0].data, viewMode },
                                },
                            ],
                            value: valueMapper[templateData.elements[elId].itemType],
                        });
                    });
                });
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    /**
     * The function `handleChangeSelectedMilestones` is an asynchronous function that handles changes
     * in selected milestones by generating notification data and updating elements based on the
     * provided pair key, milestone indexes, and view mode.
     * @param {string} pairKey - The elements pair that need updating
     * @param {array} milestoneIndexes - An array that contains the indexes of the selected milestones
     * @param {string} viewMode - The view mode of the gantt
     * @returns {undefined}
     */
    const handleChangeSelectedMilestones = async (pairKey, milestoneIndexes, viewMode) => {
        try {
            setLoading(true);

            // ? We extract the data for the elements (contentHtml for content and dataImages for gallery)
            const selectedMilestones = milestoneIndexes.map((index) => milestones[index].id);

            const contentHtml = generateMilestoneContent(
                {
                    selectedMilestones,
                    milestones,
                    purpose: pmProjectId ? 'PM' : 'CRM',
                    info,
                },
                t,
            );

            const [ganttImage] = await getImagesToCanvas(
                [
                    {
                        ids: ['gantt-wrapper-root'],
                        type: 'gantt',
                        element: {
                            type: 'milestones',
                            purpose: pmProjectId ? 'pm' : 'ia',
                        },
                        data: {
                            view: viewMode,
                            customDates: null,
                            selectedEntities: selectedMilestones,
                        },
                        request: {
                            params: {
                                currentPage: 0,
                                perPage: 99999,
                                pagesToLoad: 1,
                                pmProjectPlanId: pmProjectId ?? undefined,
                            },
                        },
                    },
                ],
                language,
            );

            const dataImages = handleMultiPageExport(
                ganttImage.canvas,
                ganttImage.disabledCropHeightsIntervals,
                'portrait',
            );

            //? We create an object with the values for the two elements
            const valueMapper = {
                content: contentHtml,
                gallery: await Promise.all(
                    dataImages.map(async (d, i) => {
                        const blob = await getRawImageFromPath(d.image, true);
                        const file = new File([blob], `gantt-image-${i}.png`, { type: 'image/png' });

                        return { id: generateUUID(), url: d.image, blob: file };
                    }),
                ),
            };

            //? We populate all elements on each columns with the extracted content.
            //! The elements that are not multi language will be populated only on the main columns in order to avoid a bigger payload when sending the template to the backend.
            Object.values(templateData.elements)
                .filter((el) => el.pairKey === pairKey)
                .forEach((el) => {
                    const mainElementId = el.referenceId || el.id;
                    let referencesIds = [];

                    if (el.isMultiLanguage) {
                        referencesIds = Object.values(templateData.elements)
                            .filter((element) => element.referenceId === mainElementId)
                            .map((element) => element.id);
                    }

                    referencesIds.push(mainElementId);

                    referencesIds.forEach((elId) => {
                        handleBatchedChangeItem(elId, {
                            entity: milestoneIndexes.map((milestoneIdx) => ({
                                entityId: milestones[milestoneIdx].id,
                                data: { viewMode },
                            })),
                            value: valueMapper[templateData.elements[elId].itemType],
                        });
                    });
                });
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    /**
     * The function `handleChangeSelectedOffer` updates a selected offer based on the element and offer
     * index provided.
     * @param {object} element - Element object to be populated with the offer data
     * @param {number} offerIdx - A number representing the index of the offer
     * @returns {undefined}
     */
    const handleChangeSelectedOffer = (pairKey, offerIdx) => {
        const offer = offers[offerIdx];

        const mappedItemTypeProps = {
            content: [offer, currencyObj, referenceCurrencyObj, language],
            signature: [offer, tenant],
        };

        Object.values(templateData.elements)
            .filter((el) => el.pairKey === pairKey)
            .forEach((el) => {
                const mainElementId = el.referenceId || el.id;
                let referencesIds = [];

                if (el.isMultiLanguage) {
                    referencesIds = Object.values(templateData.elements)
                        .filter((element) => element.referenceId === mainElementId)
                        .map((element) => element.id);
                }

                referencesIds.push(mainElementId);

                referencesIds.forEach((elId) => {
                    handleBatchedChangeItem(elId, {
                        entity: [
                            {
                                ...templateData.elements[elId].entity[0],
                                entityId: offer.id,
                                data: {},
                            },
                        ],
                        value: mappedInitializeDataItemType[templateData.elements[elId].itemType](
                            ...mappedItemTypeProps[templateData.elements[elId].itemType],
                        ),
                    });
                });
            });
    };

    /**
     * The function `handlePrevColumn` updates the displayed column based on the current active column.
     */
    const handlePrevColumn = () =>
        setDisplayedColumn((prev) => {
            const firstActiveColumn = templateData.columnOrder.findIndex(
                (columnId) => templateData.columns[columnId].isActive === true,
            );

            let nextValue = firstActiveColumn;

            if (prev > firstActiveColumn)
                for (let i = prev - 1; i >= 0; i--) {
                    const prevColumn = templateData.columns[templateData.columnOrder[i]];
                    if (prevColumn.isActive) {
                        nextValue = i;
                        break;
                    }
                }

            return nextValue;
        });

    /**
     * The function `handleNextColumn` updates the displayed column based on the current active column.
     */
    const handleNextColumn = () =>
        setDisplayedColumn((prev) => {
            const lastActiveColumn = templateData.columnOrder.findLastIndex(
                (columnId) => templateData.columns[columnId].isActive === true,
            );

            let nextValue = lastActiveColumn;

            if (prev < lastActiveColumn)
                for (let i = prev + 1; i < rawTemplate.columnOrder.length; i++) {
                    const prevColumn = templateData.columns[templateData.columnOrder[i]];
                    if (prevColumn.isActive) {
                        nextValue = i;
                        break;
                    }
                }

            return nextValue;
        });

    /**
     * The function `handleToggleIsActiveColumn` toggles the `isActive` property of a column in a
     * template data object and updates the displayed column based on the toggled state.
     * @param {string} columnId - Is the id of the columns which is gonna be toggle
     * @param {number} columnsIndex - Is the index of the columns which is gonna be toggle
     * @returns {undefined}
     */
    const handleToggleIsActiveColumn = (columnId, columnIndex) =>
        setTemplateData((prev) => {
            const newColumns = structuredClone(prev.columns);
            const hasActiveColumns = Object.values(prev.columns).some((column) => column.isActive === true);

            newColumns[columnId].isActive = !newColumns[columnId].isActive;
            const isActivating = newColumns[columnId].isActive === true;

            //? If the toggle result in activating the column and there are no active columns, we display this column
            if (isActivating && !hasActiveColumns) setDisplayedColumn(columnIndex);
            //? If we deactivating the column we have other active ones, we try to display the next closes active column from the left, or to the right
            else if (!isActivating && hasActiveColumns) {
                let hasPreviousActiveColumn = false;

                for (let i = columnIndex - 1; i >= 0; i--) {
                    const prevColumn = templateData.columns[templateData.columnOrder[i]];
                    if (prevColumn.isActive) {
                        setDisplayedColumn(i);
                        hasPreviousActiveColumn = true;
                        break;
                    }
                }

                if (!hasPreviousActiveColumn)
                    for (let i = columnIndex + 1; i < rawTemplate.columnOrder.length; i++) {
                        const prevColumn = templateData.columns[templateData.columnOrder[i]];
                        if (prevColumn.isActive) {
                            setDisplayedColumn(i);
                            break;
                        }
                    }
            }
            //? Else we set the displayed column to 0 as a default
            else setDisplayedColumn(0);

            const newTemplateData = {
                ...prev,
                columns: newColumns,
            };

            return newTemplateData;
        });

    /**
     * The function `handleToggleCollapse` toggles the state of `isCollapsed` and sets `isCollapsing` to
     * true if `isCollapsed` is changing to true.
     */
    const handleToggleCollapse = () =>
        setIsCollapsed((prev) => {
            if (!prev) setIsCollapsing(true);
            return !prev;
        });

    useEffect(() => {
        const container = containerRef.current;
        if (!container) return;

        const elementHeight = container.scrollHeight;

        const computedStyles = getComputedStyle(container);
        const paddingTop = parseFloat(computedStyles.paddingTop);
        const paddingBottom = parseFloat(computedStyles.paddingBottom);
        const borderTop = parseFloat(computedStyles.borderTopWidth);
        const borderBottom = parseFloat(computedStyles.borderBottomWidth);

        const realHeight = elementHeight + paddingTop + paddingBottom + borderTop + borderBottom;

        setContainerHeight(realHeight);
    }, [isInitializing, templateData, width, isCollapsed, isMultiView]);

    useEffect(() => {
        const container = containerRef.current;
        if (!container) return;

        const handleTransitionEnd = (e) => {
            if (e.propertyName === 'max-height') setIsCollapsing(false);
        };

        container.addEventListener('transitionend', handleTransitionEnd);
        return () => container.removeEventListener('transitionend', handleTransitionEnd);
    }, [isCollapsing]);

    useEffect(() => {
        initializeData();
    }, [rawTemplate]);

    useEffect(() => {
        (async () => {
            try {
                if (pmProjectId) {
                    const response = await API.get('/pm_projectPlanningOverview', {
                        params: {
                            id: pmProjectId,
                        },
                    });
                    setInfo(response.data.pm);
                } else setInfo(tenant);
            } catch (error) {
                console.error(error);
            }
        })();
    }, [pmProjectId]);

    return isInitializing ? (
        <div className="flex h-64 w-full items-center justify-center bg-layout-main">
            <CircularProgress />
        </div>
    ) : (
        <DocumentContext.Provider
            value={{
                templateData,
                usedTemplate,
                rawTemplate,
                milestones,
                offers,
                isMultiView,
                loading,
                setLoading,
                highlightPair,
                highlightReference,
                setHighlightPair,
                setHighlightReference,
                handleChangeItem,
                handleRemoveItem,
                handleRemovePair,
                handleChangeSelectedMilestone,
                handleChangeSelectedMilestones,
                handleChangeSelectedOffer,
            }}
        >
            <div className="mb-10 flex w-full flex-col gap-8">
                <div className="flex flex-col gap-4 bg-layout-light p-4">
                    <div className="flex gap-4">
                        <div className="flex w-max items-center gap-2 rounded-md bg-layout-transparent p-2.5 sm:w-full">
                            <Toggle checked={isMultiView} setChecked={setIsMultiView} disabled={disabled} />
                            <p>{t('See all languages')}</p>
                        </div>
                        <div className="flex w-max items-center gap-2 rounded-md bg-layout-transparent p-2.5 sm:w-full">
                            <div
                                className={`transform transition-transform duration-300 ${
                                    isCollapsed ? 'rotate-180' : ''
                                }`}
                            >
                                <ActionButton
                                    disabled={disabled}
                                    sizes={{
                                        width: 9,
                                        height: 9,
                                    }}
                                    icon={<ArrowUpwardIcon fontSize="large" />}
                                    onClick={handleToggleCollapse}
                                />
                            </div>
                            <p>{t(isCollapsed ? 'Extend interface' : 'Collapse interface')}</p>
                        </div>
                    </div>
                    <div className="flex flex-col gap-2 rounded-md bg-layout-lighter p-2">
                        <h4>{t('Document languages')}</h4>
                        <div className="flex flex-wrap gap-2">
                            {templateData.columnOrder.map((columnId, index) => {
                                const column = templateData.columns[columnId];

                                return (
                                    <div
                                        key={column.id}
                                        className="flex flex-col items-center gap-2 rounded-sm bg-layout-light p-2"
                                    >
                                        <p>{column.title}</p>

                                        <BasicTooltip
                                            tip={t(
                                                "You can't activate this language because its translation isn't completed",
                                            )}
                                            disabled={column.isCompleted}
                                        >
                                            <Button
                                                onClick={() => handleToggleIsActiveColumn(column.id, index)}
                                                endIcon={column.isActive ? <CloseIcon /> : <CheckIcon />}
                                                className={column.isActive ? classes.error : classes.success}
                                                disabled={!column.isCompleted || disabled}
                                                size="small"
                                            >
                                                {column.isActive
                                                    ? t('Deactivate language for export')
                                                    : t('Activate language for export')}
                                            </Button>
                                        </BasicTooltip>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>

                <div
                    className={`relative grid gap-8 divide-purple-300 border border-layout-light transition-all duration-300 grid-cols-${
                        isMultiView ? numberOfColumns : 1
                    } ${isCollapsed || numberOfColumns === 0 ? 'pt-10' : !isMultiView ? 'pt-14' : 'pt-4'} ${
                        isMultiView && numberOfColumns > 3
                            ? 'grid-cols-1'
                            : isMultiView && numberOfColumns > 2
                              ? '2xl:grid-cols-1'
                              : isMultiView && numberOfColumns > 1
                                ? 'xl:grid-cols-1'
                                : ''
                    } ${
                        isCollapsed || numberOfColumns === 0
                            ? 'overflow-hidden rounded-md bg-layout-transparent px-4 pb-10 shadow delay-300'
                            : 'rounded-3xl bg-dark-text px-8 pb-8'
                    } ${disabled ? 'pointer-events-none' : ''}`}
                    style={{
                        height: isCollapsed || numberOfColumns === 0 ? 'calc(infinity * 1px)' : '',
                        maxHeight: isCollapsed || numberOfColumns === 0 ? '0px' : `${containerHeight}px`,
                    }}
                    ref={containerRef}
                >
                    <div
                        className={`absolute left-1/2 top-2 flex -translate-x-1/2 transform items-center gap-4 text-layout-lighter transition-opacity duration-300 ${
                            isCollapsed || numberOfColumns === 0 || isMultiView
                                ? 'pointer-events-none opacity-0'
                                : 'delay-300'
                        }`}
                    >
                        <ActionButton
                            sizes={{
                                width: 9,
                                height: 9,
                            }}
                            icon={<ChevronLeftIcon fontSize="large" />}
                            onClick={handlePrevColumn}
                            disabled={
                                displayedColumn ===
                                templateData.columnOrder.findIndex(
                                    (columnId) => templateData.columns[columnId].isActive === true,
                                )
                            }
                        />
                        <p className="whitespace-nowrap text-2xl text-layout-lighter sm:text-xl">
                            {templateData.columns[templateData.columnOrder[displayedColumn]].title}
                        </p>
                        <ActionButton
                            sizes={{
                                width: 9,
                                height: 9,
                            }}
                            icon={<ChevronRightIcon fontSize="large" />}
                            onClick={handleNextColumn}
                            disabled={
                                displayedColumn ===
                                templateData.columnOrder.findLastIndex(
                                    (columnId) => templateData.columns[columnId].isActive === true,
                                )
                            }
                        />
                    </div>

                    <div
                        className={`absolute left-1/2 top-1/2 flex w-full -translate-x-1/2 -translate-y-1/2 transform transition-opacity duration-300 ${
                            isCollapsed || numberOfColumns === 0
                                ? 'opacity-100'
                                : 'pointer-events-none opacity-0 delay-300'
                        }`}
                    >
                        <p className="mx-auto flex items-center gap-1 px-4">
                            {t(
                                numberOfColumns === 0
                                    ? 'There are no selected languages, you have to select one first'
                                    : 'The document interface is collapsed, in order to view it, you must click on the extend interface button',
                            )}
                            {numberOfColumns !== 0 ? (
                                <span className="flex items-center">
                                    &ldquo;
                                    <span className="rotate-180 transform">
                                        <ArrowUpwardIcon fontSize="small" />
                                    </span>
                                    &rdquo;
                                </span>
                            ) : (
                                ''
                            )}
                        </p>
                    </div>

                    {templateData.columnOrder.map((id, index) => {
                        //? If we are viewing only a column at a time, we will return null for all columns that are not the displayed column
                        if (!isMultiView && index !== displayedColumn) return null;

                        const column = templateData.columns[id];

                        //? If the column is not active, we return null as well
                        if (!column.isActive) return null;

                        const elements = column.elementsIds
                            .map((id) => templateData.elements[id])
                            .filter((el) => !el.flagDisabled);

                        //? If we don't have multi view selected or the display of the grid is only in one column we use the default return of the component
                        if (
                            !isMultiView ||
                            (isMultiView &&
                                (numberOfColumns > 3 ||
                                    (numberOfColumns > 2 && width <= 1500) ||
                                    (numberOfColumns > 1 && width <= 1250)))
                        )
                            return (
                                <div
                                    key={id}
                                    className={`${
                                        isCollapsed ? 'hide-elements pointer-events-none' : 'display-elements'
                                    } ${!isCollapsing && isCollapsed && numberOfColumns > 0 ? 'hidden' : ''}`}
                                >
                                    <DocumentTemplateColumn
                                        displayTitle
                                        column={column}
                                        elements={elements}
                                        isMultiColumn={isMultiView && numberOfColumns > 1}
                                    />
                                </div>
                            );

                        //? We create an array of arrays in order to be able to map and recreate a document like structure that has elements that are multi language or not
                        const elementsSubArrays = [[]];
                        for (const element of elements) {
                            //? We always push the first element into the first sub array
                            if (elementsSubArrays[0].length === 0) elementsSubArrays[0].push(element);
                            //? If the element is not multi language we create a new sub array with one element
                            else if (!element.isMultiLanguage) {
                                elementsSubArrays.push([]);
                                elementsSubArrays.at(-1).push(element);
                            } else {
                                //? We check if the last sub array contains elements (we only need to check the last one) that are multi language or not
                                const prevElement = elementsSubArrays.at(-1).at(-1);

                                //? If it contains elements that are not multi language we create a new subarray
                                if (!prevElement.isMultiLanguage) {
                                    elementsSubArrays.push([]);
                                    elementsSubArrays.at(-1).push(element);
                                }
                                //? If it contains elements that are multi language we will push the new elements to the previous sub array
                                else {
                                    elementsSubArrays.at(-1).push(element);
                                }
                            }
                        }

                        //? We filter only the columns that are active in order to find the proper index for the start column in the grid
                        const activeColumnOrder = templateData.columnOrder.filter(
                            (columnId) => templateData.columns[columnId].isActive,
                        );
                        const columnIndex = activeColumnOrder.findIndex((activeColumnId) => activeColumnId === id);

                        return elementsSubArrays.map((subArray, subIndex) => {
                            //? If the subarray has only one element and the element is not multi language we render a layout with one element that will take the space of all the columns
                            if (subArray.length === 1 && !subArray[0].isMultiLanguage) {
                                //? We must render only the element present on an active column
                                const canRender =
                                    index ===
                                    templateData.columnOrder.findIndex(
                                        (columnId) => templateData.columns[columnId].isActive === true,
                                    );

                                if (!canRender) return null;

                                return (
                                    <div
                                        className={`col-span-full flex flex-col gap-4 ${
                                            isCollapsed ? 'hide-elements pointer-events-none' : 'display-elements'
                                        } ${!isCollapsing && isCollapsed && numberOfColumns > 0 ? 'hidden' : ''}`}
                                        key={`${id}-${subIndex}`}
                                        style={{
                                            gridColumnStart: 1,
                                            gridRowStart: subIndex + 1,
                                        }}
                                    >
                                        {subIndex === 0 && (
                                            <h3 className="text-center text-layout-lighter">{column.title}</h3>
                                        )}
                                        <DocumentTemplateItem highlightAllPairs element={subArray[0]} />
                                    </div>
                                );
                            }

                            //? In the other case we use a default render for the subarray composed of multi language elements
                            return (
                                <div
                                    className={`col-span-1 ${
                                        isCollapsed ? 'hide-elements pointer-events-none' : 'display-elements'
                                    } ${!isCollapsing && isCollapsed && numberOfColumns > 0 ? 'hidden' : ''}`}
                                    key={`${id}-${subIndex}`}
                                    style={{
                                        gridColumnStart: columnIndex + 1,
                                        gridRowStart: subIndex + 1,
                                    }}
                                >
                                    <DocumentTemplateColumn
                                        highlightAllPairs
                                        displayTitle={subIndex === 0}
                                        column={column}
                                        elements={subArray}
                                        isMultiColumn={isMultiView && numberOfColumns > 1}
                                    />
                                </div>
                            );
                        });
                    })}
                </div>
            </div>
            <LoadingExportModal open={loading} />
        </DocumentContext.Provider>
    );
};

DocumentTemplate.propTypes = {
    disabled: PropTypes.bool,
    rawTemplate: PropTypes.object,
    usedTemplate: PropTypes.object,
    templateData: PropTypes.object,
    setTemplateData: PropTypes.func,
    milestones: PropTypes.array,
    offers: PropTypes.array,
    initialData: PropTypes.array,
    pmProjectId: PropTypes.string,
};

DocumentTemplate.defaultProps = {
    disabled: false,
    rawTemplate: null,
    usedTemplate: null,
    templateData: null,
    setTemplateData: () => {},
    milestones: [],
    offers: [],
    initialData: [],
    pmProjectId: null,
};

export default DocumentTemplate;
