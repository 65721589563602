import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import { Button } from '@material-ui/core';

import SipRow from 'components/crm/quotes/create-quotes/sip-row';
import Loading from 'components/shared/loading';
import OfferContext from 'contexts/OfferContext';
import UserContext from 'contexts/UserContext';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { Dropdown } from 'RaisisComponents/index.js';
import { useTranslation } from 'react-i18next';
import { crm } from 'routes';
import API from 'utils/axios';
import { errorHandling } from 'utils/index';

const OfferSIP = ({ viewOnly }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();

    const { checkPerm } = useContext(UserContext);
    const { offerContext, editOfferContext } = useContext(OfferContext);

    const canAll = checkPerm([
        {
            permissionId: '9',
            permissionType: 'ALL',
        },
    ]);

    const [loading, setLoading] = useState(true);
    const [sip, setSip] = useState([]);
    const [selectedSIP, setSelectedSIP] = useState([]);

    const deleteSip = (index) => {
        setSelectedSIP([...selectedSIP.slice(0, index), ...selectedSIP.slice(index + 1)]);
    };

    /**
     * Fetch Catalogue
     */
    useEffect(() => {
        (async () => {
            try {
                const response = await API.get('/immobile', {
                    params: {
                        perPage: 99999,
                        currentPage: 0,
                        pagesToLoad: 1,
                    },
                });
                setSip(response.data.immobile);

                if (offerContext.offer && offerContext.offer.OfferImmobile.length)
                    setSelectedSIP(offerContext.offer.OfferImmobile.map((sip) => sip.immobile));
            } catch (err) {
                console.error(err);
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    const addSIPToOfferOrUpdate = async () => {
        try {
            setLoading(true);
            const res = await API.post(`/offer_completion/${offerContext.offerId}/IMMOBILE`, {
                immobile: selectedSIP?.map((s) => ({
                    id: s.id,
                    quantity: 1,
                })),
            });

            enqueueSnackbar(t('The third step of the offer is successfully accomplished!'), {
                variant: 'success',
            });

            setLoading(false);
            editOfferContext('offer', res.data.offer);
            editOfferContext('step', 3);
        } catch (err) {
            console.error(err);
            enqueueSnackbar(errorHandling(err).length > 100 ? errorHandling(err) : t(errorHandling(err)), {
                variant: 'error',
            });
        } finally {
            setLoading(false);
        }
    };

    const hiddenOptions = useMemo(() => {
        return selectedSIP.reduce((acc, curr) => {
            const idx = sip.findIndex((art) => art.id === curr.id);
            return idx >= 0 ? [...acc, idx] : acc;
        }, []);
    }, [sip, selectedSIP]);

    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <div className="flex max-w-3xl flex-col items-start">
                    <div className="w-full">
                        {selectedSIP.map((s, index) => (
                            <div key={s.id} className="mb-10">
                                <SipRow deleteSip={() => deleteSip(index)} sip={s} disabled={viewOnly} />
                            </div>
                        ))}
                        {sip.length > 0 && !viewOnly && (
                            <div className="mb-10 inline-flex gap-4">
                                <div className={`${canAll ? '' : 'pointer-events-none'}`}>
                                    <Dropdown
                                        hiddenOptions={hiddenOptions}
                                        options={sip.map((s) => JSON.parse(s.data)['standard']?.name)}
                                        selectedOption={null}
                                        setSelectedOption={(newSip) => {
                                            const sipToAdd = { ...sip[newSip] };
                                            setSelectedSIP([...selectedSIP, sipToAdd]);
                                        }}
                                        placeholder={t('Add catalog entity')}
                                    />
                                </div>
                            </div>
                        )}
                        {sip.length === 0 && !viewOnly && (
                            <div className="mb-5 flex flex-col items-center justify-center rounded-md bg-layout-transparent px-4 py-8 shadow">
                                <p className="mb-5">
                                    {t('There are no catalog entities yet, you have to add one first!')}
                                </p>
                                <Button
                                    color="primary"
                                    onClick={() => history.push(crm.base + crm.estate.base + crm.estate.create)}
                                >
                                    {t('Add catalog entity')}
                                </Button>
                            </div>
                        )}
                        {selectedSIP.length === 0 && viewOnly && (
                            <div className="flex w-full items-center justify-center rounded-md bg-layout-transparent px-4 py-8 shadow">
                                <p>{t('There are no catalog entities on this offer!')}</p>
                            </div>
                        )}
                    </div>

                    {!viewOnly && (
                        <div className="mt-20 flex gap-6">
                            <Button
                                color="primary"
                                startIcon={<ArrowBackIosIcon />}
                                onClick={() => editOfferContext('step', 1)}
                            >
                                {t('Step')} 2
                            </Button>
                            <Button color="primary" endIcon={<ArrowForwardIosIcon />} onClick={addSIPToOfferOrUpdate}>
                                {t('Step')} 4
                            </Button>
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

OfferSIP.propTypes = {
    viewOnly: PropTypes.bool,
};

OfferSIP.defaultProps = { viewOnly: false };

export default OfferSIP;
