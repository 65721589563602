import React, { useCallback, useContext, useEffect, useState } from 'react';

import AddIcon from '@material-ui/icons/Add';

import { Button, Checkbox, FormControlLabel, TextField } from '@material-ui/core';

import SubMilestoneActivity from 'components/internal-activity/milestone-activity';
import SubOpportunityActivity from 'components/internal-activity/sub-opportunity-activity';
import GlobalContext from 'contexts/GlobalContext';
import TaskManagementContext from 'contexts/TaskManagementContext';
import UserContext from 'contexts/UserContext';
import DOMPurify from 'dompurify';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import useScreenSizes from 'hooks/useScreenSizes.js';
import htmlToDraft from 'html-to-draftjs';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { DatePicker, Dropdown, LabelWrapper, MultiDropdown } from 'RaisisComponents/index.js';
import { Editor } from 'react-draft-wysiwyg';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import API from 'utils/axios';
import * as yup from 'yup';

const PMTaskManagementOpportunities = (props) => {
    const { theme } = useContext(GlobalContext);
    const { departmentId, partnerId, tenantUsers, closeModal } = props;
    const { t } = useTranslation();
    const taskManagementContext = useContext(TaskManagementContext);
    const { projectId } = useParams();

    const investorsUsers = tenantUsers.filter((u) => u.isInvestor);
    const affiliateUsers = tenantUsers.filter((u) => u.isAffiliate);
    const normalUsers = tenantUsers.filter((u) => !u.isInvestor && !u.isAffiliate);

    const [width] = useScreenSizes();

    const { user } = useContext(UserContext);
    const { enqueueSnackbar } = useSnackbar();

    /*
        Opportunity states/functions
    */
    const [opportunities, setOpportunities] = useState([]);
    const [opportunitiesConfig, setOpportunitiesConfig] = useState([]);
    const [opportunitiesNames, setOpportunitiesName] = useState([]);
    const [opportunityInfo, setOpportunityInfo] = useState(null);
    const [checkboxOpportunities, setCheckboxOpportunities] = useState(false);
    const [titleOpportunity, setTitleOpportunity] = useState('');
    const [selectedOpportunity, setSelectedOpportunity] = useState(null);

    //Opportunity description logic
    const htmlToDraftBlocks = (html) => {
        const blocksFromHtml = htmlToDraft(html);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        const editorState = EditorState.createWithContent(contentState);
        return editorState;
    };

    const [descriptionOpportunity, setDescriptionOpportunity] = useState(
        htmlToDraftBlocks(`<h2 style="color: rgb(0,0,0);>${t('Add description for the Opportunity')}</h2>`),
    );

    const [supervisor, setSupervisor] = useState([]);
    const [watcher, setWatcher] = useState([]);
    const [responsible, setResponsible] = useState([]);
    const [active, setActive] = useState(false);

    const [opportunityParentId, setOpportunityParentId] = useState(null);

    const [opportunityStartDate, setOpportunityStartDate] = useState(new Date());

    useEffect(() => {
        (async () => {
            try {
                const response = await API.get('/opportunities-list');
                setOpportunitiesConfig(response.data.data);
            } catch (err) {
                console.error(err);
            }
        })();
    }, []);

    /**
     * Create opportunities config names for dropdown
     */
    useEffect(() => {
        if (!opportunitiesConfig.length) return;
        setOpportunitiesName(opportunitiesConfig?.map((m) => m.name));
    }, [opportunitiesConfig]);

    const formatSubOpportunity = (subOpportunity) => {
        return {
            ...subOpportunity,
            opportunityResponsible: setDropdowns(subOpportunity.mileStoneResponsible, [
                ...normalUsers,
                ...affiliateUsers,
            ]),
            estimatedStartDate: null,
            estimatedEndDate: null,
            children: subOpportunity.children.map((c) => ({
                ...c,
                opportunityResponsible: setDropdowns(c.mileStoneResponsible, [...normalUsers, ...affiliateUsers]),
                estimatedStartDate: null,
                priority: 1,
                estimatedEndDate: null,
            })),
        };
    };

    useEffect(() => {
        (async () => {
            if (selectedOpportunity === null || !opportunitiesConfig.length) return;

            try {
                const response = await API.get(`/mileStone/${opportunitiesConfig[selectedOpportunity].id}`);
                const opportunityInf = response.data.data;

                // Let's format our api response
                const opportunity = opportunityInf?.mileStoneTask?.map((m) => formatSubOpportunity(m));
                setTitleOpportunity(opportunityInf?.name);
                setDescriptionOpportunity(htmlToDraftBlocks(opportunityInf?.description));
                setResponsible(setDropdowns(opportunityInf?.responsible, [...normalUsers, ...affiliateUsers]));
                setWatcher(setDropdowns(opportunityInf?.watcher, investorsUsers));
                setSupervisor(setDropdowns(opportunityInf?.accepter, normalUsers));
                setActive(opportunityInf?.isActive);
                setOpportunityInfo(opportunityInf);

                setOpportunities(calculateDuration(opportunity));
            } catch (err) {
                console.error(err);
            }
        })();
    }, [selectedOpportunity, opportunitiesConfig]);

    // UseCallback-ul face ca opportunityResponsible sa fie null :) E reparat
    const defaultOpportunity = () => {
        return {
            id: Math.random(),
            name: '',
            estimatedDays: 0,
            estimatedStartDate: null,
            estimatedEndDate: null,
            opportunityResponsible: [],
            children: [],
        };
    };

    const addOpportunity = () => {
        const newOpportunities = [...opportunities, defaultOpportunity()];
        setOpportunities(calculateDuration(newOpportunities));
    };

    const deleteOpportunity = (id) => {
        const newOpportunities = opportunities.filter((m) => m.id !== id);
        setOpportunities(newOpportunities.length > 0 ? calculateDuration(newOpportunities) : []);
    };

    const updateOpportunity = (id, key, value) => {
        const opportunityIndex = opportunities.findIndex((m) => m.id === id);
        const newOpportunities = [...opportunities];
        newOpportunities[opportunityIndex][key] = value;
        setOpportunities(calculateDuration(newOpportunities));
    };

    /**
     * @param {*} usersList - Is the state of the every dropdown with users - Array of numbers, returned by dropdown
     * @returns An array of users ids
     */
    const getUsersIds = (usersList, users) => {
        return usersList.map((user) => users[user].id);
    };

    /**
     * Function take the info about user from an dropdown and transform data into an array on indexes for the dropdown to be set on edit
     * @param {*} usersId is an array of objects from the opportunity data
     * @returns  An array of index for the dropdowns
     */
    const setDropdowns = (usersId, users) => {
        if (users.length) {
            return usersId
                ?.map((user) => user.userId)
                .reduce((acc, curr) => {
                    const index = users.findIndex((user) => user.id === curr);
                    return index >= 0 ? [...acc, index] : acc;
                }, []);
        }
        return [];
    };

    /**
     * [ARCADIE]
     * We gon keep a opportunity date in the state, which is actually the date of the first opportunity
     */

    const calculateDuration = (opportunities, date = null) => {
        if (opportunities.length > 0) {
            const newSubOpportunities = [];
            let daysPassed = 0;
            const secondsPerDay = 1000 * 60 * 60 * 24;

            for (const [i, subOpportunity] of opportunities.entries()) {
                const ns = { ...subOpportunity };
                ns.children = [];
                let newStartDate = new Date(date ?? opportunityStartDate).getTime();

                // Calculate subOpportunity start date
                if (i > 0) {
                    newStartDate += daysPassed * secondsPerDay;
                }

                // Calculate estimated days and end date (they depend on tasks, so we map on them)
                let allTasksDays = 0;

                for (const task of subOpportunity.children) {
                    const newTask = { ...task };
                    // Task start date
                    const startOpportunities = newStartDate + allTasksDays * secondsPerDay;
                    newTask.estimatedStartDate = new Date(startOpportunities);

                    // Task end date
                    newTask.estimatedEndDate = new Date(startOpportunities + newTask.estimatedDays * secondsPerDay);

                    allTasksDays += newTask.estimatedDays;

                    ns.children.push(newTask);
                }

                ns.estimatedStartDate = new Date(newStartDate);
                ns.estimatedDays = allTasksDays;
                ns.estimatedEndDate = newStartDate + allTasksDays * secondsPerDay;

                // Update days passed
                daysPassed += Number(ns.estimatedDays);

                newSubOpportunities.push(ns);
            }

            return newSubOpportunities;
        } else setOpportunities([]);
    };

    let schemaOpportunity = yup.object().shape({
        // descriptionOpportunity: yup
        //     .object()
        //     .typeError(t('The description is required!'))
        //     .required(t('The description is required!')),
        responsible: yup
            .array()
            .typeError(t('Choosing at least one Opportunity responsible is mandatory!'))
            .min(1, t('Choosing at least one Opportunity responsible is mandatory!'))
            .required(t('Choosing at least one Opportunity responsible is mandatory!')),
        supervisor: yup
            .array()
            .typeError(t('Choosing at least one Opportunity supervisor is mandatory!'))
            .min(1, t('Choosing at least one Opportunity supervisor is mandatory!'))
            .required(t('Choosing at least one Opportunity supervisor is mandatory!')),
        watcher: yup
            .array()
            .typeError(t('Choosing at least one Opportunity watcher/beneficiary is mandatory!'))
            .min(1, t('Choosing at least one Opportunity watcher/beneficiary is mandatory!'))
            .required(t('Choosing at least one Opportunity watcher/beneficiary is mandatory!')),
        titleOpportunity: yup
            .string()
            .typeError(t('The name of the Opportunity is mandatory!'))
            .required(t('The name of the Opportunity is mandatory!')),
        opportunity: yup
            .array()
            .of(
                yup.object().shape({
                    estimatedDays: yup
                        .number()
                        .typeError(
                            t('The Sub-Opportunity must have allocated the required number of days for completion!'),
                        )
                        .required(
                            t('The Sub-Opportunity must have allocated the required number of days for completion!'),
                        ),
                    name: yup
                        .string()
                        .typeError(t('Sub-Opportunity name is mandatory!'))
                        .required(t('Sub-Opportunity name is mandatory!')),
                    opportunityResponsible: yup
                        .array()
                        .of(
                            yup
                                .number()
                                .typeError(t('Choosing at least one Sub-Opportunity responsible is mandatory!')),
                        )
                        .min(1, t('Choosing at least one Sub-Opportunity responsible is mandatory!'))
                        .required(t('Choosing at least one Sub-Opportunity responsible is mandatory!')),
                    children: yup
                        .array()
                        .of(
                            yup.object().shape({
                                estimatedDays: yup
                                    .number()
                                    .typeError(
                                        t('The Task must have allocated the required number of days for completion!'),
                                    )
                                    .required(
                                        t('The Task must have allocated the required number of days for completion!'),
                                    ),
                                name: yup
                                    .string()
                                    .typeError(t('Task name is mandatory!'))
                                    .required(t('Task name is mandatory!')),
                                priority: yup
                                    .number()
                                    .typeError(t('Priority for the task is required!'))
                                    .required(t('Priority for the task is required!')),
                                opportunityResponsible: yup
                                    .array()
                                    .of(
                                        yup
                                            .number()
                                            .typeError(t('Choosing at least one Task responsible is mandatory!')),
                                    )
                                    .min(1, t('Choosing at least one Task responsible is mandatory!'))
                                    .required(t('Choosing at least one Task responsible is mandatory!')),
                            }),
                        )
                        .min(1, t('Sub-Opportunity must contain at least one task')),
                }),
            )
            .min(1, t('The Opportunity must have at least one Sub - Opportunity!')),
    });

    const createPMTaskManagementOpportunity = async () => {
        if (taskManagementContext.isCreating) return;

        try {
            await schemaOpportunity.validate({
                titleOpportunity,
                responsible,
                // commentMilestone,
                supervisor,
                watcher,
                opportunity: opportunities.map((m) => m),
            });

            try {
                const formData = new FormData();
                const htmlContent = DOMPurify.sanitize(
                    draftToHtml(convertToRaw(descriptionOpportunity.getCurrentContent())).replaceAll(
                        'color: currentcolor;',
                        '',
                    ),
                );

                const reqBody = {
                    milestoneId: opportunityParentId ?? undefined,
                    title: titleOpportunity,
                    description: htmlContent,
                    note: '-',
                    actionType: 'TASK',
                    status: 'TODO',
                    ownerId: user.id,
                    responsible: getUsersIds(responsible, [...normalUsers, ...affiliateUsers]),
                    watcher: getUsersIds(watcher, investorsUsers),
                    supervisor: getUsersIds(supervisor, normalUsers),
                    partnerId: partnerId ? partnerId : undefined,
                    pmDepartmentId: departmentId,
                    pmProjectPlanId: projectId,
                    pipelineType: 'LEADS_TO_POTENTIAL',
                    tasks: opportunities?.map((m) => ({
                        mileStoneTaskId: typeof m.id === 'string' ? m.id : undefined,
                        name: m.name,
                        estimatedStartDate: m.estimatedStartDate,
                        estimatedEndDate: m.estimatedEndDate,
                        responsible: getUsersIds(m.opportunityResponsible, [...normalUsers, ...affiliateUsers]),
                        subTasks: m?.children?.map((c) => ({
                            mileStoneTaskId: typeof c.id === 'string' ? c.id : undefined,
                            name: c.name,
                            priority:
                                c.priority === 0 ? 'LOW' : c.priority === 1 ? 'MEDIUM' : c.priority === 2 ? 'HIGH' : '',
                            estimatedStartDate: c.estimatedStartDate,
                            estimatedEndDate: c.estimatedEndDate,
                            responsible: getUsersIds(c.opportunityResponsible, [...normalUsers, ...affiliateUsers]),
                        })),
                    })),
                };

                formData.append('data', JSON.stringify(reqBody));
                taskManagementContext.submitTaskManagementActivity(formData);
            } catch (err) {
                console.error(err);
            }
        } catch (err) {
            if (err.errors) {
                enqueueSnackbar(err.errors[0], { variant: 'error' });
            }
        }
    };

    return (
        <form>
            <div className="mb-5">
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={checkboxOpportunities}
                            onChange={() => setCheckboxOpportunities(!checkboxOpportunities)}
                            name="email"
                            style={{
                                color: `rgb(${theme['UI-COLORS']['MAIN-PRIMARY'].color})`,
                            }}
                        />
                    }
                    label={<p className="user-select-none">{t('Pick a preconfigured opportunity')}</p>}
                />
            </div>

            {checkboxOpportunities === true && (
                <div className="relative z-50 mb-5">
                    <Dropdown
                        options={opportunitiesNames}
                        selectedOption={selectedOpportunity}
                        setSelectedOption={setSelectedOpportunity}
                        placeholder={t('Add from Opportunities')}
                        variant="black"
                    />
                    <p className="mt-2 text-disabled">
                        {t('* If you choose a template, all the fields below will be preconfigured.')}
                    </p>
                </div>
            )}

            <div className="mb-10">
                <TextField
                    placeholder={t('Opportunity name')}
                    value={titleOpportunity}
                    onChange={(e) => setTitleOpportunity(e.target.value)}
                />
            </div>

            <div className="mb-10">
                <LabelWrapper label={t('Add description for the Opportunity')}>
                    <Editor
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        editorState={descriptionOpportunity}
                        onEditorStateChange={setDescriptionOpportunity}
                        toolbarStyle={{
                            margin: 0,
                            padding: 0,
                            borderRadius: 0,
                            color: 'black',
                            border: 'none',
                            backgroundColor: 'inherit',
                        }}
                        wrapperStyle={{
                            width: '100%',
                        }}
                        editorStyle={{
                            borderRadius: 0,
                            backgroundColor: 'white',
                            padding: '0px 8px',
                            color: 'black',
                            minHeight: '20rem',
                            maxHeight: width <= 750 ? '' : '40rem',
                            lineHeight: 1.2,
                        }}
                        toolbar={{
                            options: [
                                'inline',
                                'blockType',
                                'fontSize',
                                'list',
                                'textAlign',
                                'colorPicker',
                                'link',
                                'remove',
                                'history',
                            ],
                        }}
                    />
                </LabelWrapper>
            </div>

            {/* SubOpportunities */}
            <div className="relative z-40 mb-10">
                {opportunities?.length > 0 && (
                    <div>
                        {opportunities.map((subOpportunity, index) => (
                            <div
                                key={subOpportunity.id}
                                className="relative"
                                style={{
                                    position: 'relative',
                                    zIndex: opportunities.length - index + 1,
                                }}
                            >
                                <SubOpportunityActivity
                                    onDelete={deleteOpportunity}
                                    onUpdate={updateOpportunity}
                                    opportunity={subOpportunity}
                                    allUsers={[...normalUsers, ...affiliateUsers].map((user) => user.profile?.name)}
                                    letter={String.fromCharCode(index + 65)}
                                />
                            </div>
                        ))}
                    </div>
                )}
                <Button
                    startIcon={<AddIcon />}
                    fullWidth
                    onClick={() => {
                        if (opportunities === undefined) {
                            setOpportunities([]);
                            addOpportunity();
                        } else {
                            addOpportunity();
                        }
                    }}
                    color="primary"
                >
                    {t('Add Sub-Opportunity')}
                </Button>
            </div>

            {/* 
                        Bottom dropdowns
                    */}
            <div className="relative z-30 mb-4 flex gap-4">
                <LabelWrapper label={t('From')}>
                    <DatePicker
                        date={opportunityStartDate}
                        setDate={(e) => {
                            setOpportunityStartDate(e);
                            setOpportunities((prev) => calculateDuration(prev, e));
                        }}
                        variant="black"
                    />
                </LabelWrapper>
                <LabelWrapper label={t('Up to')}>
                    <DatePicker
                        disabled
                        date={opportunities?.length > 0 && opportunities[opportunities.length - 1].estimatedEndDate}
                        variant="black"
                    />
                </LabelWrapper>
            </div>

            <div className="mb-10 flex gap-4">
                <LabelWrapper label={t('Responsible')}>
                    <MultiDropdown
                        variant="black"
                        options={[...normalUsers, ...affiliateUsers].map((user) => user.profile?.name)}
                        placeholder={t('Choose user')}
                        selectedOptions={responsible}
                        setSelectedOptions={(i) => {
                            if (responsible?.indexOf(i) > -1) {
                                setResponsible(responsible.filter((opt) => opt !== i));
                            } else {
                                setResponsible([...responsible, i]);
                            }
                        }}
                    />
                </LabelWrapper>

                <LabelWrapper label={t('Supervisor')}>
                    <MultiDropdown
                        variant="black"
                        options={normalUsers.map((user) => user.profile?.name)}
                        placeholder={t('Choose user')}
                        selectedOptions={supervisor}
                        setSelectedOptions={(i) => {
                            if (supervisor?.indexOf(i) > -1) {
                                setSupervisor(supervisor.filter((opt) => opt !== i));
                            } else {
                                setSupervisor([...supervisor, i]);
                            }
                        }}
                    />
                </LabelWrapper>

                <LabelWrapper label={t('Watcher / Beneficiary')}>
                    <MultiDropdown
                        variant="black"
                        options={investorsUsers.map((user) => user.profile?.name)}
                        placeholder={t('Choose user')}
                        selectedOptions={watcher}
                        setSelectedOptions={(i) => {
                            if (watcher?.indexOf(i) > -1) {
                                setWatcher(watcher.filter((opt) => opt !== i));
                            } else {
                                setWatcher([...watcher, i]);
                            }
                        }}
                    />
                </LabelWrapper>
            </div>

            <div className="flex items-center gap-2">
                <Button
                    color="secondary"
                    startIcon={<AddIcon />}
                    onClick={() => {
                        if (opportunityInfo) {
                            const myOpportunity = {
                                name: titleOpportunity,
                                description: descriptionOpportunity,
                                mileStoneTask: [],
                            };

                            const confOpportunity = {
                                name: opportunityInfo.name,
                                description: opportunityInfo.description,
                                mileStoneTask: [],
                            };

                            /**
                             * Construim in functie de oportunitatea noastra
                             */
                            for (const subOpportunity of opportunities) {
                                const newSubOpportunity = {};
                                newSubOpportunity.name = subOpportunity.name;
                                newSubOpportunity.children = [];

                                for (const task of subOpportunity.children) {
                                    newSubOpportunity.children.push({ name: task.name });
                                }

                                myOpportunity.mileStoneTask.push(newSubOpportunity);
                            }

                            /**
                             * Construim in functie de oportunitatea din configurator
                             */

                            for (const subOpportunity of opportunityInfo.mileStoneTask) {
                                const newSubOpportunity = {};
                                newSubOpportunity.name = subOpportunity.name;
                                newSubOpportunity.children = [];

                                for (const task of subOpportunity.children) {
                                    newSubOpportunity.children.push({ name: task.name });
                                }

                                confOpportunity.mileStoneTask.push(newSubOpportunity);
                            }

                            if (JSON.stringify(confOpportunity) === JSON.stringify(myOpportunity)) {
                                setOpportunityParentId(opportunityInfo.id);
                            }
                        }

                        createPMTaskManagementOpportunity();
                    }}
                >
                    {t('Add opportunity')}
                </Button>

                <Button color="secondary" onClick={() => closeModal()}>
                    {t('Cancel')}
                </Button>
            </div>
        </form>
    );
};

PMTaskManagementOpportunities.propTypes = {
    departmentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    partnerId: PropTypes.string,
    tenantUsers: PropTypes.arrayOf(PropTypes.object),
    closeModal: PropTypes.func,
    beneficiaryCheck: PropTypes.bool,
};

PMTaskManagementOpportunities.defaultProps = {
    departmentId: null,
    partnerId: null,
    tenantUsers: [],
    closeModal: () => null,
    beneficiaryCheck: false,
};

export default PMTaskManagementOpportunities;
