import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import GetAppIcon from '@material-ui/icons/GetApp';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import NoteAddIcon from '@material-ui/icons/NoteAdd';

import M2MLogo from '../../assets/common/m2m-logo.png';
import RaisisLogo from '../../assets/common/raisis-logo.png';

import { Button, CircularProgress } from '@material-ui/core';

import CustomModal from 'components/modals/custom_modal';
import GlobalContext from 'contexts/GlobalContext';
import UserContext from 'contexts/UserContext';
import ExcelJS from 'exceljs';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { Header } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { displayNumber, errorHandling, formatDate, formatExchangeRate, getRawImageFromPath, rgbToHex } from 'utils';
import API from 'utils/axios';
import exportImages from 'utils/exportImages';
import exportImagesToPDF from 'utils/exportImagesToPDF';
import { getCompanyLogoAndIcon } from 'utils/getterFunctions';

import CashflowTable from './cashflow-table';
import ModalAddCashData from './modal-add-periods-data';
import ModalExportAddAlias from './modal-export-add-alias';
import PLTable from './pl-table';

const TYPES = ['pl', 'pl_pm', 'pl_project', 'cashflow', 'cashflow_pm', 'cashflow_project'];

const FinancialTools = ({ type }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const { projectId } = useParams();
    const { checkPerm } = useContext(UserContext);
    const { currencyObj, referenceCurrencyObj, language, theme, tenant } = useContext(GlobalContext);
    const [excelData, setExcelData] = useState({});
    const [loading, setLoading] = useState(false);
    const [loadingOptions, setLoadingOptions] = useState(true);
    const [loadingImage, setLoadingImage] = useState(false);
    const [loadingPDF, setLoadingPDF] = useState(false);
    const [loadingExcel, setLoadingExcel] = useState(false);

    const [openModal, setOpenModal] = useState(true);
    const [openExportModal, setOpenExportModal] = useState(false);
    const [openDownloadExcelModal, setOpenDownloadExcelModal] = useState(false);
    const [openDownloadImageModal, setOpenDownloadImageModal] = useState(false);
    const [headersData, setHeadersData] = useState([]);
    const [pageTitle, setPageTitle] = useState('');
    const [companyName, setCompanyName] = useState(null);
    const [companyLogo, setCompanyLogo] = useState(null);
    const [exchangeRateDate, setExchangeRateDate] = useState(null);
    const [exchangeRate, setExchangeRate] = useState(1);
    const [loadingExchangeRate, setLoadingExchangeRate] = useState(false);

    const allDetailsTypes = ['analytical1', 'analytical2', 'synthetic'];
    const defaultDetailsTypes = ['analytical', 'synthetic'];

    const [selectedDetailsType, setSelectedDetailsType] = useState(null);

    const types = ['GLOBAL', 'COMPANY', 'PROJECTS'];
    const [contracts, setContracts] = useState([]);
    const [pmContracts, setPmContracts] = useState([]);
    const [projects, setProjects] = useState([]);
    const [projectStructures, setProjectStructures] = useState([]);
    const [selectedData, setSelectedData] = useState(null);
    const [selectedCrmType, setSelectedCrmType] = useState(0);

    const [useInvoiceExchangeRate, setUseInvoiceExchangeRate] = useState(false);

    // ? We create the case (project -> only one PM project, project management -> all PM projects or CRM -> all of the crm content excluding the PM) based on the route we are on
    const useCase =
        type === 'pl_project' || type === 'cashflow_project'
            ? 'projectCase'
            : type === 'pl_pm' || type === 'cashflow_pm'
              ? 'pmCase'
              : type === 'pl' || type === 'cashflow'
                ? 'crmCase'
                : null;

    const canViewPM = checkPerm([
        {
            permissionId: '19',
            permissionType: 'VIEW',
        },
    ]);

    const canViewPL = checkPerm([
        {
            permissionId: '40',
            permissionType: 'VIEW',
        },
    ]);

    const canViewCashflow = checkPerm([
        {
            permissionId: '41',
            permissionType: 'VIEW',
        },
    ]);

    /**
     * The function `getExcelData` is an asynchronous function that is used to retrieve the data about the P&L or Cashflow.
     * @param {Array} dates - An array with dates and names, where the dates represent the periods between which we get the data about the P&L or Cashflow
     */
    const getExcelData = async (dates) => {
        setLoading(true);
        setOpenModal(false);
        setHeadersData(dates);

        /**
         * ? This function is used to get the data about the P&L
         */
        const getDataPL = async () =>
            await API.post(
                `getCompanyPL`,
                {
                    dates: dates,
                    exchangeRate: {
                        exchangeRateValue: exchangeRate,
                        exchangeRateDate: exchangeRateDate,
                    },
                    // ? We only use the the default types in the case of a project or a contract on the CRM
                    type:
                        useCase === 'projectCase' || (useCase === 'crmCase' && selectedCrmType === 1)
                            ? defaultDetailsTypes[selectedDetailsType]
                            : allDetailsTypes[selectedDetailsType],
                },
                {
                    params: {
                        invoiceExchange: useInvoiceExchangeRate,
                        type: selectedCrmType === 0 && useCase === 'crmCase' ? types[selectedData] : undefined,
                        contractId:
                            selectedCrmType === 1 && useCase === 'crmCase' ? contracts[selectedData].id : undefined,
                        contractPMId: useCase === 'projectCase' ? pmContracts[selectedData].id : undefined,
                        pmProjectOverviewPlanId: useCase === 'pmCase' ? projects[selectedData].id : undefined,
                        projectStructureId:
                            selectedCrmType === 2 && useCase === 'crmCase'
                                ? projectStructures[selectedData].id
                                : undefined,
                    },
                },
            );

        /**
         * ? This function is used to get the data about the Cashflow
         */
        const getDataCashflow = async () =>
            await API.post(
                `projectCashflow`,
                {
                    dates: dates,
                    exchangeRate: {
                        exchangeRateValue: exchangeRate,
                        exchangeRateDate: exchangeRateDate,
                    },
                    // ? We only use the the default types in the case of a project or a contract on the CRM
                    type:
                        useCase === 'projectCase' || (useCase === 'crmCase' && selectedCrmType === 1)
                            ? defaultDetailsTypes[selectedDetailsType]
                            : allDetailsTypes[selectedDetailsType],
                },
                {
                    params: {
                        invoiceExchange: useInvoiceExchangeRate,
                        type: selectedCrmType === 0 && useCase === 'crmCase' ? types[selectedData] : undefined,
                        contractId:
                            selectedCrmType === 1 && useCase === 'crmCase' ? contracts[selectedData].id : undefined,
                        contractPMId: useCase === 'projectCase' ? pmContracts[selectedData].id : undefined,
                        pmProjectOverviewPlanId: useCase === 'pmCase' ? projects[selectedData].id : undefined,
                        projectStructureId:
                            selectedCrmType === 2 && useCase === 'crmCase'
                                ? projectStructures[selectedData].id
                                : undefined,
                    },
                },
            );

        try {
            let res = {};

            if (type.startsWith('pl')) res = await getDataPL();
            if (type.startsWith('cashflow')) res = await getDataCashflow();

            setExcelData(res.data);

            // ? We generate the correct page title and a default file name and alias used for the export
            generateDynamicDisplay();
        } catch (error) {
            console.error(error);
            enqueueSnackbar(errorHandling(error), { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    /**
     * The function `setCompanyDetails` fetches the company logo, sets the company name and logo in the
     * state, and updates the page title.
     */
    const setCompanyDetails = async () => {
        const { logoUrl } = await getCompanyLogoAndIcon();

        // ? We have to fetch the file in order to be able to avoid a crash related to the element not being an image, but a link when we try to export the table
        const logoBase64 = await getRawImageFromPath(logoUrl);

        setCompanyName(tenant.companyName);
        setCompanyLogo(logoBase64);

        const typeName = displayTypeName();
        setPageTitle(t("{{name}}'s {{typeName}}", { name, typeName }));
    };

    const getAllContracts = async () => {
        const res = await API.get('/contracts', {
            params: {
                currentPage: 0,
                perPage: 99999,
                pagesToLoad: 1,
            },
        });

        setContracts(res.data.contracts);
    };

    const getAllPmContracts = async () => {
        const res = await API.get('project_contracts_pm', {
            params: {
                currentPage: 0,
                perPage: 99999,
                pagesToLoad: 1,
                projectId: projectId,
            },
        });

        setPmContracts(res.data.contracts);
    };

    const getAllPmProjects = async () => {
        try {
            const response = await API.get('/PmProjects', {
                params: {
                    perPage: 99999,
                    currentPage: 0,
                    pagesToLoad: 1,
                },
            });

            setProjects(response.data.PmProjects.content);
        } catch (err) {
            console.error(err);
        }
    };

    const getAllProjectStructures = async () => {
        try {
            const resProjectStructures = await API.get('projectStructures', {
                params: {
                    perPage: 999999,
                    currentPage: 0,
                    pagesToLoad: 1,
                },
            });

            setProjectStructures(resProjectStructures.data.data.data);
        } catch (error) {
            throw new Error(error);
        }
    };

    /**
     * The function `checkPermission` checks if a user has permission to view certain data based on
     * their access rights.
     * @returns {boolean} The function `checkPermission` returns a boolean value. It returns `true` if all the
     * conditions are met, and `false` otherwise.
     */
    const checkPermission = () => {
        if (!canViewPM && (useCase === 'pmCase' || useCase === 'projectCase')) return false;
        if (!canViewPL && type === 'pl') return false;
        if (!canViewCashflow && type === 'cashflow') return false;

        return true;
    };

    /**
     * The function `getExchangeRate` retrieves exchange rates for a specific date and handles error
     * and loading states.
     * @param {Date} e - The date to be used to retrieve the exchange rate
     */
    const getExchangeRate = async (e) => {
        try {
            let selectedDate = new Date(e);
            if (selectedDate > new Date()) {
                enqueueSnackbar(t("The selected date can't be greater then the current date!"), {
                    variant: 'error',
                });
                selectedDate = new Date();
            }

            setExchangeRateDate(e);
            setLoadingExchangeRate(true);

            const res = await API.get('currencyByDate', {
                params: {
                    date: new Date(e),
                },
            });

            const { rates } = res.data.data;

            setExchangeRate(rates);
        } catch (error) {
            console.error(error);
        } finally {
            setLoadingExchangeRate(false);
        }
    };

    const handleUpdateExchangeRate = (e) => {
        const value = formatExchangeRate(e.target.value);

        setExchangeRate(value);
    };

    useEffect(() => {
        // ? If the user does't have the permission we push him back to the previous oages
        if (!checkPermission() || !TYPES.includes(type)) history.push('/');

        (async () => {
            try {
                // ? We get the type of the page (P&L or Cashflow)
                const typeName = displayTypeName();

                if (useCase === 'crmCase') {
                    await getAllContracts();
                    await getAllProjectStructures();
                }
                if (useCase === 'projectCase') {
                    await getAllPmContracts();
                    setPageTitle(`${typeName} Contract`);
                }
                if (useCase === 'pmCase') {
                    await getAllPmProjects();
                    setPageTitle(t(`{{typeName}} Project`, { typeName }));
                }

                await Promise.all([getExchangeRate(new Date()), setCompanyDetails()]);
            } catch (error) {
                console.error(error);
            } finally {
                setLoadingOptions(false);
            }
        })();
    }, []);

    // ? Styles for the header of the table
    const headerStyes = {
        backgroundColor: rgbToHex(...theme['PDF-COLORS']['PDF-LAYOUT-BACKGROUND'].color.split(' ')),
        height: '90px',
    };

    // ? Styles for the cells in the rows of the table
    const headerCellStyles = {
        color: rgbToHex(...theme['PDF-COLORS']['PDF-LAYOUT-TEXT'].color.split(' ')),
        padding: '0 1rem',
        textAlign: 'left',
        fontWeight: 500,
    };

    /**
     * The `handleDownloadExcel` function is an asynchronous function that generates and downloads an
     * Excel file based on the provided data and parameters.
     * @param {String} alias - Used as a name for the excel sheet
     * @param {String} fileName - Used as a name for the file of the export
     * @param {Boolean} canRenderNational - Determines if the data related to the national currency should be added into the export
     * @param {Boolean} canRenderReference - Determines if the data related to the reference (secondary) currency should be added into the export
     */
    const handleDownloadExcel = async (alias, fileName, canRenderNational, canRenderReference) => {
        try {
            setLoadingExcel(true);

            const copyOfTheExcelData = structuredClone(excelData.excel);
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet(alias);

            // ? We create the content of the excel for P&L case
            if (type.startsWith('pl')) {
                // ? We create the headers content
                worksheet.columns = [
                    {
                        header: '',
                        key: 'logo',
                        width: 15,
                    },
                    { header: t('Accounting Account').toUpperCase(), key: 'accounting_account', width: 25 },
                    {
                        header: t('Summary Profit and loss account').toUpperCase(),
                        key: 'summary_profit_loss_account',
                        width: 35,
                    },

                    ...headersData.flatMap((row) => {
                        const arr = [];

                        if (canRenderNational)
                            arr.push({
                                header: `${t('Forecasted').toUpperCase()} ${row.name}\n${formatDate(
                                    row.startDate,
                                )} - ${formatDate(row.endDate)}\n${currencyObj.currency}`,
                                key: `forecasted_${row.name}_primary`,
                                width: 25,
                            });

                        if (canRenderReference)
                            arr.push({
                                header: `${t('Forecasted').toUpperCase()} ${row.name}\n${formatDate(
                                    row.startDate,
                                )} - ${formatDate(row.endDate)}\n${referenceCurrencyObj.currency}`,
                                key: `forecasted_${row.name}_secondary`,
                                width: 25,
                            });

                        if (canRenderNational)
                            arr.push({
                                header: `${t('Achieved').toUpperCase()} ${row.name}\n${formatDate(
                                    row.startDate,
                                )} - ${formatDate(row.endDate)}\n${currencyObj.currency}`,
                                key: `achieved_${row.name}_primary`,
                                width: 25,
                            });

                        if (canRenderReference)
                            arr.push({
                                header: `${t('Achieved').toUpperCase()} ${row.name}\n${formatDate(
                                    row.startDate,
                                )} - ${formatDate(row.endDate)}\n${referenceCurrencyObj.currency}`,
                                key: `achieved_${row.name}_secondary`,
                                width: 25,
                            });

                        return arr;
                    }),
                    ...(() => {
                        if (headersData.length === 1) return [];

                        const arr = [];

                        if (canRenderNational)
                            arr.push({
                                header: `${t(`Total Forecasted`).toUpperCase()}\n${currencyObj.currency}`,
                                key: 'total_forecasted_primary',
                                width: 15,
                            });

                        if (canRenderReference)
                            arr.push({
                                header: `${t(`Total Forecasted`).toUpperCase()}\n${referenceCurrencyObj.currency}`,
                                key: 'total_forecasted_secondary',
                                width: 15,
                            });

                        if (canRenderNational)
                            arr.push({
                                header: `${t(`Total Achieved`).toUpperCase()}\n${currencyObj.currency}`,
                                key: 'total_achieved_primary',
                                width: 15,
                            });

                        if (canRenderReference)
                            arr.push({
                                header: `${t(`Total Achieved`).toUpperCase()}\n${referenceCurrencyObj.currency}`,
                                key: 'total_achieved_secondary',
                                width: 15,
                            });

                        return arr;
                    })(),
                    { header: t('Forecasted vs Achieved').toUpperCase(), key: 'achieved_vs_forecasted', width: 25 },
                    canRenderReference && {
                        header: `${t('Exchange rate').toUpperCase()}\n${formatDate(exchangeRateDate)}\n${displayNumber(
                            exchangeRate,
                            language,
                            4,
                            4,
                        )} - ${currencyObj.currency}/${referenceCurrencyObj.currency}`,
                        key: 'exchange_rate',
                        width: 25,
                    },
                ];

                // ? We create the excel rows
                copyOfTheExcelData.forEach((xlsx, index) => {
                    const keys = headersData.map((header) => header.name);

                    // ? We add a new row
                    worksheet.addRow({
                        logo: '',
                        accounting_account: xlsx.cont_Contabil,
                        summary_profit_loss_account: xlsx.name,

                        ...keys
                            .map((keyName) => {
                                const obj = {};

                                if (canRenderNational) {
                                    obj[`forecasted_${keyName}_primary`] = displayNumber(
                                        xlsx[`forecasted_${keyName}`],
                                        language,
                                    );
                                    obj[`achieved_${keyName}_primary`] = displayNumber(xlsx[keyName], language);
                                }

                                if (canRenderReference) {
                                    obj[`forecasted_${keyName}_secondary`] = displayNumber(
                                        xlsx[`forecasted_${keyName}_secondValue`],
                                        language,
                                    );
                                    obj[`achieved_${keyName}_secondary`] = displayNumber(
                                        xlsx[`${keyName}_secondValue`],
                                        language,
                                    );
                                }

                                return obj;
                            })
                            .reduce((acc, val) => ({ ...acc, ...val }), {}),
                        ...(() => {
                            if (headersData.length === 1) return {};

                            const obj = {};

                            if (canRenderNational) {
                                obj.total_forecasted_primary = displayNumber(xlsx.total ?? 0, language, 2, 2);
                                obj.total_achieved_primary = displayNumber(xlsx.total_forecasted ?? 0, language, 2, 2);
                            }

                            if (canRenderReference) {
                                obj.total_forecasted_secondary = displayNumber(
                                    xlsx.total_secondValue ?? 0,
                                    language,
                                    2,
                                    2,
                                );
                                obj.total_achieved_secondary = displayNumber(
                                    xlsx.total_forecasted_secondValue ?? 0,
                                    language,
                                    2,
                                    2,
                                );
                            }

                            return obj;
                        })(),
                        achieved_vs_forecasted: `${displayNumber(xlsx['%'], language)} %`,
                        ...(canRenderReference && { exchange_rate: '' }),
                    });

                    const row = worksheet.getRow(index + 2);

                    // ? We add styles to the new row
                    row.eachCell({ includeEmpty: true }, (cell) => {
                        cell.font = {
                            color: {
                                argb: xlsx.textHex ? `${xlsx.textHex.slice(1, xlsx.textHex.length)}` : '000000',
                            },
                        };

                        cell.fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: {
                                argb: xlsx.hex ? `${xlsx.hex.slice(1, xlsx.hex.length)} ` : 'FFFFFF',
                            },
                        };
                    });
                });
            }

            // ? We create the content of the excel for Cashflow case
            if (type.startsWith('cashflow')) {
                // ? We create the headers content
                worksheet.columns = [
                    {
                        header: '',
                        key: 'logo',
                        width: 15,
                    },
                    // { header: t('Criterion Nr.').toUpperCase(), key: 'criterion_nr', width: 15 },
                    { header: t('Accounting Account').toUpperCase(), key: 'accounting_account', width: 25 },
                    { header: t('Operation/Period').toUpperCase(), key: 'operation_period', width: 25 },

                    ...headersData.flatMap((row) => {
                        const arr = [];

                        if (canRenderNational)
                            arr.push({
                                header: `${t('Forecasted').toUpperCase()} ${row.name}\n${formatDate(
                                    row.startDate,
                                )} - ${formatDate(row.endDate)}\n${currencyObj.currency}`,
                                key: `forecasted_${row.name}_primary`,
                                width: 25,
                            });

                        if (canRenderReference)
                            arr.push({
                                header: `${t('Forecasted').toUpperCase()} ${row.name}\n${formatDate(
                                    row.startDate,
                                )} - ${formatDate(row.endDate)}\n${referenceCurrencyObj.currency}`,
                                key: `forecasted_${row.name}_secondary`,
                                width: 25,
                            });

                        if (canRenderNational)
                            arr.push({
                                header: `${t('Achieved').toUpperCase()} ${row.name}\n${formatDate(
                                    row.startDate,
                                )} - ${formatDate(row.endDate)}\n${currencyObj.currency}`,
                                key: `achieved_${row.name}_primary`,
                                width: 25,
                            });

                        if (canRenderReference)
                            arr.push({
                                header: `${t('Achieved').toUpperCase()} ${row.name}\n${formatDate(
                                    row.startDate,
                                )} - ${formatDate(row.endDate)}\n${referenceCurrencyObj.currency}`,
                                key: `achieved_${row.name}_secondary`,
                                width: 25,
                            });

                        return arr;
                    }),
                    ...(() => {
                        if (headersData.length === 1) return [];

                        const arr = [];

                        if (canRenderNational)
                            arr.push({
                                header: `${t(`Total Forecasted`).toUpperCase()}\n${currencyObj.currency}`,
                                key: 'total_forecasted_primary',
                                width: 15,
                            });

                        if (canRenderReference)
                            arr.push({
                                header: `${t(`Total Forecasted`).toUpperCase()}\n${referenceCurrencyObj.currency}`,
                                key: 'total_forecasted_secondary',
                                width: 15,
                            });

                        if (canRenderNational)
                            arr.push({
                                header: `${t(`Total Achieved`).toUpperCase()}\n${currencyObj.currency}`,
                                key: 'total_achieved_primary',
                                width: 15,
                            });

                        if (canRenderReference)
                            arr.push({
                                header: `${t(`Total Achieved`).toUpperCase()}\n${referenceCurrencyObj.currency}`,
                                key: 'total_achieved_secondary',
                                width: 15,
                            });

                        return arr;
                    })(),
                    { header: t('Forecasted vs Achieved').toUpperCase(), key: 'achieved_vs_forecasted', width: 25 },
                    canRenderReference && {
                        header: `${t('Exchange rate').toUpperCase()}\n${formatDate(exchangeRateDate)}\n${displayNumber(
                            exchangeRate,
                            language,
                            4,
                            4,
                        )} - ${currencyObj.currency}/${referenceCurrencyObj.currency}`,
                        key: 'exchange_rate',
                        width: 25,
                    },
                ];

                // ? We create the excel rows
                copyOfTheExcelData.forEach((xlsx, index) => {
                    const keys = headersData.map((header) => header.name);

                    // ? We add a new row
                    worksheet.addRow({
                        logo: '',
                        // criterion_nr: xlsx.NrCrt,
                        accounting_account: xlsx.Cont_Contabil,
                        operation_period: xlsx.Operatiune_Perioada,

                        ...keys
                            .map((keyName) => {
                                const obj = {};

                                if (canRenderNational) {
                                    obj[`forecasted_${keyName}_primary`] = displayNumber(
                                        xlsx[`forecasted_${keyName}`],
                                        language,
                                    );
                                    obj[`achieved_${keyName}_primary`] = displayNumber(xlsx[keyName], language);
                                }

                                if (canRenderReference) {
                                    obj[`forecasted_${keyName}_secondary`] = displayNumber(
                                        xlsx[`forecasted_${keyName}_secondValue`],
                                        language,
                                    );
                                    obj[`achieved_${keyName}_secondary`] = displayNumber(
                                        xlsx[`${keyName}_secondValue`],
                                        language,
                                    );
                                }

                                return obj;
                            })
                            .reduce((acc, val) => ({ ...acc, ...val }), {}),
                        ...(() => {
                            if (headersData.length === 1) return {};

                            const obj = {};

                            if (canRenderNational) {
                                obj.total_forecasted_primary = displayNumber(xlsx.total ?? 0, language, 2, 2);
                                obj.total_achieved_primary = displayNumber(xlsx.total_forecasted ?? 0, language, 2, 2);
                            }

                            if (canRenderReference) {
                                obj.total_forecasted_secondary = displayNumber(
                                    xlsx.total_secondValue ?? 0,
                                    language,
                                    2,
                                    2,
                                );
                                obj.total_achieved_secondary = displayNumber(
                                    xlsx.total_forecasted_secondValue ?? 0,
                                    language,
                                    2,
                                    2,
                                );
                            }

                            return obj;
                        })(),
                        achieved_vs_forecasted: `${displayNumber(xlsx['%'], language)} %`,
                        ...(canRenderReference && { exchange_rate: '' }),
                    });

                    const row = worksheet.getRow(index + 2);

                    // ? We add styles to the new row
                    row.eachCell({ includeEmpty: true }, (cell) => {
                        cell.font = {
                            color: {
                                argb: xlsx.textHex2 ? `${xlsx.textHex2.slice(1, xlsx.textHex2.length)}` : '000000',
                            },
                        };

                        cell.fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: {
                                argb: xlsx.hex2 ? `${xlsx.hex2.slice(1, xlsx.hex2.length)} ` : 'FFFFFF',
                            },
                        };
                    });
                });
            }

            // ? We add the image to the workbook
            const companyLogoId = workbook.addImage({
                base64: companyLogo,
                extension: 'png',
            });
            // ? We add the image inside a cell of the worksheet
            worksheet.addImage(companyLogoId, 'A1:A1');

            // ? We get the M2M and RaisisSoftware logos and we add them to the workbook
            const imagesExcel = [M2MLogo, RaisisLogo];
            const imagesExcelPromises = imagesExcel.map((imagePath) => getRawImageFromPath(imagePath));
            const [m2mLogoBase64, raisisLogoBase64] = await Promise.all([...imagesExcelPromises]);

            const m2mLogoId = workbook.addImage({
                base64: m2mLogoBase64,
                extension: 'png',
            });

            const raisisLogoId = workbook.addImage({
                base64: raisisLogoBase64,
                extension: 'png',
            });

            // ? We add an empty row at the end in order to insert M2M and RaisisSoftware logos
            worksheet.addRow(Array.from({ length: worksheet.columnCount }, () => ''));

            // ? We add styles for each row
            worksheet.eachRow((row, iRow) => {
                // ? If is the last row we add a height of 50 in order to fit the M2M and RaisisSoftware logos
                if (iRow === worksheet.rowCount) {
                    row.height = 50;
                }

                // ? We add styles for each cell in every row of the export
                row.eachCell({ includeEmpty: true }, (cell, iCell) => {
                    // ? If is the last row we add the necessary styles for cells of the logos
                    if (iRow === worksheet.rowCount) {
                        cell.fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: {
                                argb: `FF${headerStyes.backgroundColor.slice(1, headerStyes.backgroundColor.length)}`,
                            },
                        };

                        cell.alignment = { vertical: 'middle', horizontal: 'center' };
                    }

                    // ? If is the first row we add the necessary styles for the header
                    if (iRow === 1) {
                        cell.font = {
                            color: { argb: `FF${headerCellStyles.color.slice(1, headerCellStyles.color.length)}` },
                        };

                        cell.fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: {
                                argb: `FF${headerStyes.backgroundColor.slice(1, headerStyes.backgroundColor.length)}`,
                            },
                        };

                        // ? If is the first cell where the logo will be, we assign different styles for alignment compared to the other cells
                        if (iCell === 1) cell.alignment = { vertical: 'middle', horizontal: 'center' };
                        else cell.alignment = { vertical: 'justify' };
                    }
                    // ? If the current row is not the last row we only add the proper borders, otherwise we add the footer styles
                    if (iRow !== worksheet.rowCount) {
                        cell.border = {
                            top: { style: 'thin', color: { argb: 'FFD3D3D3' } },
                            left: { style: 'thin', color: { argb: 'FFD3D3D3' } },
                            bottom: { style: 'thin', color: { argb: 'FFD3D3D3' } },
                            right: { style: 'thin', color: { argb: 'FFD3D3D3' } },
                        };
                    } else {
                        cell.border = {
                            top: {
                                style: 'thin',
                                color: {
                                    argb: `FF${headerStyes.backgroundColor.slice(
                                        1,
                                        headerStyes.backgroundColor.length,
                                    )}`,
                                },
                            },
                            left: {
                                style: 'thin',
                                color: {
                                    argb: `FF${headerStyes.backgroundColor.slice(
                                        1,
                                        headerStyes.backgroundColor.length,
                                    )}`,
                                },
                            },
                            bottom: {
                                style: 'thin',
                                color: {
                                    argb: `FF${headerStyes.backgroundColor.slice(
                                        1,
                                        headerStyes.backgroundColor.length,
                                    )}`,
                                },
                            },
                            right: {
                                style: 'thin',
                                color: {
                                    argb: `FF${headerStyes.backgroundColor.slice(
                                        1,
                                        headerStyes.backgroundColor.length,
                                    )}`,
                                },
                            },
                        };
                    }
                });
            });

            // ? We add the logo inside a cell of the worksheet
            worksheet.addImage(m2mLogoId, {
                tl: { col: 0, row: worksheet.rowCount - 1 },
                br: { col: 1, row: worksheet.rowCount },
                hyperlinks: {
                    hyperlink: 'https://www.m2msolutions.ro',
                    tooltip: 'https://www.m2msolutions.ro',
                },
            });

            // ! Inserting a image into the last row of the document will create a new additional row, which i believe is a bug in the library
            // ! For now we will delete the new row, but this might be fixed in the future. When this happens this line will be removed
            worksheet.spliceRows(worksheet.rowCount, 1);

            // ? We add the logo inside a cell of the worksheet
            worksheet.addImage(raisisLogoId, {
                tl: { col: worksheet.columnCount - 1, row: worksheet.rowCount - 1 },
                br: { col: worksheet.columnCount, row: worksheet.rowCount },
                hyperlinks: {
                    hyperlink: 'https://raisissoftware.com',
                    tooltip: 'https://raisissoftware.com',
                },
            });

            // ! Inserting a image into the last row of the document will create a new additional row, which i believe is a bug in the library
            // ! For now we will delete the new row, but this might be fixed in the future. When this happens this line will be removed
            worksheet.spliceRows(worksheet.rowCount, 1);

            // ? We create the URL object of the export and download it
            const buffer = await workbook.xlsx.writeBuffer(`${fileName}.xlsx`);
            const url = window.URL.createObjectURL(new Blob([buffer]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${fileName}.xlsx`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        } catch (error) {
            console.error(error);
        } finally {
            setOpenDownloadExcelModal(false);
            setLoadingExcel(false);
        }
    };

    /**
     * This function exports an image of the P&L or Cashflow table
     * @param {String} _ - Represents the alias parameter witch we don't need here
     * @param {String} fileName - The name of the exported file
     * @param {Boolean} canRenderNational - Determines if the data related to the national currency should be displayed into the export
     * @param {Boolean} canRenderReference - Determines if the data related to the reference (secondary) currency should be displayed into the export
     */
    const handleExportImage = async (_, fileName, canRenderNational, canRenderReference) => {
        try {
            setLoadingImage(true);

            const reportQuery = [
                {
                    ids: [`${type.split('_')[0]}-table`],
                    type: 'report',
                    element: {
                        type: type.split('_')[0],
                    },
                    data: {
                        exchangeRate,
                        exchangeRateDate,
                        headersData,
                        canRenderNational,
                        canRenderReference,
                    },
                    request: {
                        body: {
                            exchangeRate: {
                                exchangeRateValue: exchangeRate,
                                exchangeRateDate: exchangeRateDate,
                            },
                            type:
                                useCase === 'projectCase' || (useCase === 'crmCase' && selectedCrmType === 1)
                                    ? defaultDetailsTypes[selectedDetailsType]
                                    : allDetailsTypes[selectedDetailsType],
                        },
                        params: {
                            invoiceExchange: useInvoiceExchangeRate,
                            type: selectedCrmType === 0 && useCase === 'crmCase' ? types[selectedData] : undefined,
                            contractId:
                                selectedCrmType === 1 && useCase === 'crmCase' ? contracts[selectedData].id : undefined,
                            contractPMId: useCase === 'projectCase' ? pmContracts[selectedData].id : undefined,
                            pmProjectOverviewPlanId: useCase === 'pmCase' ? projects[selectedData].id : undefined,
                            projectStructureId:
                                selectedCrmType === 2 && useCase === 'crmCase'
                                    ? projectStructures[selectedData].id
                                    : undefined,
                        },
                    },
                },
            ];

            // ? We export the rendered component and download it
            await exportImages(reportQuery, language, true, fileName);
        } catch (error) {
            console.error(error);
        } finally {
            setLoadingImage(false);
            setOpenDownloadImageModal(false);
        }
    };

    /**
     * This function exports a PDF of the P&L or Cashflow table
     * @param {String} alias - Represents the title to be displayed on the first page of the PDF
     * @param {String} fileName - The name of the exported file
     * @param {Boolean} canRenderNational - Determines if the data related to the national currency should be displayed into the export
     * @param {Boolean} canRenderReference - Determines if the data related to the reference (secondary) currency should be displayed into the export
     */
    const handleExportImagesAsPDF = async (alias, fileName, canRenderNational, canRenderReference) => {
        try {
            setLoadingPDF(true);

            const reportQuery = [
                {
                    ids: [`${type.split('_')[0]}-table`],
                    type: 'report',
                    element: {
                        type: type.split('_')[0],
                    },
                    data: {
                        exchangeRate,
                        exchangeRateDate,
                        headersData,
                        canRenderNational,
                        canRenderReference,
                    },
                    request: {
                        body: {
                            exchangeRate: {
                                exchangeRateValue: exchangeRate,
                                exchangeRateDate: exchangeRateDate,
                            },
                            type:
                                useCase === 'projectCase' || (useCase === 'crmCase' && selectedCrmType === 1)
                                    ? defaultDetailsTypes[selectedDetailsType]
                                    : allDetailsTypes[selectedDetailsType],
                        },
                        params: {
                            invoiceExchange: useInvoiceExchangeRate,
                            type: selectedCrmType === 0 && useCase === 'crmCase' ? types[selectedData] : undefined,
                            contractId:
                                selectedCrmType === 1 && useCase === 'crmCase' ? contracts[selectedData].id : undefined,
                            contractPMId: useCase === 'projectCase' ? pmContracts[selectedData].id : undefined,
                            pmProjectOverviewPlanId: useCase === 'pmCase' ? projects[selectedData].id : undefined,
                            projectStructureId:
                                selectedCrmType === 2 && useCase === 'crmCase'
                                    ? projectStructures[selectedData].id
                                    : undefined,
                        },
                    },
                },
            ];

            await exportImagesToPDF(
                'landscape',
                language,
                reportQuery,
                [],
                () => setLoadingPDF(false),
                () => setOpenExportModal(false),
                true,
                'multi-page',
                alias,
                fileName,
            );
        } catch (error) {
            console.error(error);
        }
    };

    const displayTypeName = () => {
        if (type.startsWith('pl')) return 'P&L';
        if (type.startsWith('cashflow')) return 'Cash Flow';
    };

    /**
     * The function `generateDynamicDisplay` generates a dynamic display title based on the selected
     * use case, CRM type, and data.
     */
    const generateDynamicDisplay = () => {
        const typeName = displayTypeName();

        // ? If we generate a report based on a contract we have to extract the name of that contract
        const generateDataForContract = () => {
            const contractsArray = useCase === 'crmCase' ? contracts : useCase === 'projectCase' ? pmContracts : [];
            const contractData = contractsArray[selectedData];
            const parsedData =
                typeof contractData.data === 'string' ? JSON.parse(contractData.data) : contractData.data;
            const contractName =
                'standard' in parsedData && 'number' in parsedData['standard']
                    ? parsedData['standard'].number
                    : 'Numar contract inexistent';

            setPageTitle(
                t("{{contractName}}'s {{typeName}} Contract", {
                    contractName,
                    typeName,
                }),
            );
        };

        // ? If we are on the CRM case we will check the type of the export and the selected data in order to display the correct name for the export
        if (useCase === 'crmCase') {
            if (selectedCrmType === 0) {
                if (selectedData === 0) {
                    setPageTitle(t(`{{companyName}}'s Global {{typeName}}`, { companyName, typeName }));
                }
                if (selectedData === 1) {
                    setPageTitle(t("{{companyName}}'s {{typeName}}", { companyName, typeName }));
                }
                if (selectedData === 2) {
                    setPageTitle(t(`{{companyName}}'s Projects {{typeName}}`, { companyName, typeName }));
                }
            }
            if (selectedCrmType === 1) {
                generateDataForContract();
            }

            if (selectedCrmType === 2) {
                const projectStructureName = projectStructures[selectedData].name;
                setPageTitle(
                    t(`{{projectStructureName}}'s {{typeName}} Project Structure`, { projectStructureName, typeName }),
                );
            }
        }
        // ? On the project case we only have to use the the function that generates the data for a contract
        if (useCase === 'projectCase') {
            generateDataForContract();
        }

        // ? In the case of a PM we only extract the name of the project and add it to the title
        if (useCase === 'pmCase') {
            const projectName = projects[selectedData].name;
            setPageTitle(t(`{{projectName}}'s {{typeName}} Project`, { projectName, typeName }));
        }
    };

    // ? If the language changes we need to set the page title with the new translation
    useEffect(() => {
        // ? In the case when we don't have a generated table we only display the default values for the page title
        if (Object.keys(excelData).length === 0) {
            const typeName = displayTypeName();

            if (useCase === 'crmCase') {
                setPageTitle(t("{{companyName}}'s {{typeName}}", { companyName, typeName }));
            }

            if (useCase === 'projectCase') {
                setPageTitle(`${typeName} Contract`);
            }

            if (useCase === 'pmCase') {
                setPageTitle(t(`{{typeName}} Project`, { typeName }));
            }

            return;
        }

        // ? When we have generated a table we will reuse the function that dynamically generates the page title
        generateDynamicDisplay();
    }, [language]);

    return (
        <>
            <Helmet>
                <title>{displayTypeName()}</title>
            </Helmet>
            <Header
                pageTitle={pageTitle}
                action={
                    <div className="flex flex-wrap gap-5">
                        <Button
                            color="secondary"
                            startIcon={<NoteAddIcon />}
                            style={{ borderRadius: '999px' }}
                            onClick={() => {
                                setSelectedCrmType(0);
                                setSelectedData(null);
                                setOpenModal(true);
                            }}
                            disabled={loading}
                        >
                            {t(`Generate ${displayTypeName()}`)}
                        </Button>
                        {Object.keys(excelData).length > 0 && (
                            <Button
                                color="secondary"
                                startIcon={<GetAppIcon />}
                                style={{ borderRadius: '999px' }}
                                onClick={() => setOpenDownloadExcelModal(true)}
                                disabled={loading}
                            >
                                {t('Download Excel file')}
                            </Button>
                        )}
                        {Object.keys(excelData).length > 0 && (
                            <Button
                                color="secondary"
                                startIcon={<ImportExportIcon />}
                                style={{ borderRadius: '999px' }}
                                onClick={() => setOpenDownloadImageModal(true)}
                                disabled={loading}
                            >
                                {t('Export as image')}
                            </Button>
                        )}
                        {Object.keys(excelData).length > 0 && (
                            <Button
                                color="secondary"
                                startIcon={<ImportExportIcon />}
                                style={{ borderRadius: '999px' }}
                                onClick={() => setOpenExportModal(true)}
                                disabled={loading}
                            >
                                {t('Export as PDF')}
                            </Button>
                        )}
                    </div>
                }
            />

            <div className="page-container overflow-auto">
                <div className="min-w-max">
                    {loading ? (
                        <div className="flex h-64 w-full items-center justify-center rounded-md bg-layout-transparent p-20">
                            <CircularProgress />
                        </div>
                    ) : Object.keys(excelData).length === 0 ? (
                        <React.Fragment>&nbsp;</React.Fragment>
                    ) : type.startsWith('pl') ? (
                        <PLTable
                            companyLogo={companyLogo}
                            headersData={headersData}
                            excelData={excelData}
                            exchangeRate={exchangeRate}
                            exchangeRateDate={exchangeRateDate}
                            currencyObj={currencyObj}
                            referenceCurrencyObj={referenceCurrencyObj}
                            language={language}
                        />
                    ) : type.startsWith('cashflow') ? (
                        <CashflowTable
                            companyLogo={companyLogo}
                            headersData={headersData}
                            excelData={excelData}
                            exchangeRate={exchangeRate}
                            exchangeRateDate={exchangeRateDate}
                            currencyObj={currencyObj}
                            referenceCurrencyObj={referenceCurrencyObj}
                            language={language}
                        />
                    ) : (
                        <React.Fragment>&nbsp;</React.Fragment>
                    )}
                </div>
            </div>

            <CustomModal open={openModal}>
                <ModalAddCashData
                    setOpen={setOpenModal}
                    getExcelData={getExcelData}
                    title={displayTypeName()}
                    contracts={contracts}
                    pmContracts={pmContracts}
                    projects={projects}
                    projectStructures={projectStructures}
                    selectedData={selectedData}
                    setSelectedData={setSelectedData}
                    useCase={useCase}
                    types={types}
                    selectedCrmType={selectedCrmType}
                    setSelectedCrmType={setSelectedCrmType}
                    exchangeRateDate={exchangeRateDate}
                    exchangeRate={exchangeRate}
                    getExchangeRate={getExchangeRate}
                    handleUpdateExchangeRate={handleUpdateExchangeRate}
                    disabled={loadingExchangeRate || loadingOptions}
                    defaultDetailsTypes={defaultDetailsTypes}
                    allDetailsTypes={allDetailsTypes}
                    selectedDetailsType={selectedDetailsType}
                    setSelectedDetailsType={setSelectedDetailsType}
                    useInvoiceExchangeRate={useInvoiceExchangeRate}
                    setUseInvoiceExchangeRate={setUseInvoiceExchangeRate}
                />
            </CustomModal>

            <CustomModal open={openExportModal}>
                <ModalExportAddAlias
                    dynamicName={pageTitle}
                    setOpen={setOpenExportModal}
                    handleExport={handleExportImagesAsPDF}
                    loading={loadingPDF}
                />
            </CustomModal>

            <CustomModal open={openDownloadExcelModal}>
                <ModalExportAddAlias
                    dynamicName={pageTitle}
                    setOpen={setOpenDownloadExcelModal}
                    handleExport={handleDownloadExcel}
                    loading={loadingExcel}
                />
            </CustomModal>

            <CustomModal open={openDownloadImageModal}>
                <ModalExportAddAlias
                    dynamicName={pageTitle}
                    setOpen={setOpenDownloadImageModal}
                    handleExport={handleExportImage}
                    loading={loadingImage}
                    withAlias={false}
                />
            </CustomModal>
        </>
    );
};

FinancialTools.propTypes = {
    type: PropTypes.string,
};

FinancialTools.defaultProps = {
    type: null,
};
export default FinancialTools;
