import React, { useContext } from 'react';

import GlobalContext from 'contexts/GlobalContext';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { displayNumber, rgbToHex } from 'utils';

const ReportsTable = ({ data, companyLogo }) => {
    const { t } = useTranslation();
    const { currencyObj, referenceCurrencyObj, language } = useContext(GlobalContext);

    const documentStyles = getComputedStyle(document.documentElement);

    const pdfTextColor = documentStyles.getPropertyValue('--pdf-text').replaceAll(' ', ', ');
    const pdfLayoutTextColor = documentStyles.getPropertyValue('--pdf-layout-text').replaceAll(' ', ', ');
    const pdfLayoutBackgroundColor = rgbToHex(
        ...documentStyles.getPropertyValue('--base-pdf-layout-background').split(' '),
    );
    const disabledColor = rgbToHex(...documentStyles.getPropertyValue('--base-disabled').split(' '));

    return (
        <table>
            <thead>
                <tr className="border border-black bg-pdf-layout-background">
                    <th
                        className="p-3 text-left"
                        colSpan={4}
                        data-pdfmake={JSON.stringify({
                            fillColor: pdfLayoutBackgroundColor,
                        })}
                    >
                        <img
                            style={{
                                width: '96px',
                            }}
                            src={companyLogo}
                            placeholder="Logo"
                        />
                    </th>
                    <th
                        colSpan={8}
                        data-pdfmake={JSON.stringify({
                            fillColor: pdfLayoutBackgroundColor,
                            margin: [0, 5, 0, 0],
                        })}
                    >
                        <p
                            className="p-3 leading-10"
                            style={{
                                textAlign: 'right',
                                fontSize: '27px',
                                fontWeight: 'bold',
                                color: pdfLayoutTextColor,
                            }}
                        >
                            {t('Stocks report')}
                        </p>
                    </th>
                </tr>
                <tr className="bg-disabled">
                    <th
                        className="border border-black"
                        data-pdfmake={JSON.stringify({
                            fillColor: disabledColor,
                            margin: [0, 0, 0, -5],
                        })}
                    />
                    <th
                        className="border border-black"
                        data-pdfmake={JSON.stringify({
                            fillColor: disabledColor,
                            margin: [0, 0, 0, -5],
                        })}
                    >
                        <p
                            className="px-0.5 py-1 leading-7"
                            style={{
                                fontSize: '15px',
                                fontWeight: 'bold',
                                color: pdfTextColor,
                            }}
                        >
                            {t('SKU Product')}
                        </p>
                    </th>
                    <th
                        className="border border-black"
                        data-pdfmake={JSON.stringify({
                            fillColor: disabledColor,
                            margin: [0, 0, 0, -5],
                        })}
                    >
                        <p
                            className="px-0.5 py-1 leading-7"
                            style={{
                                fontSize: '15px',
                                fontWeight: 'bold',
                                color: pdfTextColor,
                            }}
                        >
                            {t('Product name')}
                        </p>
                    </th>
                    <th
                        className="border border-black"
                        data-pdfmake={JSON.stringify({
                            fillColor: disabledColor,
                            margin: [0, 0, 0, -5],
                        })}
                    >
                        <p
                            className="px-0.5 py-1 leading-7"
                            style={{
                                fontSize: '15px',
                                fontWeight: 'bold',
                                color: pdfTextColor,
                            }}
                        >
                            {t('Categories')}
                        </p>
                    </th>
                    <th
                        className="border border-black"
                        data-pdfmake={JSON.stringify({
                            fillColor: disabledColor,
                            margin: [0, 0, 0, -5],
                        })}
                    >
                        <p
                            className="px-0.5 py-1 leading-7"
                            style={{
                                fontSize: '15px',
                                fontWeight: 'bold',
                                color: pdfTextColor,
                            }}
                        >{`${t('Unit price')} (${currencyObj.currency})`}</p>
                    </th>
                    <th
                        className="border border-black"
                        data-pdfmake={JSON.stringify({
                            fillColor: disabledColor,
                            margin: [0, 0, 0, -5],
                        })}
                    >
                        <p
                            className="px-0.5 py-1 leading-7"
                            style={{
                                fontSize: '15px',
                                fontWeight: 'bold',
                                color: pdfTextColor,
                            }}
                        >{`${t('Unit price')} (${referenceCurrencyObj.currency})`}</p>
                    </th>
                    <th
                        className="border border-black"
                        data-pdfmake={JSON.stringify({
                            fillColor: disabledColor,
                            margin: [0, 0, 0, -5],
                        })}
                    >
                        <p
                            className="px-0.5 py-1 leading-7"
                            style={{
                                fontSize: '15px',
                                fontWeight: 'bold',
                                color: pdfTextColor,
                            }}
                        >{`${t('Total price')} (${currencyObj.currency})`}</p>
                    </th>
                    <th
                        className="border border-black"
                        data-pdfmake={JSON.stringify({
                            fillColor: disabledColor,
                            margin: [0, 0, 0, -5],
                        })}
                    >
                        <p
                            className="px-0.5 py-1 leading-7"
                            style={{
                                fontSize: '15px',
                                fontWeight: 'bold',
                                color: pdfTextColor,
                            }}
                        >{`${t('Total price')} (${referenceCurrencyObj.currency})`}</p>
                    </th>
                    <th
                        className="border border-black"
                        data-pdfmake={JSON.stringify({
                            fillColor: disabledColor,
                            margin: [0, 0, 0, -5],
                        })}
                    >
                        <p
                            className="px-0.5 py-1 leading-7"
                            style={{
                                fontSize: '15px',
                                fontWeight: 'bold',
                                color: pdfTextColor,
                            }}
                        >
                            {t('Stock quantity')}
                        </p>
                    </th>
                    <th
                        className="border border-black"
                        data-pdfmake={JSON.stringify({
                            fillColor: disabledColor,
                            margin: [0, 0, 0, -5],
                        })}
                    >
                        <p
                            className="px-0.5 py-1 leading-7"
                            style={{
                                fontSize: '15px',
                                fontWeight: 'bold',
                                color: pdfTextColor,
                            }}
                        >
                            {t('Free quantity')}
                        </p>
                    </th>
                    <th
                        className="border border-black"
                        data-pdfmake={JSON.stringify({
                            fillColor: disabledColor,
                            margin: [0, 0, 0, -5],
                        })}
                    >
                        <p
                            className="px-0.5 py-1 leading-7"
                            style={{
                                fontSize: '15px',
                                fontWeight: 'bold',
                                color: pdfTextColor,
                            }}
                        >
                            {t('Reserved quantity')}
                        </p>
                    </th>
                    <th
                        className="border border-black"
                        data-pdfmake={JSON.stringify({
                            fillColor: disabledColor,
                            margin: [0, 0, 0, -5],
                        })}
                    >
                        <p
                            className="px-0.5 py-1 leading-7"
                            style={{
                                fontSize: '15px',
                                fontWeight: 'bold',
                                color: pdfTextColor,
                            }}
                        >
                            {t('Sold quantity')}
                        </p>
                    </th>
                </tr>
            </thead>
            <tbody>
                {data.map((entry, index) => {
                    const {
                        sku,
                        name,
                        categories,
                        mainUnitPrice,
                        secondaryUnitPrice,
                        mainTotalPrice,
                        secondaryTotalPrice,
                        totalQuantity,
                        freeQuantity,
                        reservedQuantity,
                        soldQuantity,
                    } = entry;

                    return (
                        <tr key={entry.id} className={`${index % 2 === 0 ? 'bg-white' : 'bg-disabled'}`}>
                            <td
                                className="border border-black"
                                data-pdfmake={JSON.stringify({
                                    fillColor: index % 2 === 0 ? '#FFFFFF' : disabledColor,
                                    margin: [0, 0, 0, -5],
                                })}
                            >
                                <p
                                    className="px-0.5 py-1 leading-7"
                                    style={{
                                        textAlign: 'center',
                                        fontSize: '15px',
                                        fontWeight: 'bold',
                                        color: pdfTextColor,
                                    }}
                                >
                                    {index + 1}
                                </p>
                            </td>
                            <td
                                className="border border-black"
                                data-pdfmake={JSON.stringify({
                                    fillColor: index % 2 === 0 ? '#FFFFFF' : disabledColor,
                                    margin: [0, 0, 0, -5],
                                })}
                            >
                                <p
                                    className="px-0.5 py-1 leading-7"
                                    style={{
                                        textAlign: 'center',
                                        fontSize: '15px',
                                        fontWeight: 'bold',
                                        color: pdfTextColor,
                                    }}
                                >
                                    {sku}
                                </p>
                            </td>
                            <td
                                className="border border-black"
                                data-pdfmake={JSON.stringify({
                                    fillColor: index % 2 === 0 ? '#FFFFFF' : disabledColor,
                                    margin: [0, 0, 0, -5],
                                })}
                            >
                                <p
                                    className="px-0.5 py-1 leading-7"
                                    style={{
                                        textAlign: 'center',
                                        fontSize: '15px',
                                        fontWeight: 'bold',
                                        color: pdfTextColor,
                                    }}
                                >
                                    {name}
                                </p>
                            </td>
                            <td
                                className="border border-black"
                                data-pdfmake={JSON.stringify({
                                    fillColor: index % 2 === 0 ? '#FFFFFF' : disabledColor,
                                    margin: [0, 0, 0, -5],
                                })}
                            >
                                <p
                                    className="px-0.5 py-1 leading-7"
                                    style={{
                                        textAlign: 'center',
                                        fontSize: '15px',
                                        fontWeight: 'bold',
                                        color: pdfTextColor,
                                    }}
                                >
                                    {categories}
                                </p>
                            </td>
                            <td
                                className="border border-black"
                                data-pdfmake={JSON.stringify({
                                    fillColor: index % 2 === 0 ? '#FFFFFF' : disabledColor,
                                    margin: [0, 0, 0, -5],
                                })}
                            >
                                <p
                                    className="px-0.5 py-1 leading-7"
                                    style={{
                                        textAlign: 'center',
                                        fontSize: '15px',
                                        fontWeight: 'bold',
                                        color: pdfTextColor,
                                    }}
                                >
                                    {displayNumber(mainUnitPrice, language)}
                                </p>
                            </td>
                            <td
                                className="border border-black"
                                data-pdfmake={JSON.stringify({
                                    fillColor: index % 2 === 0 ? '#FFFFFF' : disabledColor,
                                    margin: [0, 0, 0, -5],
                                })}
                            >
                                <p
                                    className="px-0.5 py-1 leading-7"
                                    style={{
                                        textAlign: 'center',
                                        fontSize: '15px',
                                        fontWeight: 'bold',
                                        color: pdfTextColor,
                                    }}
                                >
                                    {displayNumber(secondaryUnitPrice, language)}
                                </p>
                            </td>
                            <td
                                className="border border-black"
                                data-pdfmake={JSON.stringify({
                                    fillColor: index % 2 === 0 ? '#FFFFFF' : disabledColor,
                                    margin: [0, 0, 0, -5],
                                })}
                            >
                                <p
                                    className="px-0.5 py-1 leading-7"
                                    style={{
                                        textAlign: 'center',
                                        fontSize: '15px',
                                        fontWeight: 'bold',
                                        color: pdfTextColor,
                                    }}
                                >
                                    {displayNumber(mainTotalPrice, language)}
                                </p>
                            </td>
                            <td
                                className="border border-black"
                                data-pdfmake={JSON.stringify({
                                    fillColor: index % 2 === 0 ? '#FFFFFF' : disabledColor,
                                    margin: [0, 0, 0, -5],
                                })}
                            >
                                <p
                                    className="px-0.5 py-1 leading-7"
                                    style={{
                                        textAlign: 'center',
                                        fontSize: '15px',
                                        fontWeight: 'bold',
                                        color: pdfTextColor,
                                    }}
                                >
                                    {displayNumber(secondaryTotalPrice, language)}
                                </p>
                            </td>
                            <td
                                className="border border-black"
                                data-pdfmake={JSON.stringify({
                                    fillColor: index % 2 === 0 ? '#FFFFFF' : disabledColor,
                                    margin: [0, 0, 0, -5],
                                })}
                            >
                                <p
                                    className="px-0.5 py-1 leading-7"
                                    style={{
                                        textAlign: 'center',
                                        fontSize: '15px',
                                        fontWeight: 'bold',
                                        color: pdfTextColor,
                                    }}
                                >
                                    {totalQuantity}
                                </p>
                            </td>
                            <td
                                className="border border-black"
                                data-pdfmake={JSON.stringify({
                                    fillColor: index % 2 === 0 ? '#FFFFFF' : disabledColor,
                                    margin: [0, 0, 0, -5],
                                })}
                            >
                                <p
                                    className="px-0.5 py-1 leading-7"
                                    style={{
                                        textAlign: 'center',
                                        fontSize: '15px',
                                        fontWeight: 'bold',
                                        color: pdfTextColor,
                                    }}
                                >
                                    {freeQuantity}
                                </p>
                            </td>
                            <td
                                className="border border-black"
                                data-pdfmake={JSON.stringify({
                                    fillColor: index % 2 === 0 ? '#FFFFFF' : disabledColor,
                                    margin: [0, 0, 0, -5],
                                })}
                            >
                                <p
                                    className="px-0.5 py-1 leading-7"
                                    style={{
                                        textAlign: 'center',
                                        fontSize: '15px',
                                        fontWeight: 'bold',
                                        color: pdfTextColor,
                                    }}
                                >
                                    {reservedQuantity}
                                </p>
                            </td>
                            <td
                                className="border border-black"
                                data-pdfmake={JSON.stringify({
                                    fillColor: index % 2 === 0 ? '#FFFFFF' : disabledColor,
                                    margin: [0, 0, 0, -5],
                                })}
                            >
                                <p
                                    className="px-0.5 py-1 leading-7"
                                    style={{
                                        textAlign: 'center',
                                        fontSize: '15px',
                                        fontWeight: 'bold',
                                        color: pdfTextColor,
                                    }}
                                >
                                    {soldQuantity}
                                </p>
                            </td>
                        </tr>
                    );
                })}
                <tr className="bg-white">
                    <td
                        colSpan={3}
                        className="border border-black"
                        data-pdfmake={JSON.stringify({
                            fillColor: '#FFFFFF',
                            margin: [0, 0, 0, -5],
                        })}
                    >
                        <p
                            className="px-0.5 py-1 leading-7"
                            style={{
                                textAlign: 'center',
                                fontSize: '15px',
                                fontWeight: 'bold',
                                color: pdfTextColor,
                            }}
                        >
                            {t('Explanations of columns')}
                        </p>
                    </td>
                    <td
                        colSpan={9}
                        className="border border-black"
                        data-pdfmake={JSON.stringify({
                            fillColor: '#FFFFFF',
                            margin: [0, 0, 0, -5],
                        })}
                    >
                        <div className="flex flex-col items-end justify-end">
                            <p
                                className="px-0.5 py-1 leading-7"
                                style={{
                                    fontSize: '15px',
                                    color: pdfTextColor,
                                }}
                            >
                                {t('SKU Product')}:{' '}
                                <span
                                    style={{
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {t('Unique identifier for each product.')}
                                </span>
                            </p>
                            <p
                                className="px-0.5 py-1 leading-7"
                                style={{
                                    fontSize: '15px',
                                    color: pdfTextColor,
                                }}
                            >
                                {t('Product name')}:{' '}
                                <span
                                    style={{
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {t('Product name')}.
                                </span>
                            </p>
                            <p
                                className="px-0.5 py-1 leading-7"
                                style={{
                                    fontSize: '15px',
                                    color: pdfTextColor,
                                }}
                            >
                                {t('Unit price')}:{' '}
                                <span
                                    style={{
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {t('Unit price')}.
                                </span>
                            </p>
                            <p
                                className="px-0.5 py-1 leading-7"
                                style={{
                                    fontSize: '15px',
                                    color: pdfTextColor,
                                }}
                            >
                                {t('Total price')}:{' '}
                                <span
                                    style={{
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {t('Total price of the stock.')}
                                </span>
                            </p>
                            <p
                                className="px-0.5 py-1 leading-7"
                                style={{
                                    fontSize: '15px',
                                    color: pdfTextColor,
                                }}
                            >
                                {t('Stock quantity')}:{' '}
                                <span
                                    style={{
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {t('Number of units in stock.')}
                                </span>
                            </p>
                            <p
                                className="px-0.5 py-1 leading-7"
                                style={{
                                    fontSize: '15px',
                                    color: pdfTextColor,
                                }}
                            >
                                {t('Free quantity')}:{' '}
                                <span
                                    style={{
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {t('Number of units available for sale.')}
                                </span>
                            </p>
                            <p
                                className="px-0.5 py-1 leading-7"
                                style={{
                                    fontSize: '15px',
                                    color: pdfTextColor,
                                }}
                            >
                                {t('Reserved quantity')}:{' '}
                                <span
                                    style={{
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {t('Number of units reserved for customers (not yet invoiced).')}
                                </span>
                            </p>
                            <p
                                className="px-0.5 py-1 leading-7"
                                style={{
                                    fontSize: '15px',
                                    color: pdfTextColor,
                                }}
                            >
                                {t('Sold quantity')}:{' '}
                                <span
                                    style={{
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {t('Number of units sold out of total stock.')}
                                </span>
                            </p>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    );
};

ReportsTable.propTypes = {
    data: PropTypes.array,
    companyLogo: PropTypes.string,
};

ReportsTable.defaultProps = {
    data: [],
    companyLogo: null,
};

export default ReportsTable;
