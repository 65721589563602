import React, { useEffect, useMemo, useRef, useState } from 'react';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { errorHandling } from 'utils';

const PaginationSearch = (props) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { children, getFunction, perPageItems, setLoading, loading, refreshList, query } = props;
    const [currentPage, setCurrentPage] = useState(0);
    const [pages, setPages] = useState([]);
    const [totalLength, setTotalLength] = useState(0);
    const [debouncedQuery, setDebouncedQuery] = useState('');
    const controller = useRef(null);
    const debounceTimer = useRef(null);
    const memoizedRefreshList = useMemo(() => refreshList, [...refreshList]);

    const handleGetData = async (dynamicCurrentPage) => {
        setLoading(true);

        try {
            const len = await getFunction(dynamicCurrentPage, perPageItems, controller.current);

            setTotalLength(len);

            if (len >= 0) {
                const newPagesArray = new Array(Math.ceil(len / perPageItems)).fill(0).map((_, index) => index + 1);
                setPages(newPagesArray);
            } else {
                setPages([]);
            }

            setLoading(false);
        } catch (err) {
            if (err instanceof Error && err.message !== 'Cancel: canceled') {
                console.error(err);

                let errorMessage = 'error';
                if (!err?.response) errorMessage = t('Something went wrong!');
                else errorMessage = errorHandling(err).length > 100 ? errorHandling(err) : t(errorHandling(err));

                enqueueSnackbar(errorMessage, {
                    variant: 'error',
                });

                setLoading(false);
                setTotalLength(0);
            }
        }
    };

    useEffect(() => {
        setLoading(true);

        debounceTimer.current = setTimeout(() => {
            setDebouncedQuery(query);
        }, 500);

        return () => {
            controller.current?.abort();
            clearTimeout(debounceTimer.current);
        };
    }, [query]);

    useEffect(() => {
        controller.current = new AbortController();

        setCurrentPage(0);
        handleGetData(0);

        return () => {
            clearTimeout(debounceTimer?.current);
            controller.current.abort();
        };
    }, [debouncedQuery, memoizedRefreshList]);

    const handleNextPage = () => {
        if (loading) return;

        setCurrentPage((prev) => {
            const nextValue = prev + 1;

            if (nextValue === pages.length) return prev;

            handleGetData(nextValue);

            return nextValue;
        });
    };

    const handlePrevPage = () => {
        if (loading) return;

        setCurrentPage((prev) => {
            if (prev === 0) return prev;

            const prevValue = prev - 1;

            handleGetData(prevValue);

            return prevValue;
        });
    };

    return (
        <div className="relative">
            {children}

            {totalLength > 10 && (
                <div className="mt-10 flex">
                    <div
                        className="flex select-none rounded-md"
                        style={{
                            zIndex: 999,
                            bottom: '5%',
                            left: '41%',
                        }}
                    >
                        <div
                            className={`rounded-md p-4 transition-all ${
                                currentPage === 0
                                    ? 'text-disabled'
                                    : 'text-primary-main hover:bg-layout-transparent-dark hover:text-primary-dark'
                            }
                             ${loading ? 'text-layout-transparent-dark hover:text-layout-transparent-dark' : ''}`}
                            onClick={handlePrevPage}
                        >
                            <ArrowBackIosIcon />
                        </div>
                        <div className="p-4 text-xl font-semibold text-main-text">
                            <span className="text-primary-main">{currentPage + 1}</span> of {pages.length}
                        </div>
                        <div
                            className={`rounded-md  p-4 transition-all
                        ${
                            currentPage + 1 === pages.length
                                ? 'text-disabled'
                                : 'text-primary-main hover:bg-layout-transparent-dark hover:text-primary-dark'
                        }
                        ${loading ? 'text-layout-transparent-dark hover:text-layout-transparent-dark' : ''}`}
                            onClick={handleNextPage}
                        >
                            <ArrowForwardIosIcon />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

PaginationSearch.propTypes = {
    children: PropTypes.element,
    getFunction: PropTypes.func,
    perPageItems: PropTypes.number,
    setLoading: PropTypes.func,
    loading: PropTypes.bool,
    query: PropTypes.string,
    refreshList: PropTypes.array,
};

PaginationSearch.defaultProps = {
    children: null,
    getFunction: () => null,
    perPageItems: 10,
    setLoading: () => {},
    loading: false,
    query: '',
    refreshList: [],
};

export default PaginationSearch;
