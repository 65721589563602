import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import OpportunityForm from 'components/opportunities/opportunity-form';
import UserContext from 'contexts/UserContext';
import { Header } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import API from 'utils/axios';

const EditOpportunity = () => {
    const { id: opportunityId } = useParams();
    const { t } = useTranslation();
    const [opportunity, setOpportunity] = useState(null);
    const history = useHistory();

    const { checkPerm } = useContext(UserContext);

    const canAll = checkPerm([
        {
            permissionId: '15',
            permissionType: 'ALL',
        },
    ]);

    const getMilestone = async () => {
        try {
            const response = await API.get(`/mileStone/${opportunityId}`);
            setOpportunity(response?.data?.data);
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        if (!canAll) {
            history.push('/');
        }
        getMilestone();
    }, [canAll]);

    return (
        <>
            <Helmet>
                <title>Configurator ERP | {t('Edit Opportunity')}</title>
            </Helmet>

            <Header pageTitle={t('Edit Opportunity')} />
            {canAll && (
                <div className="page-container">
                    <OpportunityForm id={opportunityId} opportunityInfo={opportunity} editable={false} />
                </div>
            )}
        </>
    );
};

export default EditOpportunity;
