import React, { useEffect, useRef, useState } from 'react';

import useScreenSizes from 'hooks/useScreenSizes.js';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { daysFrom, formatDate } from 'utils';

import SubOpportunityDisplay from './sub-opportunity-display';
import SubmilestoneDisplay from './submilestone-display';

const ActivitySquare = (props) => {
    const { t } = useTranslation();
    const { activity, zIndex } = props;
    const { activitiesTasks = [] } = activity;
    const [estimatedEndDate, setEstimatedEndDate] = useState(new Date());
    const [taskOpened, setTaskOpened] = useState(false);
    const outerPanel = useRef();
    const middlePanel = useRef();
    const innerPanel = useRef();
    const [width] = useScreenSizes();

    useEffect(() => {
        setEstimatedEndDate(activitiesTasks[activitiesTasks.length - 1].estimatedEndDate);
    }, [activitiesTasks]);

    useEffect(() => {
        if (taskOpened) {
            const panelHeight = innerPanel.current.offsetHeight;
            outerPanel.current.style.height = panelHeight + 'px';
            middlePanel.current.style.height = panelHeight + 'px';
        } else {
            outerPanel.current.style.height = 0;
            middlePanel.current.style.height = 0;
        }
    }, [width, taskOpened]);

    return (
        <div
            className="relative my-4 cursor-pointer"
            style={{
                zIndex,
            }}
        >
            <div
                className={`rounded-lg hover:bg-layout-transparent ${taskOpened && 'bg-layout-transparent'} p-2.5`}
                onClick={() => setTaskOpened(!taskOpened)}
            >
                <div className="group relative">
                    <p className="relative z-20 mb-1 whitespace-nowrap rounded-md border-l-4 border-secondary-light bg-layout-lighter p-4 text-lg font-semibold">
                        {activity.owner.profile.role[0]?.toUpperCase() + activity.owner.profile.role?.slice(1)}:{' '}
                        {activity.owner.profile.name}
                    </p>
                </div>

                <div className="relative my-2.5 ml-4 inline-flex items-center rounded-full bg-layout-transparent p-1.5 px-4 ">
                    <div
                        className="absolute left-0 transform bg-primary-main"
                        style={{
                            width: '50px',
                            height: '50px',
                            transform: 'translateX(-2.75rem) scale(.1)',
                            borderRadius: '999px',
                        }}
                    />
                    <p>{activity.status}</p>
                </div>

                <p className="mb-1 text-dark-text">{formatDate(activity.createAt)}</p>
                <p className="font-semibold">
                    {t('Deadline in')} {daysFrom(estimatedEndDate)} {t('days')}
                </p>
            </div>

            {/* Expand panel */}
            <div
                ref={outerPanel}
                className="relative mt-2.5 w-full transition-all"
                style={{
                    transform: 'translateY(-.5px)',
                }}
            >
                <div
                    className={`absolute left-0 top-0 overflow-hidden rounded-md bg-layout-light transition-all`}
                    style={{
                        width: 'auto',
                        minWidth: 'min-content',
                        maxWidth: 'max-content',
                        boxShadow: '0 0 10px rgba(0, 0, 0, .25)',
                    }}
                    ref={middlePanel}
                >
                    <div ref={innerPanel} className="relative w-full">
                        <div className="flex w-full flex-col items-start gap-2.5 border-b border-layout-transparent-dark px-2.5 py-5">
                            <div className="flex flex-1 flex-col">
                                <p className="pointer-events-none text-dark-text">{t('Responsible')}:</p>
                                {activity.users
                                    .filter((user) => user.userType === 'RESPONSIBLE')
                                    .map((r) => (
                                        <p key={r.id} className="text-lg font-semibold">
                                            {r.user.email}
                                        </p>
                                    ))}
                            </div>

                            <div className="flex flex-1 flex-col">
                                <p className="pointer-events-none text-dark-text">{t('Watchers')}:</p>
                                {activity.users
                                    .filter((user) => user.userType === 'WATCHER')
                                    .map((r) => (
                                        <p key={r.id} className="text-lg font-semibold">
                                            {r.user.email}
                                        </p>
                                    ))}
                            </div>

                            <div className="flex flex-1 flex-col">
                                <p className="pointer-events-none text-dark-text">{t('Accepters')}:</p>
                                {activity.users
                                    .filter((user) => user.userType === 'ACCEPTER')
                                    .map((r) => (
                                        <p key={r.id} className="text-lg font-semibold">
                                            {r.user.email}
                                        </p>
                                    ))}
                            </div>
                        </div>

                        <div
                            className="w-full border-b border-layout-transparent-dark px-2.5 py-5"
                            style={{
                                userSelect: 'none',
                            }}
                        >
                            <p>
                                <span className="text-dark-text">{t('Data postare')}:</span>{' '}
                                <span className="font-semibold">{formatDate(activity.createAt)}</span>
                            </p>
                        </div>
                        <div className="p-2.5">
                            {activity.activitiesTasks.map((submilestone) =>
                                activity.pipelineType === 'LEADS_TO_POTENTIAL' ? (
                                    <SubOpportunityDisplay
                                        key={submilestone.id}
                                        expandable={false}
                                        subOpportunity={submilestone}
                                    />
                                ) : (
                                    <SubmilestoneDisplay
                                        key={submilestone.id}
                                        expandable={false}
                                        submilestone={submilestone}
                                    />
                                ),
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

ActivitySquare.propTypes = {
    activity: PropTypes.object,
    zIndex: PropTypes.number,
};

ActivitySquare.defaultProps = {
    activity: null,
    zIndex: 0,
};

const TaskColumn = (props) => {
    const { task } = props;

    const { name = '', Activity = [] } = task;

    return (
        <div
            style={{
                overflowX: 'visible',
                minWidth: '200px',
            }}
        >
            {/* Task name */}
            <div className="rounded-md bg-layout-transparent-dark p-4">
                <p className="text-lg">{name}</p>
            </div>

            {/* Task body */}
            <div className="flex flex-col">
                {Activity.map((activity, index) => (
                    <ActivitySquare key={activity.id} activity={activity} zIndex={index + 1} />
                ))}
            </div>
        </div>
    );
};

TaskColumn.propTypes = {
    task: PropTypes.object,
};

TaskColumn.defaultProps = {
    task: null,
};

const SubMilestone = (props) => {
    const { submilestone } = props;

    return (
        <div className="text-main-text">
            {/* Sub milestone name */}
            <div className={`border-b border-layout-transparent bg-layout-transparent-dark p-4 py-6`}>
                <h4 className="font-semibold">{submilestone.name}</h4>
            </div>

            {/* Sub milestone tasks */}
            <div className="flex flex-wrap gap-2 bg-layout-transparent p-2">
                {submilestone.children.map((task, index) => (
                    <div key={task.id} style={{ zIndex: index + 1 }} className="relative">
                        <TaskColumn task={task} />
                    </div>
                ))}
            </div>
        </div>
    );
};

SubMilestone.propTypes = {
    submilestone: PropTypes.object,
};

SubMilestone.defaultProps = {
    submilestone: null,
};

const MilestonePipeline = (props) => {
    const { milestone } = props;
    const { t } = useTranslation();

    return milestone !== null ? (
        <div className="flex flex-col gap-8">
            {/* Milestone name */}
            <h2>{milestone.name}</h2>

            {/* Swipe-able section */}
            <div className="flex flex-wrap items-start justify-start gap-4">
                {/* Submilestones */}
                {milestone.mileStoneTask.map((submilestone, index) => (
                    <div
                        key={submilestone.id}
                        style={{
                            zIndex: index + 1,
                        }}
                        className="relative rounded-md border border-layout-transparent-dark"
                    >
                        <SubMilestone submilestone={submilestone} />
                    </div>
                ))}

                {!milestone.mileStoneTask.length && (
                    <div className="mb-20 flex w-full items-center justify-center rounded-md border border-layout-transparent-dark bg-layout-transparent px-10 py-20">
                        <p
                            className="pointer-events-none text-xl font-semibold text-dark-text"
                            style={{ userSelect: 'none' }}
                        >
                            {t('There are no sub-milestones.')}
                        </p>
                    </div>
                )}
            </div>
        </div>
    ) : null;
};

MilestonePipeline.propTypes = {
    milestone: PropTypes.object,
};

MilestonePipeline.defaultProps = {
    milestone: null,
};

export default MilestonePipeline;
