import { useEffect, useMemo, useRef, useState } from 'react';

import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { errorHandling } from 'utils';

const DisplayInfiniteScrollResults = ({ children, query, setLoading, getFunction, refreshList }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [debouncedQuery, setDebouncedQuery] = useState('');
    const controller = useRef(null);
    const debounceTimer = useRef(null);
    const memoizedRefreshList = useMemo(() => refreshList, [...refreshList]);

    const handleGetData = async () => {
        setLoading(true);

        try {
            await getFunction(controller.current);
            setLoading(false);
        } catch (err) {
            if (err instanceof Error && err.message !== 'Cancel: canceled') {
                console.error(err);

                let errorMessage = 'error';
                if (!err?.response) errorMessage = t('Something went wrong!');
                else errorMessage = errorHandling(err).length > 100 ? errorHandling(err) : t(errorHandling(err));

                enqueueSnackbar(errorMessage, {
                    variant: 'error',
                });

                setLoading(false);
            }
        }
    };

    useEffect(() => {
        setLoading(true);

        debounceTimer.current = setTimeout(() => {
            setDebouncedQuery(query);
        }, 500);

        return () => {
            controller.current?.abort();
            clearTimeout(debounceTimer.current);
        };
    }, [query]);

    useEffect(() => {
        controller.current = new AbortController();

        handleGetData();

        return () => {
            clearTimeout(debounceTimer?.current);
            controller.current.abort();
        };
    }, [debouncedQuery, memoizedRefreshList]);

    return children;
};

DisplayInfiniteScrollResults.propTypes = {
    children: PropTypes.element,
    query: PropTypes.string,
    getFunction: PropTypes.func,
    setLoading: PropTypes.func,
    refreshList: PropTypes.array,
};

DisplayInfiniteScrollResults.defaultProps = {
    children: null,
    query: '',
    getFunction: () => {},
    setLoading: () => {},
    refreshList: [],
};

export default DisplayInfiniteScrollResults;
