import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Configurator from 'components/shared/configurator/configurator';
import ConfiguratorForm from 'components/shared/configurator/configurator-form';
import UserContext from 'contexts/UserContext';
import { Header, Tabs } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const CreateEstate = () => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState(0);
    const history = useHistory();
    const { checkPerm } = useContext(UserContext);

    const canAll = checkPerm([
        {
            permissionId: '7',
            permissionType: 'ALL',
        },
    ]);

    const canEditCRMFiles = checkPerm([
        {
            permissionId: '32',
            permissionType: 'ALL',
        },
    ]);

    const allTabs = [
        {
            id: '7',
            tab: t('Preview'),
        },
        {
            id: '13',
            tab: 'Configurator',
        },
    ];

    const tabs = [];

    allTabs.forEach((t) => {
        if (
            checkPerm([
                {
                    permissionId: t.id,
                    permissionType: 'ALL',
                },
            ])
        )
            tabs.push(t.tab);
    });

    useEffect(() => {
        if (!canAll) {
            history.push('/');
        }
    }, [canAll]);

    return (
        <>
            <Helmet>
                <title>CRM | {t('Create catalog entity')}</title>
            </Helmet>

            <Header
                pageTitle={t('Create catalog entity')}
                toolbar={<Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />}
            />
            {canAll && (
                <div className="page-container relative">
                    {activeTab === 0 ? <ConfiguratorForm entity="estate" canEditCRMFiles={canEditCRMFiles} /> : null}

                    {activeTab === 1 ? <Configurator entity="estate" /> : null}
                </div>
            )}
        </>
    );
};

export default CreateEstate;
