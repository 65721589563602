import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import ListIcon from '@material-ui/icons/List';

import {
    Box,
    Button,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core';

import OpportunityForm from 'components/opportunities/opportunity-form';
import NoDataPlaceholder from 'components/shared/no-data-placeholder';
import Pagination from 'components/shared/pagination';
import UserContext from 'contexts/UserContext';
import PropTypes from 'prop-types';
import { ExpandableRow, TableSeparator } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { configurator } from 'routes';
import { formatDate, linkStyle } from 'utils';
import API from 'utils/axios';

const OpportunityRow = (props) => {
    const { opportunity, setInitializeData } = props;
    const history = useHistory();
    const { t } = useTranslation();

    const { checkPerm } = useContext(UserContext);

    const canAll = checkPerm([
        {
            permissionId: '15',
            permissionType: 'ALL',
        },
    ]);

    const canAllUsers = checkPerm([
        {
            permissionId: '11',
            permissionType: 'ALL',
        },
    ]);

    return (
        <ExpandableRow
            colSpan={6}
            padding={false}
            row={
                <>
                    <TableCell>{opportunity.name}</TableCell>
                    <TableCell>{opportunity.isActive ? t('active') : t('inactive')}</TableCell>
                    <TableCell>{formatDate(opportunity.createAt)}</TableCell>
                    <TableCell>
                        <span
                            className={`font-semibold ${canAllUsers && linkStyle}`}
                            onClick={(e) => {
                                e.stopPropagation();
                                if (!canAllUsers || setInitializeData) return;
                                history.push(
                                    configurator.base +
                                        configurator.users.base +
                                        configurator.users.update +
                                        '/' +
                                        opportunity.authorId +
                                        '?tab=1',
                                );
                            }}
                        >
                            {opportunity.user.profile.name}
                        </span>
                    </TableCell>
                </>
            }
            collapse={
                <div className="relative overflow-visible">
                    <div className="mb-2 p-5">
                        {canAll && !setInitializeData && (
                            <Button
                                startIcon={<ListIcon />}
                                color="secondary"
                                onClick={() => {
                                    history.push(
                                        configurator.base +
                                            configurator.opportunities.base +
                                            configurator.opportunities.update +
                                            '/' +
                                            opportunity.id,
                                    );
                                }}
                            >
                                {t('View & Edit Opportunity')}
                            </Button>
                        )}
                    </div>

                    <div className="mb-6 overflow-visible pl-5">
                        <OpportunityForm
                            view={false}
                            editable={true}
                            id={opportunity.id}
                            opportunityInfo={opportunity}
                        />
                    </div>
                </div>
            }
        />
    );
};

OpportunityRow.propTypes = {
    opportunity: PropTypes.object,
    zIndex: PropTypes.number,
    setInitializeData: PropTypes.object,
};

OpportunityRow.defaultProps = {
    opportunity: null,
    zIndex: 1,
    setInitializeData: null,
};

const Opportunities = ({ setInitializeData }) => {
    const [opportunities, setOpportunities] = useState([]);
    const { t } = useTranslation();

    const { checkPerm } = useContext(UserContext);

    const [loading, setLoading] = useState(true);

    const canView = checkPerm([
        {
            permissionId: '15',
            permissionType: 'VIEW',
        },
    ]);

    const getMilestones = async (currentPage, perPage) => {
        try {
            const response = await API.get('/configurator-opportunities', {
                params: {
                    perPage,
                    currentPage,
                    pagesToLoad: 1,
                },
            });

            setOpportunities(response.data.data.data);
            setInitializeData?.((prev) => ({ ...prev, nextDisabled: !response.data.data.length }));
            return response.data.data.length;
        } catch (err) {
            throw new Error(err);
        }
    };

    return (
        <>
            {!setInitializeData && (
                <Helmet>
                    <title>
                        {t('ERP Configurator')} | {t('Opportunities')}
                    </title>
                </Helmet>
            )}

            {canView && (
                <Pagination loading={loading} setLoading={setLoading} getFunction={getMilestones}>
                    {loading ? (
                        <div className="flex h-64 w-full items-center justify-center bg-layout-main">
                            <CircularProgress />
                        </div>
                    ) : opportunities.length ? (
                        <TableContainer component={Box}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{t('Opportunity name')}</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell>{t('Created on')}</TableCell>
                                        <TableCell>{t('Author')}</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableSeparator />
                                    {opportunities?.map((o) => (
                                        <OpportunityRow
                                            key={o.id}
                                            opportunity={o}
                                            setInitializeData={setInitializeData}
                                        />
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : (
                        <NoDataPlaceholder />
                    )}
                </Pagination>
            )}
        </>
    );
};

Opportunities.propTypes = {
    setInitializeData: PropTypes.func,
};

Opportunities.defaultProps = {
    setInitializeData: null,
};

export default Opportunities;
