import React, { createContext, memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import EditIcon from '@material-ui/icons/Edit';

import { Button, CircularProgress, TextField } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';

import { AccountingAccounts } from 'api/Accounting_Accounts';
import { Expenses, Revenues } from 'api/Expenses_Revenues';
import CustomModal from 'components/modals/custom_modal';
import GlobalContext from 'contexts/GlobalContext';
import DOMPurify from 'dompurify';
import useScreenSizes from 'hooks/useScreenSizes';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import EditElementModal from 'pages/configurator/documents_configurator/documents_items/edit-element-modal';
import PropTypes from 'prop-types';
import { DatePicker, Dropdown, LabelWrapper } from 'RaisisComponents';
import { LocaleTextField } from 'RaisisComponents/Inputs';
import { useTranslation } from 'react-i18next';
import { internalActivity } from 'routes';
import { errorHandling, formatExchangeRate, generateUUID, ONE_DAY_IN_MILLISECONDS, toLocaleNumber } from 'utils';
import API from 'utils/axios';
import * as yup from 'yup';

import ProjectStructureAddSectionModal from './project-structure-add-section-modal';
import ProjectStructureMilestone from './project-structure-milestone';

// ? Default values for a new project structure
const defaultProjectStructure = {
    id: generateUUID(),
    name: '',
    startDate: new Date(new Date().toDateString()),
    initialStartDate: null,
    milestones: [],
    manualExchange: false,
    exchangeDate: null,
    exchangeRate: 1,
    status: 'TO_DO',
    description: '',
};

// ? Default values for context object
const defaultValues = {
    projectStructureInfo: null,
    loadingExchangeRate: false,
    onDeleteMilestone: () => {},
    onUpdateMilestone: () => {},
    onAddActivity: () => {},
    onDeleteActivity: () => {},
    onUpdateActivity: () => {},
    onBatchedUpdateActivity: () => {},
    onBatchedUpdateMilestone: () => {},
    allExpenses: [],
    allRevenues: [],
    accounts: [],
    onUpdateMilestoneDate: () => {},
    onUpdateMilestoneDependency: () => {},
    onUpdateActivityDate: () => {},
    onUpdateActivityDependency: () => {},
    onUpdateActivityRealizationDays: () => {},
};

// ? Project context declaration
export const ProjectContext = createContext(defaultValues);

/**
 *  ? Project sections memoization -> I added the memoization in order to avoid unnecessary rerenders
 *  ? In this screen we can have a lot of components and we need memoization in order to avoid an expensive render
 *  ! If in the future more props will be added to this components, you will need to memoize them using a React mechanism such as useMemo or useCallback in order, and check if they changed between rerenders in memo second parameter
 *  ! Props such as object or arrays who can change will be checked using lodash equality function, while the primitives and memoized arrays and objects that will never change will be checked using === operator
 *  !!! All components that are defined using using memo will have this rules applied !!!
 */
const ProjectStructureMilestonesWrapper = memo(
    function ProjectStructureMilestonesWrapper({ sections, allMilestones }) {
        return sections.map((milestone, index) => (
            <ProjectStructureMilestone
                key={milestone.id}
                milestone={milestone}
                index={index}
                allMilestones={allMilestones}
                sections={sections}
            />
        ));
    },
    (prev, next) => prev.allMilestones === next.allMilestones && _.isEqual(prev.sections, next.sections),
);

ProjectStructureMilestonesWrapper.propTypes = {
    sections: PropTypes.array,
    allMilestones: PropTypes.array,
};

ProjectStructureMilestonesWrapper.defaultProps = {
    sections: [],
    allMilestones: [],
};

const legendData = [
    {
        id: generateUUID(),
        info: 'Status expense',
        subItems: [
            { id: generateUUID(), info: 'Unselected', color: 'var(--layout-lighter)' },
            { id: generateUUID(), info: 'Selected', color: 'rgb(var(--base-secondary-light) / 20%)' },
            {
                id: generateUUID(),
                info: 'Deleted and checked',
                color: 'color-mix(in srgb, rgb(var(--base-secondary-light) / 20%), rgb(var(--base-error) / 40%))',
            },
            { id: generateUUID(), info: 'Deleted', color: 'rgb(var(--base-error) / 40%)' },
        ],
    },
    {
        id: generateUUID(),
        info: 'Status revenue',
        subItems: [
            { id: generateUUID(), info: 'Unselected', color: 'var(--layout-lighter)' },
            { id: generateUUID(), info: 'Selected', color: 'rgb(var(--base-secondary-light) / 20%)' },
            {
                id: generateUUID(),
                info: 'Deleted and checked',
                color: 'color-mix(in srgb, rgb(var(--base-secondary-light) / 20%), rgb(var(--base-error) / 40%))',
            },
            { id: generateUUID(), info: 'Deleted', color: 'rgb(var(--base-error) / 40%)' },
        ],
    },
];

const ProjectStructureForm = (props) => {
    const [width] = useScreenSizes();
    const history = useHistory();
    const { t } = useTranslation();

    const {
        projectStructureInfo,
        originalId,
        loading,
        setLoading,
        projectStructureSelection,
        historyLength,
        getNewProjectStructure,
        isInitialLoad,
        setIsInitialLoad,
    } = props;

    const { language } = useContext(GlobalContext);

    // !!! All entities that have use a useMemo or a useCallback that help them to be declared will be used in child components or in the context, so we need to memoize them in order to avoid rerenders !!!
    const memoizedProjectStructureInfo = useMemo(() => projectStructureInfo, [projectStructureInfo]);
    // ? Boolean used to determine if the status of a new section or activity will be set to new
    const isNew = memoizedProjectStructureInfo ? true : false;
    // ? We check if we can edit the project structure
    const canEdit = memoizedProjectStructureInfo ? originalId === memoizedProjectStructureInfo.id : true;
    const { enqueueSnackbar } = useSnackbar();

    const statuses = ['TO_DO', 'IN_PROGRESS', 'DONE'];
    const [selectedStatus, setSelectedStatus] = useState(
        memoizedProjectStructureInfo
            ? statuses.findIndex((status) => memoizedProjectStructureInfo.status === status)
            : 0,
    );
    const [isCreating, setIsCreating] = useState(false);

    const [loadingExchangeRate, setLoadingExchangeRate] = useState(false);

    const [allMilestones, setAllMilestones] = useState([]);
    const memoizedAllMilestones = useMemo(() => allMilestones, [allMilestones]);

    const [projectStructure, setProjectStructure] = useState(defaultProjectStructure);

    // ? We keep a state with the api exchange rate in order to check if the user modified the value when submitting
    const [apiExchangeRate, setApiExchangeRate] = useState(0);

    const [allRevenues, setAllRevenues] = useState([]);
    const memoizedAllRevenues = useMemo(() => allRevenues, [allRevenues]);
    const [allExpenses, setAllExpenses] = useState([]);
    const memoizedAllExpenses = useMemo(() => allExpenses, [allExpenses]);
    const [accounts, setAccounts] = useState([]);
    const memoizedAccount = useMemo(() => accounts, [accounts]);

    const [open, setOpen] = useState(false);
    const [openSectionModal, setOpenSectionModal] = useState(false);

    const handleCloseModal = () => setOpen(false);
    const handleCloseSectionModal = () => setOpenSectionModal(false);
    const handleOpenModal = () => setOpen(true);
    const handleOpenSectionModal = () => setOpenSectionModal(true);

    // !!! All entities that have use a useMemo or a useCallback that help them to be declared will be used in child components or in the context, so we need to memoize them in order to avoid rerenders !!!
    const handleChangeProjectStructure = useCallback((e) => {
        setProjectStructure((prev) => {
            const newState = { ...prev, [e.target.name]: e.target.value };

            return newState;
        });
    }, []);

    const handleBatchedChangeProjectStructure = useCallback(
        /**
         * ? Updates a multitude of fields of the project structure object
         * @param {object} elements - Object of elements that represent the modified fields of the project structure object
         */
        (elements) => {
            setProjectStructure((prev) => {
                const newState = { ...prev, ...elements };

                return newState;
            });
        },
        [],
    );

    const handleUpdateProjectStructureDescription = (value) => {
        handleChangeProjectStructure({
            target: {
                name: 'description',
                value,
            },
        });
        handleCloseModal();
    };

    const updateMilestoneActivitiesDates = useCallback(
        /**
         * ? Updates the activities of a section
         * @param {array} activities - Array of activities witch need updated
         * @param {number} daysDifference - Days offset
         */
        (activities, daysDifference) => {
            activities.forEach(
                (a, index) =>
                    (activities[index].startDate = new Date(
                        new Date(a.startDate).getTime() + daysDifference * ONE_DAY_IN_MILLISECONDS,
                    )),
            );
        },
        [],
    );

    /**
     * ? Updates the project structure start date
     * @param {Date} e - New date of the project structure
     */
    const handleUpdateProjectStructureStartDate = (e) => {
        // ? If we update the start date of the project structure we will need to update the sections and activities start date accordingly
        const initialStartTimestamp = new Date(projectStructure.startDate).getTime();
        const newStartDate = new Date(new Date(e).toDateString());
        const newStartTimestamp = newStartDate.getTime();

        // ? With this calculation we will determine by how many days we have to change the start date of each section and activity
        // ? This can be a negative value, a positive one or 0
        // ? Positive for something in the future and negative for something in the past
        // !!! This calculation will appear in other functions and the logic will always be the same
        const daysDifference = (newStartTimestamp - initialStartTimestamp) / ONE_DAY_IN_MILLISECONDS;

        // !!! Always work on a clone of an object or array !!!
        const copyOfMilestones = structuredClone(projectStructure.milestones);

        // ? Here we update the sections and activities of the project structure by looping throw all of them and changing the start date using the day difference variable
        copyOfMilestones.forEach((m, mIndex) => {
            copyOfMilestones[mIndex]['startDate'] = new Date(
                new Date(m.startDate).getTime() + daysDifference * ONE_DAY_IN_MILLISECONDS,
            );

            updateMilestoneActivitiesDates(m.activities, daysDifference);
        });

        handleBatchedChangeProjectStructure({
            startDate: newStartDate,
            milestones: copyOfMilestones,
        });

        enqueueSnackbar(
            t(
                'The start date has been updated to {{date}}. All the sections and activities that are related to this one will have their date updated accordingly!',
                { date: newStartDate.toLocaleDateString() },
            ),
            { variant: 'info' },
        );
    };

    const getMilestoneInfo = useCallback(
        /**
         * ? Gets the index of the section and a copy of all sections
         * @param {string} milestoneId - We return the index based on the milestoneId
         * @param {object} prev - The previous state of the project structure object (before updating it)
         * @returns {object} The index of the section and a copy of all sections
         */
        (milestoneId, prev) => {
            const index = prev.milestones.findIndex((milestone) => milestone.id === milestoneId);
            const copyOfMilestones = structuredClone(prev.milestones);

            return { index, copyOfMilestones };
        },
        [],
    );

    const handleAddMilestone = (name, milestoneId) => {
        handleChangeProjectStructure({
            target: {
                name: 'milestones',
                value: [
                    ...projectStructure.milestones,
                    {
                        id: generateUUID(),
                        milestoneId,
                        name,
                        description: '',
                        startDate: new Date(projectStructure.startDate),
                        initialStartDate: null,
                        isNew,
                        referenceKey: generateUUID(),
                        dependency: null,
                        status: 'NEW',
                        activities: [],
                    },
                ],
            },
        });

        handleCloseSectionModal();
    };

    const handleDeleteMilestone = useCallback((milestoneId, reference) => {
        setProjectStructure((prev) => {
            const copyOfMilestones = structuredClone(prev.milestones);

            // ? When deleting a milestone we have to set to null the dependency field of the section that were dependent on this one
            for (let i = 0; i < copyOfMilestones.length; i++) {
                if (copyOfMilestones[i].dependency === reference) copyOfMilestones[i].dependency = null;
            }

            const filteredMilestones = copyOfMilestones.filter((milestone) => milestone.id !== milestoneId);

            return { ...prev, milestones: filteredMilestones };
        });
    }, []);

    const handleUpdateMilestone = useCallback(
        (milestoneId, key, value) => {
            setProjectStructure((prev) => {
                const { index, copyOfMilestones } = getMilestoneInfo(milestoneId, prev);
                copyOfMilestones[index][key] = value;

                return { ...prev, milestones: copyOfMilestones };
            });
        },
        [getMilestoneInfo],
    );

    const handleBatchedUpdateMilestone = useCallback(
        /**
         * ? Updates a multitude of fields of the section object
         * @param {string} milestoneId - We return the index based on the section ID
         * @param {object} elements - Object of elements that represent the modified fields of the section object
         */
        (milestoneId, elements) => {
            setProjectStructure((prev) => {
                const { index, copyOfMilestones } = getMilestoneInfo(milestoneId, prev);

                for (const element of elements) {
                    copyOfMilestones[index][element.name] = element.value;
                }

                return { ...prev, milestones: copyOfMilestones };
            });
        },
        [getMilestoneInfo],
    );

    const updateMilestonesDatesRecursively = useCallback(
        /**
         * ? This function will update recursively the sections tree when the dependency field is equal to the reference
         * ? We start by updating the root section, then we update all the section that depend on this one, and this step is repeated for each section in the previous step when one of the section has other sections depending on it
         * @param {string} reference - The reference is the unique key of each section (like an id), and we use is to find witch section depend on it
         * @param {object} milestones - All milestones and opportunities
         * @param {number} daysDifference - Days offset
         * @param {number} startIndex - We use the start index in order to avoid checking the sections that can't possibly have the previous section as a parent
         */
        (reference, milestones, daysDifference, startIndex) => {
            for (let i = startIndex; i < milestones.length; i++) {
                const currentMilestone = milestones[i];

                // ? If we find a section witch is dependent on the parent section we update it
                if (currentMilestone.dependency === reference) {
                    // ? We update the section start date
                    currentMilestone.startDate = new Date(
                        new Date(currentMilestone.startDate).getTime() + daysDifference * ONE_DAY_IN_MILLISECONDS,
                    );

                    // ? We update the start date of the activities of the current section
                    updateMilestoneActivitiesDates(currentMilestone.activities, daysDifference);

                    // ? The function calls itself in order to update other sections depending on the current one (if we find any)
                    updateMilestonesDatesRecursively(currentMilestone.referenceKey, milestones, daysDifference, i + 1);
                }
            }
        },
        [],
    );

    const getMilestoneEndOffset = useCallback(
        /**
         * ? Finds the end date in milliseconds of a section
         * @param {object} section - The section for which to find the end date
         * @returns {number} The end date in milliseconds
         */
        (milestone) => {
            let endTimestamp = new Date(milestone.startDate).getTime() + ONE_DAY_IN_MILLISECONDS;
            if (milestone.activities.length > 0) {
                const activitiesTimeStamps = [];

                milestone.activities.forEach((a) => {
                    const activityEndOffset =
                        new Date(a.startDate).getTime() + a.realizationDays * ONE_DAY_IN_MILLISECONDS;
                    activitiesTimeStamps.push(activityEndOffset);
                });

                endTimestamp = Math.max(...activitiesTimeStamps);
            }

            return endTimestamp;
        },
        [],
    );

    const handleUpdateMilestoneDate = useCallback(
        /**
         * ? We update the section start date
         * @param {Date} value - The new date
         * @param {number} index - The index of the section
         */
        (value, index) => {
            setProjectStructure((prev) => {
                const copyOfMilestones = structuredClone(prev.milestones);
                const currentMilestone = copyOfMilestones[index];
                const initialStartTimestamp = new Date(currentMilestone.startDate).getTime();

                // ? Variables that holds the potential new start date in Date and milliseconds formats
                const newStartDate = new Date(new Date(value).toDateString());
                const newStartTimestamp = newStartDate.getTime();

                const daysDifference = (newStartTimestamp - initialStartTimestamp) / ONE_DAY_IN_MILLISECONDS;

                // ? If we get no new date (when daysDifference is 0) we return the current state and show a warning to the user
                if (!daysDifference) {
                    enqueueSnackbar(t('You selected the same date as the initial one!'), { variant: 'warning' });
                    return prev;
                }

                // ? This set process is needed in order to find what is the minimum new date we can set for the section
                // ? We set as the default, the end date in milliseconds as the start date of the project structure
                let referenceEndOffsetTimestamp = new Date(prev.startDate).getTime();
                // ? If the section is dependent on another one, we will set the end date to the end date of that section
                if (currentMilestone.dependency) {
                    const dependencyMilestone = copyOfMilestones.find(
                        (m) => m.referenceKey === currentMilestone.dependency,
                    );
                    referenceEndOffsetTimestamp = getMilestoneEndOffset(dependencyMilestone);
                }

                // ? If the new start date is less than the minimum date that we ca set we return the current state and display a 'error' message for the user
                if (newStartTimestamp < referenceEndOffsetTimestamp) {
                    enqueueSnackbar(
                        t(
                            "You can't set a date before {{date}} witch is the start date of the parent of this section!",
                            {
                                date: new Date(initialStartTimestamp).toLocaleDateString(),
                            },
                        ),
                        { variant: 'error' },
                    );

                    return prev;
                }

                // ? We set the new start date for this section
                currentMilestone.startDate = newStartDate;

                // ? We update all activities of the section to reflect the change of the start date
                updateMilestoneActivitiesDates(currentMilestone.activities, daysDifference);

                // ? We update the section tree for the sections that depend on this one
                updateMilestonesDatesRecursively(
                    currentMilestone.referenceKey,
                    copyOfMilestones,
                    daysDifference,
                    index,
                );

                enqueueSnackbar(
                    t(
                        'The start date has been updated to {{date}}. All the sections and activities that are related to this one will have their date updated accordingly!',
                        {
                            date: newStartDate.toLocaleDateString(),
                        },
                    ),
                    { variant: 'info' },
                );

                return { ...prev, milestones: copyOfMilestones };
            });
        },
        [getMilestoneEndOffset, updateMilestoneActivitiesDates, updateMilestonesDatesRecursively],
    );

    const handleUpdateMilestoneDependency = useCallback(
        /**
         * ? Updates the dependency of a section
         * @param {number} index - Index of the section
         * @param {number} rIndex - Index of the sections with will be parent of this section
         */
        (index, rIndex) => {
            setProjectStructure((prev) => {
                const copyOfMilestones = structuredClone(prev.milestones);
                const currentMilestone = copyOfMilestones[index];
                const referenceMilestone = copyOfMilestones.find((m) => m.id === copyOfMilestones[rIndex].id);

                // ? If the same dependency was selected again we return the current state and we display a warning message
                if (currentMilestone.dependency === referenceMilestone.referenceKey) {
                    enqueueSnackbar(t('You selected the same dependency as the initial one!'), { variant: 'warning' });
                    return prev;
                }

                const initialStartTimestamp = new Date(currentMilestone.startDate).getTime();
                const referenceEndOffsetTimestamp = getMilestoneEndOffset(referenceMilestone);

                const daysDifference = (referenceEndOffsetTimestamp - initialStartTimestamp) / ONE_DAY_IN_MILLISECONDS;

                //? We set the new dependency
                currentMilestone.dependency = referenceMilestone.referenceKey;

                // ? If the current section starts before the section on witch depends we will update the current section start date and the associated tree of sections
                if (daysDifference > 0) {
                    const newStartDate = new Date(referenceEndOffsetTimestamp);
                    currentMilestone.startDate = newStartDate;

                    // ? We update all activities of the section to reflect the change of the start date
                    updateMilestoneActivitiesDates(currentMilestone.activities, daysDifference);

                    // ? We update the section tree for the sections that depend on this one
                    updateMilestonesDatesRecursively(
                        currentMilestone.referenceKey,
                        copyOfMilestones,
                        daysDifference,
                        index,
                    );

                    enqueueSnackbar(
                        t(
                            'The start date has been updated to {{date}}. All the sections and activities that are related to this one will have their date updated accordingly!',
                            { date: newStartDate.toLocaleDateString() },
                        ),
                        { variant: 'info' },
                    );
                }

                return { ...prev, milestones: copyOfMilestones };
            });
        },
        [getMilestoneEndOffset, updateMilestoneActivitiesDates, updateMilestonesDatesRecursively],
    );

    const getActivityIndex = useCallback(
        /**
         * ? Returns the index of the activity based on the activity ID
         * @param {number} milestoneIndex - Index of the current milestone
         * @param {string} activityId - The ID of the activity we are looking for
         * @param {object} prev - The previous state of the project structure object (before updating it)
         * @returns {number} The index of the activity
         */
        (milestoneIndex, activityId, prev) =>
            prev.milestones[milestoneIndex].activities.findIndex((activity) => activity.id === activityId),
        [],
    );

    const handleAddActivity = useCallback(
        (milestoneId) => {
            setProjectStructure((prev) => {
                const { index, copyOfMilestones } = getMilestoneInfo(milestoneId, prev);
                const currentMilestone = copyOfMilestones[index];

                currentMilestone.activities = [
                    ...currentMilestone.activities,
                    {
                        id: generateUUID(),
                        name: '',
                        description: '',
                        startDate: new Date(currentMilestone.startDate),
                        realizationDays: 1,
                        initialStartDate: null,
                        initialRealizationDays: null,
                        isNew,
                        percent: 0,
                        referenceKey: generateUUID(),
                        dependency: null,
                        status: 'NEW',
                        forecastedExpenses: [],
                        forecastedRevenues: [],
                        estimatedExpenses: [],
                        estimatedRevenues: [],
                    },
                ];

                return { ...prev, milestones: copyOfMilestones };
            });
        },
        [isNew, getMilestoneInfo, getMilestoneEndOffset, updateMilestonesDatesRecursively],
    );

    const handleDeleteActivity = useCallback(
        /**
         * ? Deletes an activity
         * @param {string} milestoneId - Parent milestone ID
         * @param {string} activityId - ID of the activity to be deleted
         * @param {string} reference - The unique key used as an ID for the dependency logic
         */
        (milestoneId, activityId, reference) => {
            setProjectStructure((prev) => {
                const { index, copyOfMilestones } = getMilestoneInfo(milestoneId, prev);
                const currentMilestone = copyOfMilestones[index];

                // ? Removes all dependencies for the activities related to this one
                for (let i = 0; i < currentMilestone.activities.length; i++) {
                    if (currentMilestone.activities[i].dependency === reference)
                        currentMilestone.activities[i].dependency = null;
                }

                const initialMilestoneEndOffsetTimestamp = getMilestoneEndOffset(currentMilestone);

                // ? The actual removal of the activity
                currentMilestone.activities = currentMilestone.activities.filter(
                    (activity) => activity.id !== activityId,
                );

                const currentMilestoneEndOffsetTimesStamp = getMilestoneEndOffset(currentMilestone);

                // ? We check if the end date of the parent section has modified based on the deleted activity
                const milestonesDaysDifference =
                    (currentMilestoneEndOffsetTimesStamp - initialMilestoneEndOffsetTimestamp) /
                    ONE_DAY_IN_MILLISECONDS;

                // ? If there is a new end date we update the tree of the current section
                if (milestonesDaysDifference)
                    updateMilestonesDatesRecursively(
                        currentMilestone.referenceKey,
                        copyOfMilestones,
                        milestonesDaysDifference,
                        index,
                    );

                enqueueSnackbar(
                    t(
                        'The end date of the section has been updated to {{date}}. All the sections and activities that are related to this one will have their date updated accordingly!',
                        { date: new Date(currentMilestoneEndOffsetTimesStamp).toLocaleDateString() },
                    ),
                    { variant: 'info' },
                );

                return { ...prev, milestones: copyOfMilestones };
            });
        },
        [getMilestoneInfo],
    );

    const handleUpdateActivity = useCallback(
        (milestoneId, activityId, name, value) => {
            setProjectStructure((prev) => {
                const { index, copyOfMilestones } = getMilestoneInfo(milestoneId, prev);
                const aIndex = getActivityIndex(index, activityId, prev);

                copyOfMilestones[index].activities[aIndex][name] = value;

                return { ...prev, milestones: copyOfMilestones };
            });
        },
        [getMilestoneInfo, getActivityIndex],
    );

    const handleBatchedUpdateActivity = useCallback(
        /**
         * ? Updates a multitude of fields of the activity object
         * @param {string} milestoneId - We return the index based on the section ID
         * @param {string} activityId - We return the index based on the activity ID
         * @param {object} elements - Object of elements that represent the modified fields of the activity object
         */
        (milestoneId, activityId, elements) => {
            setProjectStructure((prev) => {
                const { index, copyOfMilestones } = getMilestoneInfo(milestoneId, prev);
                const aIndex = getActivityIndex(index, activityId, prev);

                for (const element of elements) {
                    copyOfMilestones[index].activities[aIndex][element.name] = element.value;
                }

                return { ...prev, milestones: copyOfMilestones };
            });
        },
        [getMilestoneInfo, getActivityIndex],
    );

    const updateActivitiesDatesRecursively = useCallback(
        /**
         * ? This function will update recursively the activity tree when the dependency field is equal to the reference
         * ? We start by updating the root activity, then we update all the activities that depend on this one, and this step is repeated for each activity in the previous step when one of the activity has other activity depending on it
         * @param {string} reference - The reference is the unique key of each activity (like an id), and we use is to find witch activity depend on it
         * @param {array} activities - All activities
         * @param {number} daysDifference - Days offset
         * @param {number} startIndex - We use the start index in order to avoid checking the activities that can't possibly have the previous activity as a parent
         */
        (reference, activities, daysDifference, startIndex) => {
            for (let i = startIndex; i < activities.length; i++) {
                const currentActivity = activities[i];

                // ? If we find a activity witch is dependent on the parent activity we update it
                if (currentActivity.dependency === reference) {
                    // ? We update the activity start date
                    currentActivity.startDate = new Date(
                        new Date(currentActivity.startDate).getTime() + daysDifference * ONE_DAY_IN_MILLISECONDS,
                    );

                    // ? The function calls itself in order to update other activities depending on the current one (if we find any)
                    if (currentActivity.referenceKey)
                        updateActivitiesDatesRecursively(
                            currentActivity.referenceKey,
                            activities,
                            daysDifference,
                            i + 1,
                        );
                }
            }
        },
        [],
    );

    const getActivityEndOffset = useCallback((activity) => {
        return new Date(activity.startDate).getTime() + activity.realizationDays * ONE_DAY_IN_MILLISECONDS;
    }, []);

    const handleUpdateActivityDate = useCallback(
        /**
         * ? We update the section start date
         * @param {Date} value - The new date
         * @param {string} milestoneId - Parent section ID
         * @param {number} index - The index of the activity
         */
        (value, milestoneId, index) => {
            setProjectStructure((prev) => {
                const { index: mIndex, copyOfMilestones } = getMilestoneInfo(milestoneId, prev);
                const currentMilestone = copyOfMilestones[mIndex];
                const currentActivity = copyOfMilestones[mIndex].activities[index];
                const initialStartTimestamp = new Date(currentActivity.startDate).getTime();

                // ? Variables that holds the potential new start date in Date and milliseconds formats
                const newStartDate = new Date(new Date(value).toDateString());
                const newStartTimestamp = newStartDate.getTime();

                const daysDifference = (newStartTimestamp - initialStartTimestamp) / ONE_DAY_IN_MILLISECONDS;

                // ? If we get no new date (when daysDifference is 0) we return the current state and show a warning to the user
                if (!daysDifference) {
                    enqueueSnackbar(t('You selected the same date as the initial one!'), { variant: 'warning' });
                    return prev;
                }

                // ? This set process is needed in order to find what is the minimum new date we can set for the activity
                // ? We set as the default, the end date in milliseconds as the start date of parent section
                let referenceEndOffsetTimestamp = new Date(currentMilestone.startDate).getTime();
                // ? If the activity is dependent on another one, we will set the end date to the end date of that activity
                if (currentActivity.dependency) {
                    const dependencyActivity = currentMilestone.activities.find(
                        (a) => a.referenceKey === currentActivity.dependency,
                    );
                    referenceEndOffsetTimestamp = getActivityEndOffset(dependencyActivity);
                }

                // ? If the new start date is less than the minimum date that we ca set we return the current state and display a 'error' message for the user
                if (newStartTimestamp < referenceEndOffsetTimestamp) {
                    enqueueSnackbar(
                        t(
                            "You can't set a date before {{date}} witch is the start date of the parent of this section!",
                            {
                                date: new Date(initialStartTimestamp).toLocaleDateString(),
                            },
                        ),
                        { variant: 'error' },
                    );

                    return prev;
                }

                // ? Variable that holds the initial end date of the parent section
                const initialMilestoneEndOffsetTimestamp = getMilestoneEndOffset(currentMilestone);

                // ? We set the new start date for this activity
                currentActivity.startDate = newStartDate;

                // ? We update the activity tree for the activities that depend on this one
                updateActivitiesDatesRecursively(
                    currentActivity.referenceKey,
                    currentMilestone.activities,
                    daysDifference,
                    index,
                );
                // ? Variable that holds the new end date of the parent section
                const currentMilestoneEndOffsetTimesStamp = getMilestoneEndOffset(currentMilestone);

                const milestonesDaysDifference =
                    (currentMilestoneEndOffsetTimesStamp - initialMilestoneEndOffsetTimestamp) /
                    ONE_DAY_IN_MILLISECONDS;

                // ? If the end time of the parent section has changed we will update the tree of this section
                if (milestonesDaysDifference)
                    updateMilestonesDatesRecursively(
                        currentMilestone.referenceKey,
                        copyOfMilestones,
                        milestonesDaysDifference,
                        mIndex,
                    );

                enqueueSnackbar(
                    t(
                        'The start date has been updated to {{date}}. All the sections and activities that are related to this one will have their date updated accordingly!',
                        { date: newStartDate.toLocaleDateString() },
                    ),
                    { variant: 'info' },
                );

                return { ...prev, milestones: copyOfMilestones };
            });
        },
        [
            getMilestoneInfo,
            getMilestoneEndOffset,
            getActivityEndOffset,
            updateActivitiesDatesRecursively,
            updateMilestonesDatesRecursively,
        ],
    );

    const handleUpdateActivityDependency = useCallback(
        /**
         * ? Updates the dependency of a section
         * @param {string} milestoneId - ID of the parent milestone
         * @param {number} index - Index of the section
         * @param {number} rIndex - Index of the sections with will be parent of this section
         */
        (milestoneId, index, rIndex) => {
            setProjectStructure((prev) => {
                const { index: mIndex, copyOfMilestones } = getMilestoneInfo(milestoneId, prev);
                const currentMilestone = copyOfMilestones[mIndex];
                const currentActivity = copyOfMilestones[mIndex].activities[index];
                const referenceActivity = currentMilestone.activities.find(
                    (a) => a.id === currentMilestone.activities[rIndex].id,
                );

                // ? If the same dependency was selected again we return the current state and we display a warning message
                if (currentActivity.dependency === referenceActivity.referenceKey) {
                    enqueueSnackbar(t('You selected the same dependency as the initial one!'), { variant: 'warning' });
                    return prev;
                }

                const initialStartTimestamp = new Date(currentActivity.startDate).getTime();

                const referenceEndOffsetTimestamp = getActivityEndOffset(referenceActivity);
                const daysDifference = (referenceEndOffsetTimestamp - initialStartTimestamp) / ONE_DAY_IN_MILLISECONDS;

                // ? We update the dependency
                currentActivity.dependency = referenceActivity.referenceKey;

                // ? If the current activity starts before the activity on witch depends we will update the current activity start date and the associated tree of activities
                if (daysDifference > 0) {
                    const newStartDate = new Date(referenceEndOffsetTimestamp);

                    const initialMilestoneEndOffsetTimestamp = getMilestoneEndOffset(currentMilestone);

                    // ? We set the new start date for the current activity
                    currentActivity.startDate = newStartDate;

                    // ? We update the activity tree for the sections that depend on this one
                    updateActivitiesDatesRecursively(
                        currentActivity.referenceKey,
                        currentMilestone.activities,
                        daysDifference,
                        index,
                    );
                    const currentMilestoneEndOffsetTimesStamp = getMilestoneEndOffset(currentMilestone);

                    const milestonesDaysDifference =
                        (currentMilestoneEndOffsetTimesStamp - initialMilestoneEndOffsetTimestamp) /
                        ONE_DAY_IN_MILLISECONDS;

                    // ? If the end time of the parent section has changed we will update the tree of this section
                    if (milestonesDaysDifference)
                        updateMilestonesDatesRecursively(
                            currentMilestone.referenceKey,
                            copyOfMilestones,
                            milestonesDaysDifference,
                            mIndex,
                        );

                    enqueueSnackbar(
                        t(
                            'The start date has been updated to {{date}}. All the sections and activities that are related to this one will have their date updated accordingly!',
                            { date: newStartDate.toLocaleDateString() },
                        ),
                        { variant: 'info' },
                    );
                }

                return { ...prev, milestones: copyOfMilestones };
            });
        },
        [
            getMilestoneInfo,
            getMilestoneEndOffset,
            getActivityEndOffset,
            updateActivitiesDatesRecursively,
            updateMilestonesDatesRecursively,
        ],
    );

    const handleUpdateActivityRealizationDays = useCallback(
        /**
         * ? We update the realization days of an activity
         * @param {number} value - New realizations days
         * @param {string} milestoneId - ID of the parent section
         * @param {number} index - Index of the current activity
         */
        (value, milestoneId, index) => {
            setProjectStructure((prev) => {
                const { index: mIndex, copyOfMilestones } = getMilestoneInfo(milestoneId, prev);
                const currentMilestone = copyOfMilestones[mIndex];
                const currentActivity = copyOfMilestones[mIndex].activities[index];

                // ? If realization days are 0 or '' we will return the current state and display a warning to the user
                if (!value) {
                    enqueueSnackbar(t('Every activity must have at least one realization day!'), { variant: 'error' });
                    return prev;
                }

                // ? If we get no new realization days (currentActivity.rea) we return the current state and show a warning to the user
                if (Number(value) === currentActivity.realizationDays) {
                    enqueueSnackbar(t('You introduced the same number of realization days!'), { variant: 'warning' });
                    return prev;
                }

                const initialEndTimeStamp = getActivityEndOffset(currentActivity) - ONE_DAY_IN_MILLISECONDS;
                const newEndTimestamp =
                    new Date(currentActivity.startDate).getTime() + (value - 1) * ONE_DAY_IN_MILLISECONDS;
                const daysDifference = (newEndTimestamp - initialEndTimeStamp) / ONE_DAY_IN_MILLISECONDS;

                const newEndDate = new Date(newEndTimestamp);

                const initialMilestoneEndOffsetTimestamp = getMilestoneEndOffset(currentMilestone);

                // ? We set the new realization days
                currentActivity.realizationDays = value;

                // ? We update the activity tree for the sections that depend on this one
                updateActivitiesDatesRecursively(
                    currentActivity.referenceKey,
                    currentMilestone.activities,
                    daysDifference,
                    index,
                );
                const currentMilestoneEndOffsetTimesStamp = getMilestoneEndOffset(currentMilestone);

                const milestonesDaysDifference =
                    (currentMilestoneEndOffsetTimesStamp - initialMilestoneEndOffsetTimestamp) /
                    ONE_DAY_IN_MILLISECONDS;

                // ? We update the activity tree for the sections that depend on this one
                if (milestonesDaysDifference)
                    updateMilestonesDatesRecursively(
                        currentMilestone.referenceKey,
                        copyOfMilestones,
                        milestonesDaysDifference,
                        mIndex,
                    );

                enqueueSnackbar(
                    t(
                        'The end date has been updated to {{date}}. All the sections and activities that are related to this one will have their date updated accordingly!',
                        { date: newEndDate.toLocaleDateString() },
                    ),
                    { variant: 'info' },
                );

                return { ...prev, milestones: copyOfMilestones };
            });
        },
        [
            getMilestoneInfo,
            getActivityEndOffset,
            getMilestoneEndOffset,
            updateActivitiesDatesRecursively,
            updateMilestonesDatesRecursively,
        ],
    );

    /**
     * ? Array of milestones that were used before when creating a new section
     * @returns {array} milestones
     */
    const getCurrentHiddenOptions = () =>
        projectStructure.milestones.reduce((hoe, curr) => {
            const milestoneIndex = allMilestones.findIndex((aM) => aM.id === curr.milestoneId);
            if (milestoneIndex >= 0) return [...hoe, milestoneIndex];
            else return hoe;
        }, []);

    const schema = yup.object().shape({
        name: yup
            .string()
            .min(3, t('The project structure name length must be at least 3 characters long'))
            .required('The project structure name is required'),
        milestones: yup
            .array()
            .of(
                yup.object().shape({
                    name: yup
                        .string()
                        .min(3, t('The section name length must be at least 3 characters long'))
                        .required('The section name is required'),
                    activities: yup
                        .array()
                        .of(
                            yup.object().shape({
                                name: yup
                                    .string()
                                    .min(3, t('The activity name length must be at least 3 characters long'))
                                    .required('The activity name is required'),
                                realizationDays: yup
                                    .number()
                                    .min(1, t('The activity realization period must be at least one day'))
                                    .required(t('The activity realization days are required')),
                            }),
                        )
                        .min(1, t('The section must have at least one activity'))
                        .required(t('The section must have at least one activity')),
                }),
            )
            .min(1, t('The project structure must have at least one section'))
            .required(t('The project structure must have at least one section')),
    });

    /**
     * ? Updates the status of project structure
     * @param {string} e - new status
     */
    const handleSetNewStatus = async (e) => {
        try {
            setIsCreating(true);

            await API.patch('projectStructure', undefined, {
                params: {
                    id: memoizedProjectStructureInfo.id,
                    status: statuses[selectedStatus],
                },
            });

            setSelectedStatus(e);

            enqueueSnackbar(t('Project structure status was updated successfully!'), { variant: 'success' });
        } catch (error) {
            console.error(error);
        } finally {
            setIsCreating(false);
        }
    };

    const handleUpdateProjectStructure = async () => {
        try {
            /**
             * ? This function is used to restructure the expenses and revenues arrays for the backend
             * @param {array} array - The array to be modified
             * @param {string} key - The key represents the field id for an object
             * @returns Modified array
             */
            const mappedArrays = (array, key) => {
                return array.flatMap((el) => {
                    return el.list.map((e) => ({
                        grandParentId: el.grandParentId,
                        value: e.value,
                        secondCurrencyValue: e.secondCurrencyValue,
                        [key]: e.id,
                    }));
                });
            };

            const copyOfProjectStructure = structuredClone(projectStructure);
            const formattedData = {
                ...copyOfProjectStructure,
                milestones: copyOfProjectStructure.milestones.map((m) => {
                    const newMilestone = {
                        ...m,
                        activities: m.activities.map((a) => {
                            const newActivity = {
                                ...a,
                                ForecastedExpenses: mappedArrays(a.forecastedExpenses, 'expensesNameId'),
                                EstimatedExpenses: mappedArrays(a.estimatedExpenses, 'expensesNameId'),
                                ForecastedRevenues: mappedArrays(a.forecastedRevenues, 'revenueNamesId'),
                                EstimatedRevenues: mappedArrays(a.estimatedRevenues, 'revenueNamesId'),
                            };

                            delete newActivity.forecastedExpenses;
                            delete newActivity.estimatedExpenses;
                            delete newActivity.forecastedRevenues;
                            delete newActivity.estimatedRevenues;

                            return newActivity;
                        }),
                    };

                    return newMilestone;
                }),
            };

            setIsCreating(true);
            await schema.validate(formattedData);
            await API.put('projectStructures', formattedData);
            enqueueSnackbar(t('Project structure was update successfully!'), { variant: 'success' });

            history.push(internalActivity.base + internalActivity.projectStructure.base);
        } catch (error) {
            enqueueSnackbar(error.errors[0], { variant: 'error' });
        } finally {
            setIsCreating(false);
        }
    };

    const handleAddProjectStructure = async () => {
        try {
            /**
             * ? This function is used to restructure the expenses and revenues arrays for the backend
             * @param {array} array - The array to be modified
             * @param {string} key - The key represents the field id for an object
             * @returns Modified array
             */
            const mappedArrays = (array, key) => {
                return array.flatMap((el) => {
                    return el.list.map((e) => ({
                        grandParentId: el.grandParentId,
                        value: e.value,
                        secondCurrencyValue: e.secondCurrencyValue,
                        [key]: e.id,
                    }));
                });
            };

            const copyOfProjectStructure = structuredClone(projectStructure);
            const formattedData = {
                ...copyOfProjectStructure,
                milestones: copyOfProjectStructure.milestones.map((m) => {
                    const newMilestone = {
                        ...m,
                        activities: m.activities.map((a) => {
                            const newActivity = {
                                ...a,
                                ForecastedExpenses: mappedArrays(a.forecastedExpenses, 'expensesNameId'),
                                EstimatedExpenses: mappedArrays(a.estimatedExpenses, 'expensesNameId'),
                                ForecastedRevenues: mappedArrays(a.forecastedRevenues, 'revenueNamesId'),
                                EstimatedRevenues: mappedArrays(a.estimatedRevenues, 'revenueNamesId'),
                            };

                            delete newActivity.forecastedExpenses;
                            delete newActivity.estimatedExpenses;
                            delete newActivity.forecastedRevenues;
                            delete newActivity.estimatedRevenues;
                            delete newActivity.id;

                            return newActivity;
                        }),
                    };

                    delete newMilestone.id;
                    return newMilestone;
                }),
            };
            delete formattedData.id;

            setIsCreating(true);

            await schema.validate(formattedData);
            await API.post('projectStructures', formattedData);

            enqueueSnackbar(t('Project structure was added successfully!'), { variant: 'success' });

            history.push(internalActivity.base + internalActivity.projectStructure.base);
        } catch (error) {
            enqueueSnackbar(error.errors[0], { variant: 'error' });
        } finally {
            setIsCreating(false);
        }
    };

    const handleUpdateExchangeRate = (e) => {
        const value = formatExchangeRate(e.target.value);

        /**
         * ? Updates the second value of the expenses or incomes in the given array
         * @param {array} elements - Array to be modified
         * @returns {array} Modified array
         */
        const updateSecondValue = (elements) =>
            elements.map((el) => ({
                ...el,
                list: el.list.map((l) => ({
                    ...l,
                    secondCurrencyValue: l.value / value,
                })),
            }));

        const newMilestones = projectStructure.milestones.map((m) => ({
            ...m,
            activities: m.activities.map((a) => {
                const newForecastedExpenses = updateSecondValue(structuredClone(a.forecastedExpenses));
                const newForecastedRevenues = updateSecondValue(structuredClone(a.forecastedRevenues));
                const newEstimatedExpenses = updateSecondValue(structuredClone(a.estimatedExpenses));
                const newEstimatedRevenues = updateSecondValue(structuredClone(a.estimatedRevenues));

                return {
                    ...a,
                    forecastedExpenses: newForecastedExpenses,
                    forecastedRevenues: newForecastedRevenues,
                    estimatedExpenses: newEstimatedExpenses,
                    estimatedRevenues: newEstimatedRevenues,
                };
            }),
        }));

        handleBatchedChangeProjectStructure({
            [e.target.name]: value,
            manualExchange: value !== apiExchangeRate,
            milestones: newMilestones,
        });
    };

    /**
     * ? This method is used to set the exchange rate info on the project structure
     * ? We set this data only when we are manually selecting a new date for the exchange rate
     * @param {Date} e - New date
     * @param {boolean} canUpdateData - Indicates if can update the project structure object
     * @param {boolean} canThrow - Indicates if we can throw the error to the next try/catch block
     */
    const getExchangeRate = async (e, canUpdateData = true, canThrow = false) => {
        try {
            let selectedDate = new Date(e);
            if (selectedDate > new Date()) {
                enqueueSnackbar(t("The selected date can't be greater then the current date!"), {
                    variant: 'error',
                });
                selectedDate = new Date();
            }

            if (canUpdateData) handleChangeProjectStructure({ target: { name: 'exchangeDate', value: selectedDate } });
            setLoadingExchangeRate(true);

            const res = await API.get('currencyByDate', {
                params: {
                    date: selectedDate,
                },
            });

            const { rates } = res.data.data;

            if (canUpdateData) handleUpdateExchangeRate({ target: { name: 'exchangeRate', value: +rates } });
            setApiExchangeRate(+rates);
        } catch (error) {
            if (canThrow) throw new Error(error);
            else console.error(error);
        } finally {
            setLoadingExchangeRate(false);
        }
    };

    const getMilestones = async () => {
        const resMilestones = await API.get('/configurator-all-milestones', {
            params: {
                perPage: 99999,
                currentPage: 0,
                pagesToLoad: 1,
            },
        });

        setAllMilestones(resMilestones.data.data.data);
    };

    const getAllExpenses = async () => {
        let expenses = [];

        await Expenses.get(0, 999999).then((res) => {
            if (res.ok) {
                expenses = res.data.expenseNames;
                setAllExpenses(expenses);
            } else {
                console.error(res.error);
            }
        });

        return expenses;
    };

    const getAllRevenues = async () => {
        let revenues = [];

        await Revenues.get(999999, 0).then((res) => {
            if (res.ok) {
                revenues = res.data.revenuName;
                setAllRevenues(revenues);
            } else {
                console.error(res.error);
            }
        });

        return revenues;
    };

    const getAllAccountingAccounts = async () => {
        await AccountingAccounts.getAll().then((res) => {
            if (res.ok) {
                setAccounts(res.data);
            } else {
                console.error(res.error);
                enqueueSnackbar(
                    errorHandling(res.error).length > 100 ? errorHandling(res.error) : t(errorHandling(res.error)),
                    {
                        variant: 'error',
                    },
                );
            }
        });
    };

    useEffect(() => {
        (async () => {
            try {
                const [returnedExpenses, returnedRevenues] = await Promise.all([
                    getAllExpenses(),
                    getAllRevenues(),
                    getAllAccountingAccounts(),
                    getMilestones(),
                    // ? We only get the exchange rate reference when we are editing a project structure
                    getExchangeRate(new Date(), memoizedProjectStructureInfo ? false : true, true),
                ]);

                // ? If we have a project structure to edit we need to restructure the project structure object to the frontend needs
                if (memoizedProjectStructureInfo) {
                    /**
                     * ? Returns a modified array of expenses or revenues
                     * @param {array} array - Array to be modified
                     * @param {array} data - Array of expenses or revenues
                     * @param {string} key - Represents the key for the id in the expense or revenue object
                     * @returns {Array} Modified expenses or revenues
                     */
                    const mutateArray = (array, data, key) => {
                        return array.reduce((acc, e) => {
                            // ? We try to find if the grand parent of the expense or revenue already exists
                            const grandParentIdIndex = acc.findIndex(
                                (accEl) => accEl.grandParentId === e.grandParentId,
                            );

                            // ? If the grand parent exists we push the new expense or revenue into the grand parent list
                            if (grandParentIdIndex >= 0) {
                                const newAcc = structuredClone(acc);
                                newAcc[grandParentIdIndex].list.push({
                                    value: e.value,
                                    secondCurrencyValue: e.secondCurrencyValue,
                                    id: e[key],
                                });

                                return newAcc;
                            }

                            // ? If the grand parent does't exists we create a new entry in the array for him and the respective expense or revenue
                            return [
                                ...acc,
                                {
                                    selectionIndex: data.findIndex((el) => el.id === e.grandParentId),
                                    grandParentId: e.grandParentId,
                                    list: [
                                        {
                                            value: e.value,
                                            secondCurrencyValue: e.secondCurrencyValue,
                                            id: e[key],
                                        },
                                    ],
                                },
                            ];
                        }, []);
                    };

                    const copyOfProjectStructureInfo = structuredClone(memoizedProjectStructureInfo);

                    const formattedData = {
                        ...copyOfProjectStructureInfo,
                        milestones: copyOfProjectStructureInfo.milestones.map((m) => {
                            const newMilestone = {
                                ...m,
                                activities: m.activities.map((a) => {
                                    const newActivity = {
                                        ...a,
                                        forecastedExpenses: mutateArray(
                                            a.ForecastedExpenses,
                                            returnedExpenses,
                                            'expensesNameId',
                                        ),
                                        forecastedRevenues: mutateArray(
                                            a.ForecastedRevenues,
                                            returnedRevenues,
                                            'revenueNamesId',
                                        ),
                                        estimatedExpenses: mutateArray(
                                            a.EstimatedExpenses,
                                            returnedExpenses,
                                            'expensesNameId',
                                        ),
                                        estimatedRevenues: mutateArray(
                                            a.EstimatedRevenues,
                                            returnedRevenues,
                                            'revenueNamesId',
                                        ),
                                    };

                                    delete newActivity.ForecastedExpenses;
                                    delete newActivity.ForecastedRevenues;
                                    delete newActivity.EstimatedExpenses;
                                    delete newActivity.EstimatedRevenues;

                                    return newActivity;
                                }),
                            };

                            return newMilestone;
                        }),
                    };

                    setProjectStructure(formattedData);
                }
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
                if (isInitialLoad) setIsInitialLoad(false);
            }
        })();
    }, [memoizedProjectStructureInfo]);

    // !!! The object used as value for the context must be memoized in order to avoid expensive renders !!!
    const projectContextValue = useMemo(
        () => ({
            canEdit,
            projectStructureInfo: memoizedProjectStructureInfo,
            isLoading: loadingExchangeRate || isCreating,
            onDeleteMilestone: handleDeleteMilestone,
            onUpdateMilestone: handleUpdateMilestone,
            onAddActivity: handleAddActivity,
            onDeleteActivity: handleDeleteActivity,
            onUpdateActivity: handleUpdateActivity,
            onBatchedUpdateActivity: handleBatchedUpdateActivity,
            onBatchedUpdateMilestone: handleBatchedUpdateMilestone,
            allExpenses: memoizedAllExpenses,
            allRevenues: memoizedAllRevenues,
            accounts: memoizedAccount,
            exchangeRate: projectStructure.exchangeRate,
            onUpdateMilestoneDate: handleUpdateMilestoneDate,
            onUpdateMilestoneDependency: handleUpdateMilestoneDependency,
            onUpdateActivityDate: handleUpdateActivityDate,
            onUpdateActivityDependency: handleUpdateActivityDependency,
            onUpdateActivityRealizationDays: handleUpdateActivityRealizationDays,
        }),
        [
            canEdit,
            memoizedProjectStructureInfo,
            loadingExchangeRate,
            isCreating,
            handleDeleteMilestone,
            handleUpdateMilestone,
            handleAddActivity,
            handleDeleteActivity,
            handleUpdateActivity,
            handleBatchedUpdateActivity,
            handleBatchedUpdateMilestone,
            memoizedAllExpenses,
            memoizedAllRevenues,
            memoizedAccount,
            projectStructure.exchangeRate,
            handleUpdateMilestoneDate,
            handleUpdateMilestoneDependency,
            handleUpdateActivityDate,
            handleUpdateActivityDependency,
            handleUpdateActivityRealizationDays,
        ],
    );

    if (loading && isInitialLoad)
        return (
            <div className="flex h-64 w-full items-center justify-center bg-layout-main">
                <CircularProgress />
            </div>
        );

    return (
        <ProjectContext.Provider value={projectContextValue}>
            <div className={`flex flex-col gap-8 ${isCreating ? 'pointer-events-none' : ''}`}>
                <div
                    className="flex flex-col gap-4 rounded-sm p-4"
                    style={{ backgroundColor: 'rgb(var(--base-layout-transparent) / 30%)' }}
                >
                    <h2 className="text-3xl text-main-text">{t('Project structure legend')}</h2>
                    <div className="grid grid-cols-2 gap-x-2 gap-y-2">
                        {legendData.map((item) => (
                            <div key={item.id} className="flex flex-col gap-2">
                                <h3 className="text-2xl text-main-text">{t(item.info)}</h3>
                                <div
                                    className="grid items-start gap-4"
                                    style={{
                                        gridTemplateColumns: 'repeat(auto-fill, 200px)',
                                    }}
                                >
                                    {item.subItems.map((subItem) => (
                                        <div className="flex items-center gap-2" key={subItem.id}>
                                            <div
                                                className="h-8 w-12 flex-shrink-0 rounded-sm"
                                                style={{
                                                    backgroundColor: subItem.color,
                                                }}
                                            />
                                            &mdash;
                                            <p className="text-xl text-main-text">{t(subItem.info)}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="flex gap-16 xl:flex-col-reverse">
                    {loading ? (
                        <div className="flex h-64 w-full items-center justify-center bg-layout-main">
                            <CircularProgress />
                        </div>
                    ) : (
                        <div
                            className={`flex flex-col gap-4 xl:w-full sm:gap-8 ${
                                memoizedProjectStructureInfo ? 'w-1/2' : 'w-1/2 xl:w-full'
                            }`}
                        >
                            <div className="inline-flex gap-2 sm:flex-col">
                                <LabelWrapper label={t('Project structure name')}>
                                    <TextField
                                        disabled={!canEdit}
                                        name="name"
                                        placeholder={t('Project structure name')}
                                        value={projectStructure.name}
                                        onChange={handleChangeProjectStructure}
                                    />
                                </LabelWrapper>

                                <div className="inline-flex">
                                    <LabelWrapper label={t('Start date')}>
                                        <DatePicker
                                            disabled={!canEdit}
                                            date={projectStructure.startDate}
                                            setDate={handleUpdateProjectStructureStartDate}
                                        />
                                    </LabelWrapper>
                                </div>
                                <div className="inline-flex">
                                    <LabelWrapper label={'Status'}>
                                        <Dropdown
                                            disabled={!memoizedProjectStructureInfo || !canEdit}
                                            icon={<EditIcon />}
                                            options={statuses.map((status) => t(status.split('_').join(' ')))}
                                            selectedOption={selectedStatus}
                                            setSelectedOption={handleSetNewStatus}
                                        />
                                    </LabelWrapper>
                                </div>
                            </div>
                            <div className="inline-flex gap-2 sm:flex-col">
                                <LabelWrapper label={t('Select the date for the exchange rate')}>
                                    <DatePicker
                                        disabled={loadingExchangeRate || isCreating || !canEdit}
                                        date={projectStructure.exchangeDate}
                                        setDate={getExchangeRate}
                                    />
                                </LabelWrapper>
                                <LabelWrapper label={t('Exchange rate')}>
                                    <LocaleTextField
                                        name="exchangeRate"
                                        disabled={loadingExchangeRate || isCreating || !canEdit}
                                        placeholder={`${toLocaleNumber(0, language, 4, 4)}`}
                                        value={projectStructure.exchangeRate}
                                        onChange={handleUpdateExchangeRate}
                                        minDecimals={4}
                                        maxDecimals={4}
                                        className="w-full"
                                    />
                                </LabelWrapper>
                            </div>

                            <div className={`flex flex-col gap-2`}>
                                {projectStructure.description && (
                                    <LabelWrapper label={t('Description')}>
                                        <iframe
                                            className="h-40 w-full rounded-md border border-layout-transparent bg-white p-2"
                                            srcDoc={`<base target="_blank" /> ${DOMPurify.sanitize(
                                                projectStructure.description,
                                            )}`}
                                        />
                                    </LabelWrapper>
                                )}

                                <div
                                    className={`inline-flex ${
                                        projectStructure.description
                                            ? 'self-end'
                                            : 'w-full justify-center rounded-md bg-layout-transparent p-2'
                                    }`}
                                >
                                    <Button color="primary" onClick={handleOpenModal} disabled={!canEdit}>
                                        {t(memoizedProjectStructureInfo ? 'Edit description' : 'Add description')}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    )}
                    {memoizedProjectStructureInfo && historyLength > 1 && (
                        <div className="flex w-1/2 flex-col items-center justify-center gap-4 rounded-sm bg-layout-lighter p-4 xl:w-full">
                            <h2 className="text-3xl text-main-text">{t('Project structure history')}</h2>
                            <Pagination
                                onChange={(_, p) => getNewProjectStructure(p)}
                                page={projectStructureSelection}
                                disabled={isCreating || loading}
                                count={historyLength}
                                showFirstButton
                                showLastButton
                                size={width > 600 ? 'large' : 'small'}
                            />
                        </div>
                    )}
                </div>

                {loading ? (
                    <div className="flex h-64 w-full items-center justify-center bg-layout-main">
                        <CircularProgress />
                    </div>
                ) : (
                    <div className="relative flex flex-col gap-8">
                        <ProjectStructureMilestonesWrapper
                            sections={projectStructure.milestones}
                            allMilestones={memoizedAllMilestones}
                        />

                        {canEdit && (
                            <div className="max-w-max" style={{ zIndex: 1 }}>
                                <Button startIcon={<AddIcon />} onClick={handleOpenSectionModal}>
                                    {t('Add section')}
                                </Button>
                            </div>
                        )}
                    </div>
                )}

                {canEdit && (
                    <div className="mt-8 flex flex-col gap-8">
                        <div className="inline-flex">
                            {memoizedProjectStructureInfo ? (
                                <Button
                                    disabled={isCreating || loading}
                                    type="submit"
                                    startIcon={<CheckIcon />}
                                    color="primary"
                                    onClick={handleUpdateProjectStructure}
                                >
                                    {t('Update Project structure')}
                                </Button>
                            ) : (
                                <Button
                                    disabled={isCreating || loading}
                                    startIcon={<AddIcon />}
                                    color="primary"
                                    onClick={handleAddProjectStructure}
                                >
                                    {t('Create Project structure')}
                                </Button>
                            )}
                        </div>
                    </div>
                )}
            </div>
            {open && (
                <CustomModal open={open}>
                    <EditElementModal
                        onClose={handleCloseModal}
                        value={projectStructure.description}
                        onFinish={handleUpdateProjectStructureDescription}
                    />
                </CustomModal>
            )}

            {openSectionModal && (
                <CustomModal open={openSectionModal}>
                    <ProjectStructureAddSectionModal
                        onClose={handleCloseSectionModal}
                        onFinish={handleAddMilestone}
                        options={allMilestones}
                        hiddenOptions={getCurrentHiddenOptions()}
                    />
                </CustomModal>
            )}
        </ProjectContext.Provider>
    );
};

ProjectStructureForm.propTypes = {
    view: PropTypes.bool,
    editable: PropTypes.bool,
    projectStructureInfo: PropTypes.object,
    originalId: PropTypes.string,
    loading: PropTypes.bool,
    setLoading: PropTypes.func,
    historyLength: PropTypes.number,
    projectStructureSelection: PropTypes.number,
    getNewProjectStructure: PropTypes.func,
    isInitialLoad: PropTypes.bool,
    setIsInitialLoad: PropTypes.func,
};

ProjectStructureForm.defaultProps = {
    view: true,
    editable: true,
    projectStructureInfo: null,
    originalId: null,
    loading: false,
    setLoading: () => {},
    historyLength: 1,
    projectStructureSelection: 1,
    getNewProjectStructure: () => {},
    isInitialLoad: true,
    setIsInitialLoad: () => {},
};

export default ProjectStructureForm;
