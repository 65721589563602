import React, { useEffect, useMemo, useState } from 'react';

import CheckIcon from '@material-ui/icons/Check';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import Comments from 'components/shared/comments/comments';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { fileNameFromKey, formatDate, linkStyle } from 'utils';

import PMTaskManagementTaskDisplay from './pm-task-management-task-display';

const PMTaskManagementSubmilestoneDisplay = (props) => {
    const { t } = useTranslation();
    const {
        submilestone,
        expandable,
        userIsResponsible,
        canViewComments,
        canAllComments,
        onAddComment,
        onDeleteComment,
    } = props;
    const [checked, setChecked] = useState(false);
    const [open, setOpen] = useState(false);

    const formattedComments = useMemo(
        () =>
            submilestone.comments.map((element) => ({
                ...element,
                text: element.comment,
                createdAt: element.createAt,
                files: element.files.map((file) => ({
                    ...file,
                    id: file.fileId,
                    url: file.urls,
                    fileName: fileNameFromKey(file.file.key),
                })),
            })),
        [submilestone.comments],
    );

    /**
     * Set the state of checked to display a checked icon on activity
     */
    useEffect(() => {
        setChecked(submilestone.status === 'DONE');
    }, [submilestone.status]);

    return (
        <>
            <div className="relative mb-1 rounded-xl border border-layout-transparent bg-layout-transparent px-6 py-4 text-main-text">
                {/* Action arrow */}
                {expandable && (
                    <div
                        className="absolute right-2 top-2 flex h-8 w-8 cursor-pointer items-center justify-center rounded-full hover:bg-layout-transparent"
                        onClick={() => setOpen(!open)}
                    >
                        <KeyboardArrowDownIcon
                            fontSize="medium"
                            style={{
                                transition: 'transform .2s ease',
                            }}
                            className={`transform text-main-text  ${open ? 'rotate-180' : 'rotate-0'}`}
                        />
                    </div>
                )}

                <div>
                    <div className="no-user-select-recursive mb-2 flex cursor-pointer items-center">
                        <div
                            className={`relative mr-4 h-5 w-5 flex-shrink-0 rounded-full border-primary-main transition-colors ${
                                checked ? 'bg-primary-main' : ''
                            }`}
                            style={{ borderWidth: '1px' }}
                        >
                            <CheckIcon
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    fontSize: '0.8rem',
                                }}
                                className={`${
                                    checked ? 'opacity-100' : 'opacity-0'
                                } text-buttons-text transition-opacity`}
                            />
                        </div>
                        <h4>{submilestone.name}</h4>
                    </div>

                    {/* Deadline */}
                    <p className="no-user-select-recursive pointer-events-none pl-9">
                        <span className="text-dark-text">{t('Deadline')}: </span>
                        <span className="font-semibold text-main-text">
                            {formatDate(submilestone.estimatedEndDate)}
                        </span>
                    </p>
                </div>

                {open && (
                    <div className="mt-4 rounded-md bg-layout-transparent px-6 py-4">
                        <div className="mb-4 flex justify-between gap-5">
                            <p className="w-1/4 md:w-full">
                                <span className="no-user-select-recursive pointer-events-none text-dark-text">
                                    {t('Responsible')}:
                                </span>
                                <br />
                                {submilestone?.responsible.map((responsible) => (
                                    <span
                                        key={responsible.id}
                                        className="mr-4 break-all font-semibold text-main-text md:mr-1"
                                    >
                                        {responsible.user.email}
                                    </span>
                                ))}
                            </p>
                        </div>

                        {/* <p className="mb-4">
                            <span className="no-user-select-recursive pointer-events-none opacity-50">
                                Dependent de:
                            </span>
                            <br />
                            <span className={`font-semibold ${linkStyle}`}>
                                Milestone Name A, Sub-Milestone Name B2
                            </span>
                        </p> */}
                        <p>
                            <span className="no-user-select-recursive pointer-events-none text-dark-text">
                                {t('Timeline')}:
                            </span>
                            <br />
                            <span className={`font-semibold ${linkStyle}`}>
                                {formatDate(submilestone.estimatedStartDate)} -{' '}
                                {formatDate(submilestone.estimatedEndDate)}
                            </span>
                        </p>
                    </div>
                )}
            </div>

            {/* Comment section */}
            {canViewComments && (
                <div className="pb-4">
                    <Comments
                        comments={formattedComments}
                        canAdd={canAllComments}
                        canDelete={canAllComments}
                        onSubmit={onAddComment.bind(null, 'subActivities', submilestone.id)}
                        onDelete={onDeleteComment.bind(null, 'subActivities')}
                        size="small"
                    />
                </div>
            )}

            {/* Submilestone tasks */}
            {submilestone.subTasks?.length > 0 && (
                <div className="relative">
                    {submilestone.subTasks.map((task, index) => (
                        <div
                            key={task.id}
                            style={{
                                // zIndex: submilestone.subTasks.length - index + 1,
                                position: 'relative',
                            }}
                        >
                            <PMTaskManagementTaskDisplay
                                task={task}
                                canBeUpgraded={index === 0 || submilestone.subTasks[index - 1].status === 'DONE'}
                                expandable={expandable}
                                userIsResponsible={userIsResponsible}
                                canViewComments={canViewComments}
                                canAllComments={canAllComments}
                                onAddComment={onAddComment}
                                onDeleteComment={onDeleteComment}
                            />
                        </div>
                    ))}
                </div>
            )}
        </>
    );
};

PMTaskManagementSubmilestoneDisplay.propTypes = {
    submilestone: PropTypes.object.isRequired,
    expandable: PropTypes.bool,
    userIsResponsible: PropTypes.bool,
    canViewComments: PropTypes.bool,
    canAllComments: PropTypes.bool,
    onAddComment: PropTypes.func,
    onDeleteComment: PropTypes.func,
};

PMTaskManagementSubmilestoneDisplay.defaultProps = {
    submilestone: {},
    expandable: true,
    userIsResponsible: false,
    canViewComments: false,
    canAllComments: false,
    onAddComment: () => null,
    onDeleteComment: () => null,
};

export default PMTaskManagementSubmilestoneDisplay;
