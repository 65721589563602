import React, { useContext, useMemo } from 'react';

import { Download, EllipsisVertical, Paperclip, Trash } from 'lucide-react';

import UserContext from 'contexts/UserContext';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { downloadFile, errorHandling, formatDate, getFileNameAndExtension } from 'utils';

import ActionsPopover from '../actions-popover/actions-popover';
import Avatar from '../avatar/avatar';
import FileRow from '../file-row/file-row';
import TagSmall from '../tag-small/tag-small';

const Comment = ({ comment, onDelete, disabled, canDelete }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const {
        user: { id: userId },
    } = useContext(UserContext);

    const {
        text,
        files,
        createdAt,
        author: {
            id: authorId,
            profileImageUrl,
            profile: { name },
        },
    } = comment;

    const formattedFiles = useMemo(
        () =>
            files.map((file) => {
                const { fileName } = file;
                const { name, extension } = getFileNameAndExtension(fileName);

                return { ...file, name, extension };
            }),
        [files],
    );

    const handleDownloadFile = async (file) => {
        try {
            const { name, url, extension } = file;
            await downloadFile(name, url, extension);
        } catch (error) {
            enqueueSnackbar(errorHandling(error), { variant: 'error' });
        }
    };

    const actions =
        canDelete && userId === authorId
            ? [
                  {
                      icon: <Trash />,
                      text: t('Delete'),
                      action: onDelete,
                      disabled,
                      confirm: true,
                  },
              ]
            : [];

    const fileActions = [
        {
            icon: <Download />,
            text: t('Download'),
            action: (file) => handleDownloadFile(file),
            disabled,
            confirm: false,
        },
    ];

    return (
        <div className="flex rounded-r-xl border border-layout-transparent bg-layout-transparent">
            <div className="w-0.5 flex-shrink-0 bg-secondary-lighter" />
            <div className="flex w-full flex-col gap-8 p-5">
                <div className="flex h-10 items-center justify-between gap-3">
                    <div className="flex h-full items-center gap-3">
                        <Avatar url={profileImageUrl} />
                        <TagSmall>{name}</TagSmall>
                        <TagSmall>{formatDate(createdAt)}</TagSmall>
                    </div>
                    <ActionsPopover
                        icon={<EllipsisVertical />}
                        opacity={{ default: 1, hover: 0.4 }}
                        actions={actions}
                        disabled={disabled}
                    />
                </div>

                <p className="break-words text-xl leading-7">{text}</p>

                {formattedFiles.length > 0 && (
                    <div className="flex flex-col gap-2">
                        {formattedFiles.map((file) => (
                            <FileRow
                                key={file.id}
                                info={{ text: file.extension, icon: <Paperclip /> }}
                                actions={fileActions.map((element) => ({
                                    ...element,
                                    action: element.action.bind(null, file),
                                }))}
                                disabled={disabled}
                            >
                                {file.name}
                            </FileRow>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

Comment.propTypes = {
    comment: PropTypes.object,
    onDelete: PropTypes.func,
    disabled: PropTypes.bool,
    canDelete: PropTypes.bool,
};

Comment.defaultProps = {
    comment: {},
    onDelete: () => {},
    disabled: false,
    canDelete: true,
};

export default Comment;
